import { action } from 'typesafe-actions';

import { iContractClauses } from '../../../interfaces/contract.interface';
import { iDeal } from '../../../interfaces/objects/iDeal.interface';
import { iDealProduct } from '../../../interfaces/objects/iDealProduct.interface';
import { iDealVehicle } from '../../../interfaces/objects/iDealVehicle.interface';
import { iFinancing } from '../../../interfaces/objects/iFinancing.interface';
import { iStore } from '../../../interfaces/objects/iStore.interface';
import { iTransaction } from '../../../interfaces/objects/iTransaction.interface';
import { iVehicle } from '../../../interfaces/objects/iVehicle.interface';
import * as types from './types';


export function dealSetValuesByFormik(props: {
  deal: iDeal;
}) {
  return action(types.DEAL_SET_VALUES_BY_FORMIK, {
    props
  });
}

export function dealGet(props: {
  history: any;
  id: string;
}) {
  return action(types.DEAL_GET, {
    props
  });
}

export function dealGetSuccess(props: {
  deal: iDeal,
}) {
  return action(types.DEAL_GET_SUCCESS, {
    props
  });
}


export function dealGetFailure() {
  return action(types.DEAL_GET_FAILURE);
}

// ---------------------------------------------------

export function dealHandleEditingFormValues(props: {
  value: boolean,
}) {
  return action(types.DEAL_HANDLE_EDITING_FORM_VALUE, {
    props
  });
}

// ---------------------------------------------------

export function dealHandleMounted(props: {
  mounted: boolean,
}) {
  return action(types.DEAL_HANDLE_MOUNTED, {
    props
  });
}

// ---------------------------------------------------

export function dealRequest(props: {
  history: any;
  deal: iDeal,
}) {
  return action(types.DEAL_REQUEST, {
    props
  });
}

export function dealCreatedSuccess(props: {
  deal: iDeal,
}) {
  return action(types.DEAL_CREATED_SUCCESS, {
    props
  });
}

export function dealUpdatedSuccess(props: {
  deal: iDeal,
}) {
  return action(types.DEAL_UPDATED_SUCCESS, {
    props
  });
}

export function dealRequestFailure() {
  return action(types.DEAL_REQUEST_FAILURE);
}

// ---------------------------------------------------

export function dealConcretize(props: {
  deal: iDeal,
}) {
  return action(types.DEAL_CONCRETIZE, {
    props
  });
}

export function dealConcretizeSuccess(props: {
  concretionDate: Date;
  status: string;
}) {
  return action(types.DEAL_CONCRETIZE_SUCCESS, {
    props
  });
}

export function dealConcretizeFailure() {
  return action(types.DEAL_CONCRETIZE_FAILURE);
}

// ---------------------------------------------------

export function dealRevert(props: {
  id: string
}) {
  return action(types.DEAL_REVERT, {
    props
  });
}

export function dealRevertSuccess() {
  return action(types.DEAL_REVERT_SUCCESS);
}

export function dealRevertFailure() {
  return action(types.DEAL_REVERT_FAILURE);
}

// ---------------------------------------------------

export function dealSetContractClause(props: {
  clauses: iContractClauses;
}) {
  return action(types.DEAL_SET_CONTRACT_CLAUSE, {
    props
  });
}

export function dealSetContractType(props: {
  type: string;
}) {
  return action(types.DEAL_SET_CONTRACT_TYPE, {
    props
  });
}

// ---------------------------------------------------

export function dealSetStore(props: {
  store: iStore,
}) {
  return action(types.DEAL_SET_STORE, {
    props
  });
}

// ---------------------------------------------------

export function dealSetPurchaseVehicle(props: {
  deal: iDeal;
  vehicle: iVehicle;
  purchaseVehicles: iVehicle[];
}) {
  return action(types.DEAL_SET_PURCHASE_VEHICLE, {
    props
  });
}

export function dealRemovePurchaseVehicle(props: {
  id: string;
  deal: iDeal;
}) {
  return action(types.DEAL_REMOVE_PURCHASE_VEHICLE, {
    props
  });
}

// ---------------------------------------------------

export function dealSetSellingVehicle(props: {
  deal: iDeal;
  dealVehicle: iDealVehicle,
  sellingVehicles: iDealVehicle[],
}) {
  return action(types.DEAL_SET_SELLING_VEHICLE, {
    props
  });
}

export function dealRemoveSellingVehicle(props: {
  id: string;
  deal: iDeal;
}) {
  return action(types.DEAL_REMOVE_SELLING_VEHICLE, {
    props
  });
}

// ---------------------------------------------------

export function dealSetFinancing(props: {
  deal: iDeal;
  financing: iFinancing;
  financings: iFinancing[];
}) {
  return action(types.DEAL_SET_FINANCING, {
    props
  });
}

export function dealRemoveFinancing(props: {
  id: string;
  deal: iDeal;
}) {
  return action(types.DEAL_REMOVE_FINANCING, {
    props
  });
}

// ---------------------------------------------------

export function dealSetDealProduct(props: {
  deal: iDeal;
  dealProduct: iDealProduct,
  dealProducts: iDealProduct[],
}) {
  return action(types.DEAL_SET_DEAL_PRODUCT, {
    props
  });
}

export function dealRemoveDealProduct(props: {
  id: string;
  deal: iDeal;
}) {
  return action(types.DEAL_REMOVE_PRODUCT, {
    props
  });
}

// ---------------------------------------------------

export function dealSetTransaction(props: {
  deal: iDeal;
  transaction: iTransaction;
  transactions: iTransaction[];
}) {
  return action(types.DEAL_SET_TRANSACTION, {
    props
  });
}

export function dealRemoveTransaction(props: {
  id: string;
  deal: iDeal;
}) {
  return action(types.DEAL_REMOVE_TRANSACTION, {
    props
  });
}

// ---------------------------------------------------

export function dealUpdateTotalSellingValue(props: {
  sellingVehicles: iDealVehicle[],
}) {
  return action(types.DEAL_GET_TOTAL_SELLING_VALUE, {
    props
  });
}

export function dealUpdateTotalSellingValueSuccess(props: {
  totalSellingValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_SELLING_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalSellingValueFailure() {
  return action(types.DEAL_GET_TOTAL_SELLING_VALUE_FAILURE);
}


export function dealUpdateTotalPurchaseValue(props: {
  purchaseVehicles: iVehicle[],
}) {
  return action(types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE, {
    props
  });
}

export function dealUpdateTotalPurchaseValueSuccess(props: {
  totalPurchaseValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalPurchaseValueFailure() {
  return action(types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE_FAILURE);
}

export function dealUpdateTotalProductsValue(props: {
  dealProducts: iDealProduct[],
}) {
  return action(types.DEAL_UPDATE_TOTAL_PRODUCTS_VALUE, {
    props
  });
}

export function dealUpdateTotalProductsValueSuccess(props: {
  totalProductsValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_PRODUCTS_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalFinancingsValue(props: {
  financings: iFinancing[],
}) {
  return action(types.DEAL_UPDATE_TOTAL_FINANCINGS_VALUE, {
    props
  });
}

export function dealUpdateTotalFinancingsValueSuccess(props: {
  totalFinancingsValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_FINANCINGS_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalTransactionsValues(props: {
  transactions: iTransaction[],
}) {
  return action(types.DEAL_UPDATE_TOTAL_TRANSACTIONS_VALUE, {
    props
  });
}

export function dealUpdateTotalTransactionsReceivedValueSuccess(props: {
  totalTransactionsReceivedValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_TRANSACTIONS_RECEIVED_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalTransactionsPaidValueSuccess(props: {
  totalTransactionsPaidValue: number,
}) {
  return action(types.DEAL_UPDATE_TOTAL_TRANSACTIONS_PAID_VALUE_SUCCESS, {
    props
  });
}

export function dealUpdateTotalValue() {
  return action(types.DEAL_UPDATE_TOTAL_VALUE);
}

export function dealUpdateTotalReceivedValue() {
  return action(types.DEAL_UPDATE_TOTAL_RECEIVED_VALUE);
}

export function dealUpdateTotalPaidValue() {
  return action(types.DEAL_UPDATE_TOTAL_PAID_VALUE);
}

export function dealUpdateTotalDifferenceValue() {
  return action(types.DEAL_UPDATE_TOTAL_DIFFERENCE_VALUE);
}

// ---------------------------------------------------

export function dealDelete(props: {
  history: any;
  id: string;
}) {
  return action(types.DEAL_DELETE, {
    props
  });
}

export function dealDeleteSuccess(props: {
  deal: iDeal,
}) {
  return action(types.DEAL_DELETE_SUCCESS, {
    props
  });
}

export function dealDeleteFailure() {
  return action(types.DEAL_DELETE_FAILURE);
}

// ---------------------------------------------------

export function dealResetState() {
  return action(types.DEAL_RESET_STATE);
}
