import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';
import Axios, { AxiosResponse } from 'axios';
import { iApiResponse } from '../../../interfaces/apiResponse.interface';

export function* addressGet({ payload }: ActionType<typeof actions.addressGet>) {
  // console.log('@addressGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/addresses/${payload.props.id}`);
    yield put(actions.addressGetSuccess({ address: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.addressGetFailure());
  }
}

export function* addressRequest({ payload }: ActionType<typeof actions.addressRequest>) {
  // console.log('@addresseRequest/payload:: ', payload)
  try {
    if(payload.props.address.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/addresses/${payload.props.address.id}`, payload.props.address);
      yield put(actions.addressUpdatedSuccess({ address: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/addresses/', payload.props.address);
      yield put(actions.addressCreatedSuccess({ address: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Err', err)
    console.log('Erro:: ', err)
    yield put(actions.addressRequestFailure());
  }
}

export function* addressPostalCodeRequest({ payload }: ActionType<typeof actions.addressPostalCodeRequest>) {
  // console.log('@addressPostalCodeRequest/payload:: ', payload)
  try {
    const { data }: AxiosResponse<any> = yield call(Axios.get, `https://brasilapi.com.br/api/cep/v2/${payload.props.postalCode}`);
    // console.log('data:: ', data)
    if(data.type !== 'service_error') {
      yield put(actions.addressPostalCodeRequestSuccess({
        address: {
          ...payload.props.address,
          postalCode: `${data.cep}`,
          street: `${data.street}`,
          addressComplement: '',
          city: `${data.city}`,
          provincy: `${data.neighborhood}`,
          state: `${data.state}`,
          country: 'BR',
          latitude: data.location?.coordinates?.latitude,
          longitude: data.location?.coordinates?.longitude,
        }
      }))
    } else {
      toast.warning(`[Erro] ${data.errors[0]?.message}`);
      yield put(actions.addressPostalCodeRequestFailure());
    }
  } catch(err) {
    console.log('Err', err)

    yield put(actions.addressPostalCodeRequestFailure());
  }
}

export default all([
  takeLatest(types.ADDRESS_GET, addressGet),
  takeLatest(types.ADDRESS_REQUEST, addressRequest),
  takeLatest(types.ADDRESS_POSTAL_CODE_REQUEST, addressPostalCodeRequest),
])
