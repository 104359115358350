import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';
import { AxiosResponse } from 'axios';
import { iApiResponse } from '../../../interfaces/apiResponse.interface';

export function* dealVehicleGet({ payload }: ActionType<typeof actions.dealVehicleGet>) {
  // console.log('@dealVehicleGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/dealVehicles/${payload.props.id}`);
    yield put(actions.dealVehicleGetSuccess({ dealVehicle: response.data.payload }));
  } catch {
    yield put(actions.dealVehicleGetFailure());
  }
}


export function* dealVehicleRequest({ payload }: ActionType<typeof actions.dealVehicleRequest>) {
  // console.log('@dealVehicleRequest/payload:: ', payload)
  try {
    if(payload.data.dealVehicle.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/dealVehicles/${payload.data.dealVehicle.id}`, payload.data.dealVehicle);
      yield put(actions.dealVehicleUpdatedSuccess({ dealVehicle: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/dealVehicles/', payload.data.dealVehicle);
      yield put(actions.dealVehicleCreatedSuccess({ dealVehicle: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.dealVehicleRequestFailure());
  }

}

export default all([
  takeLatest(types.DEAL_VEHICLE_GET, dealVehicleGet),
  takeLatest(types.DEAL_VEHICLE_REQUEST, dealVehicleRequest),
])
