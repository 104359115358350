import { action } from 'typesafe-actions';
import { iAccount } from '../../../interfaces/objects/iAccount.interface';
import * as types from './types';

export function accountRequest(data: {
  history: any;
  account: iAccount,
}) {
  return action(types.ACCOUNT_REQUEST, {
    data
  });
}

export function accountCreatedSuccess(data: {
  account: iAccount,
}) {
  return action(types.ACCOUNT_CREATED_SUCCESS, {
    data,
  });
}

export function accountUpdatedSuccess(data: {
  account: iAccount,
}) {
  return action(types.ACCOUNT_UPDATED_SUCCESS, {
    data,
  });
}

export function accountRequestFailure() {
  return action(types.ACCOUNT_REQUEST_FAILURE);
}

export function accountResetState() {
  return action(types.ACCOUNT_RESET_STATE);
}
