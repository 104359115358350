import { DealAction, iDealState } from './types';
import * as types from './types';


const initialState: iDealState = {
  mounted: false,
  editingFormValues: false,
  loadingData: false,
  requestPerform: false,
  contract: {
    clauses: {
      purchaseReceipt: true,
      guaranteeCertificate: true,
      sellingReceipt: true,
      declaration: true,
      contractClauses: true,
      consignmentOpen: true,
      consignmentCancel: true,
    },
    type: 'deal'
  },
  totalFinancingsValue: 0,
  totalTransactionsReceivedValue: 0,
  totalTransactionsPaidValue: 0,
  totalSellingValue: 0,
  totalPurchaseValue: 0,
  totalProductsValue: 0,
  totalValue: 0,
  totalReceivedValue: 0,
  totalPaidValue: 0,
  totalDifferenceValue: 0,
  fields: {
    createdAt: {
      name: 'createdAt',
      label: 'Data da abertura',
      type: 'datetime',
      disabled: true
    },
    concretionDate: {
      name: 'concretionDate',
      label: 'Data da concretização',
      type: 'datetime',
      disabled: true
    },
    warrantyKilometers: {
      name: 'warrantyKilometers',
      label: 'KM/Garantia',
      type: 'input'
    },
    warrantyDays: {
      name: 'warrantyDays',
      label: 'Dias/Garantia',
      type: 'input'
    },
    contractObservations: {
      name: 'contractObservations',
      label: 'Observações do contrato',
      type: 'textarea'
    },
    internalObservations: {
      name: 'internalObservations',
      label: 'Observações internas',
      type: 'textarea'
    },
    paidsValue: {
      name: 'paidsValue',
      label: 'Valor pago',
      type: 'input'
    },
    receiveidValue: {
      name: 'receiveidValue',
      label: 'Valor recebido',
      type: 'input'
    },
    receivableValue: {
      name: 'receivableValue',
      label: 'Valor à receber',
      type: 'input'
    },
    servicesValue: {
      name: 'servicesValue',
      label: 'Valor dos serviços',
      type: 'input'
    },
    amountValue: {
      name: 'amountValue',
      label: 'Valor total',
      type: 'input'
    },
    storeId: {
      name: 'storeId',
      label: 'Loja',
      type: 'select',
    },
  },
  data: {
    warrantyKilometers: 0,
    warrantyDays: 0,
    contractObservations: '',
    internalObservations: '',
    paidsValue: 0,
    receiveidValue: 0,
    receivableValue: 0,
    servicesValue: 0,
    purchaseVehicles: [],
    sellingVehicles: [],
    financings: [],
    products: [],
    transactions: [],
    dealObservations: [],
    dealProducts: [],
    storeId: '',
  }
}

export default function changeState(
  state = initialState,
  action: DealAction
): iDealState {
  switch(action.type) {
    case types.DEAL_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.deal,
        }
      };
    }
    case types.DEAL_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.deal,
        mounted: true,
        loadingData: false
      };
    case types.DEAL_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_HANDLE_EDITING_FORM_VALUE:
      return {
        ...state,
        editingFormValues: action.payload.props.value
      };
    case types.DEAL_HANDLE_MOUNTED:
      return {
        ...state,
        mounted: action.payload.props.mounted
      };
    case types.DEAL_REQUEST:
      return {
        ...state,
      };
    case types.DEAL_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.deal,
        editingFormValues: false,
        requestPerform: true
      };
    case types.DEAL_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.deal,
        editingFormValues: false,
        requestPerform: true
      };
    case types.DEAL_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.DEAL_CONCRETIZE:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_CONCRETIZE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          concretionDate: action.payload.props.concretionDate
        },
        loadingData: false,
        editingFormValues: false,
        requestPerform: true
      };
    case types.DEAL_CONCRETIZE_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_REVERT:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_REVERT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          concretionDate: undefined
        },
        loadingData: false
      };
    case types.DEAL_REVERT_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_SET_CONTRACT_CLAUSE:
      return {
        ...state,
        contract: {
          ...state.contract,
          clauses: action.payload.props.clauses
        }
      };
    case types.DEAL_SET_CONTRACT_TYPE:
      return {
        ...state,
        contract: {
          ...state.contract,
          type: action.payload.props.type
        }
      };
    case types.DEAL_SET_STORE: {
      return {
        ...state,
        fields: {
          ...state.fields,
          storeId: {
            ...state.fields.storeId,
          },
        },
        data: {
          ...state.data,
          storeId: action.payload.props.store.id ?? '',
          store: action.payload.props.store
        }
      };
    }
    case types.DEAL_UPDATE_TOTAL_PRODUCTS_VALUE_SUCCESS:
      return {
        ...state,
        totalProductsValue: action.payload.props.totalProductsValue
      };
    case types.DEAL_UPDATE_TOTAL_FINANCINGS_VALUE_SUCCESS:
      return {
        ...state,
        totalFinancingsValue: action.payload.props.totalFinancingsValue
      };
    case types.DEAL_UPDATE_TOTAL_TRANSACTIONS_RECEIVED_VALUE_SUCCESS:
      return {
        ...state,
        totalTransactionsReceivedValue: action.payload.props.totalTransactionsReceivedValue
      };
    case types.DEAL_UPDATE_TOTAL_TRANSACTIONS_PAID_VALUE_SUCCESS:
      return {
        ...state,
        totalTransactionsPaidValue: action.payload.props.totalTransactionsPaidValue
      };
    case types.DEAL_UPDATE_TOTAL_SELLING_VALUE_SUCCESS:
      return {
        ...state,
        totalSellingValue: action.payload.props.totalSellingValue
      };
    case types.DEAL_GET_TOTAL_SELLING_VALUE_FAILURE:
      return {
        ...state,
      };
    case types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE:
      return {
        ...state,
      };
    case types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE_SUCCESS:
      return {
        ...state,
        totalPurchaseValue: action.payload.props.totalPurchaseValue
      };
    case types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE_FAILURE:
      return {
        ...state,
      };
    case types.DEAL_UPDATE_TOTAL_VALUE:
      return {
        ...state,
        totalValue: ((state.totalSellingValue + state.totalProductsValue) - state.totalPurchaseValue)
      };
    case types.DEAL_UPDATE_TOTAL_RECEIVED_VALUE:
      return {
        ...state,
        totalReceivedValue: (state.totalPurchaseValue + state.totalFinancingsValue + state.totalTransactionsReceivedValue)
      };
    case types.DEAL_UPDATE_TOTAL_PAID_VALUE:
      return {
        ...state,
        totalPaidValue: (state.totalSellingValue + state.totalProductsValue + state.totalTransactionsPaidValue)
      };
    case types.DEAL_UPDATE_TOTAL_DIFFERENCE_VALUE:
      return {
        ...state,
        totalDifferenceValue: (state.totalPaidValue - state.totalReceivedValue)
      };
    case types.DEAL_DELETE:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_DELETE_SUCCESS:
      return {
        ...initialState,
      };
    case types.DEAL_DELETE_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_REMOVE_FINANCING:
      return {
        ...state,
      };
    case types.DEAL_REMOVE_PRODUCT:
      return {
        ...state,
      };
    case types.DEAL_REMOVE_PURCHASE_VEHICLE:
      return {
        ...state,
      };
    case types.DEAL_REMOVE_SELLING_VEHICLE:
      return {
        ...state,
      };
    case types.DEAL_REMOVE_TRANSACTION:
      return {
        ...state,
      };
    case types.DEAL_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
