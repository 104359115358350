import { AccountAction, iAccountState } from './types';

import * as types from './types';


const initialState: iAccountState = {
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    nickname: {
      name: 'nickname',
      label: 'Apelido',
      type: 'input',
    },
    bank: {
      name: 'bank',
      label: 'Banco',
      type: 'input',
    },
    agency: {
      name: 'agency',
      label: 'Agência bancária',
      type: 'input',
    },
    accountNumber: {
      name: 'accountNumber',
      label: 'Número da conta',
      type: 'input',
    },
    cpf: {
      name: 'cpf',
      label: 'CPF',
      type: 'input',
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'CHECKING',
          label: 'Conta corrente',
          value: 'CHECKING'
        },
        {
          key: 'SAVINGS',
          label: 'Conta poupança',
          value: 'SAVINGS'
        },
        {
          key: 'CASH_REGISTER',
          label: 'Caixa',
          value: 'CASH_REGISTER'
        }
      ],
    },
  },
  data: {
    name: '',
    nickname: '',
    bank: '',
    agency: '',
    accountNumber: '',
    cpf: '',
    type: 'CHECKING',
  }
}

export default function changeState(
  state = initialState,
  action: AccountAction
): iAccountState {
  switch(action.type) {
    case types.ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case types.ACCOUNT_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.account,
        requestPerform: true
      };
    case types.ACCOUNT_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.account,
        requestPerform: true
      };
    case types.ACCOUNT_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.ACCOUNT_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
