import React, { FC, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/createStore';
import MyModal from '../../atoms/Modal';
import { signInRequest } from '../../../store/modules/auth/actions';
import { get } from 'lodash';
import { LoaderSpin } from '../../atoms/LoaderSpin';
import { Input } from '../../slds';
import logoBrancoExtenso from '../../../assets/logo-branco-extenso.png';

interface Props {

}

const SignInForm: FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const prevPath = get(location, 'state.prevPath', '/');
  const { showAuthModal } = useSelector((state: StoreState) => state.auth);
  const isLoading = useSelector((state: StoreState) => state.loading.asyncLoadingItems.some(i => i === 'signIn'));
  const [username, setUsername] = useState(process.env.REACT_APP_ENVIROIMENT === 'dev' ? 'raphael' : '');
  const [password, setPassword] = useState(process.env.REACT_APP_ENVIROIMENT === 'dev' ? 'Test@2020' : '');

  const signIn = useCallback(() => {
    dispatch(signInRequest({ username, password, history }))
  }, [username, password, history]);

  console.log(showAuthModal, location.pathname)

  if(!showAuthModal || location.pathname === '/login') return null;

  return (
    <MyModal
      hideCloseButton
      showModal={showAuthModal}
      size="sm"
    // title='Login'
    >
      <div className="flex align-middle flex-col gap-4 justify-center h-full max-w-sm p-4 mx-auto">
        <img src={logoBrancoExtenso} alt="Logo" className='w-24 mx-auto mb-4' />
        <Input
          placeholder="Usuário"
          onChange={(e: any) => setUsername(e.target.value)}
          value={username}
        />
        <Input
          type="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          onKeyPress={(e: any) => {
            if(e.key === 'Enter') {
              signIn()
            }
          }}
          placeholder="Senha"
        />
        <button className='mt-10' onClick={signIn}>
          {isLoading ? (
            <LoaderSpin>
              Carregando...
            </LoaderSpin>
          ) : 'Fazer login'}
        </button>
      </div>
    </MyModal>
  );
};

export default SignInForm;
