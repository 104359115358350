import { action } from 'typesafe-actions';
import { iDealVehicle } from '../../../interfaces/objects/iDealVehicle.interface';

import { iVehicle } from '../../../interfaces/objects/iVehicle.interface';
import * as types from './types';


export function dealVehicleGet(props: {
  history: any;
  id: string;
}) {
  return action(types.DEAL_VEHICLE_GET, {
    props
  });
}

export function dealVehicleGetSuccess(props: {
  dealVehicle: iDealVehicle,
}) {
  return action(types.DEAL_VEHICLE_GET_SUCCESS, {
    props
  });
}


export function dealVehicleGetFailure() {
  return action(types.DEAL_VEHICLE_GET_FAILURE);
}

// ---------------------------------------------------

export function dealVehicleRequest(data: {
  history: any;
  dealVehicle: iDealVehicle,
}) {
  return action(types.DEAL_VEHICLE_REQUEST, {
    data
  });
}

export function dealVehicleCreatedSuccess(data: {
  dealVehicle: iDealVehicle,
}) {
  return action(types.DEAL_VEHICLE_CREATED_SUCCESS, {
    data,
  });
}

export function dealVehicleUpdatedSuccess(data: {
  dealVehicle: iDealVehicle,
}) {
  return action(types.DEAL_VEHICLE_UPDATED_SUCCESS, {
    data,
  });
}

export function dealVehicleRequestFailure() {
  return action(types.DEAL_VEHICLE_REQUEST_FAILURE);
}

// ---------------------------------------------------

export function dealVehicleSetVehicle(props: {
  vehicle: iVehicle,
}) {
  return action(types.DEAL_VEHICLE_SET_VEHICLE, {
    props
  });
}

// ---------------------------------------------------

export function dealVehicleResetState() {
  return action(types.DEAL_VEHICLE_RESET_STATE);
}
