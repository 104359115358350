import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* storeRequest({ payload }: ActionType<typeof actions.storeRequest>) {
  console.log('@storeRequest/payload:: ', payload.props)
  try {
    if(payload.props.store.id) {
      const response = yield call(api.patch, `/api/stores/${payload.props.store.id}`, payload.props.store);
      yield put(actions.storeUpdatedSuccess({ store: response.data.payload }));
      toast.success('Cadastro atualizado!');
      console.log('storeUpdatedSuccess:: ', response.data.payload);
    } else {
      const response = yield call(api.post, '/api/stores/', payload.props.store);
      yield put(actions.storeCreatedSuccess({ store: response.data.payload }));
      toast.success('Cadastro realizado!');
      console.log('storeCreatedSuccess:: ', response.data.payload);
    }
    const { history } = payload.props;
    history.push('/stores/')
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.storeRequestFailure());
  }

}

export default all([
  takeLatest(types.STORE_REQUEST, storeRequest),
])
