import { action } from 'typesafe-actions';
import { iOption } from '../../../components/formik/types';

import { iTransaction, tTransactionCondition, tTransactionType } from '../../../interfaces/objects/iTransaction.interface';
import * as types from './types';


export function transactionSetValuesByFormik(props: {
  transaction: iTransaction;
}) {
  return action(types.TRANSACTION_SET_VALUES_BY_FORMIK, {
    props
  });
}


export function transactionGet(props: {
  history: any;
  id: string;
  client?: iOption;
  guarantor?: iOption;
  store?: iOption;
}) {
  return action(types.TRANSACTION_GET, {
    props
  });
}

export function transactionGetSuccess(props: {
  transaction: iTransaction,
}) {
  return action(types.TRANSACTION_GET_SUCCESS, {
    props
  });
}

export function transactionGetFailure() {
  return action(types.TRANSACTION_GET_FAILURE);
}

// ----------------------------------------------------

export function transactionRequest(props: {
  history: any;
  transaction: iTransaction;
}) {
  return action(types.TRANSACTION_REQUEST, {
    props
  });
}

export function transactionCreatedSuccess(props: {
  transaction: iTransaction;
}) {
  return action(types.TRANSACTION_CREATED_SUCCESS, {
    props
  });
}

export function transactionUpdatedSuccess(props: {
  transaction: iTransaction;
}) {
  return action(types.TRANSACTION_UPDATED_SUCCESS, {
    props
  });
}

export function transactionRequestFailure() {
  return action(types.TRANSACTION_REQUEST_FAILURE);
}

// ----------------------------------------------------

export function transactionSetCondition(props: {
  transaction: iTransaction;
  condition: tTransactionCondition;
  client?: iOption;
  guarantor?: iOption;
  store?: iOption;
}) {
  return action(types.TRANSACTION_SET_CONDITION, {
    props
  });
}

// ----------------------------------------------------

export function transactionResetState() {
  return action(types.TRANSACTION_RESET_STATE);
}

