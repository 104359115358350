import styled from 'styled-components';
import { breakAt, BreakpointSizes, getMaxWidth, MaxWidthProps } from '../../../styles/Breakpoints';
import { iGlobalStyleProps } from '../../../styles/GlobalStyles';
import background from '../../../assets/background.jpg'
export const StyledOverlay = styled.div`
  z-index: 1201;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface iStyledModalProps extends MaxWidthProps { };
export const StyledModal = styled.div<iStyledModalProps>`
  border-color: #3f51b5;
  width: ${({ size = 'sm' }: iStyledModalProps) => `${getMaxWidth({ size })}px`};
  max-width: ${({ size = 'sm' }: iStyledModalProps) => `${getMaxWidth({ size })}px`};
  background-image: url(${background});
  background-size: cover;
  background-position: center;
`;
