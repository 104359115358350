import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { iVehicleModel } from '../../../interfaces/objects/iVehicleModel.interface';
import { api } from '../../../services/api/axios';
import { mergeDeepArrays } from '../../../utils/objectUtils';
import * as actions from './actions';
import * as types from './types';

export function* leadGet({ payload }: ActionType<typeof actions.leadGet>) {
  // console.log('@leadGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/leads/${payload.props.id}`);
    console.log('@leadGet/response.data.payload:: ', response.data.payload)
    yield put(actions.leadGetSuccess({ lead: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.leadGetFailure());
  }
}

export function* leadRequest({ payload }: ActionType<typeof actions.leadRequest>) {
  // console.log('@leadRequest/payload:: ', payload)
  try {
    if(payload.props.lead.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/leads/${payload.props.lead.id}`, payload.props.lead);
      yield put(actions.leadUpdatedSuccess({ lead: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/leads/', payload.props.lead);
      yield put(actions.leadCreatedSuccess({ lead: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.leadRequestFailure());
  }

}

export function* leadSetDesiredVehicle({ payload }: ActionType<typeof actions.leadSetDesiredVehicle>) {
  // console.log('@leadSetDesiredVehicle/payload:: ', payload)
  try {
    const updatedDesiredVehicles: iVehicleModel[] = mergeDeepArrays([...payload.props.desiredVehicles], [payload.props.desiredVehicle]);
    yield put(actions.leadSetValuesByFormik({ lead: { ...payload.props.lead, desiredVehicles: updatedDesiredVehicles } }));
  } catch(err) {
    console.log('Falha ao atualizar os veículos desejados::', err);
    toast.error('Falha ao atualizar os veículos desejados');
  }
}

export function* leadRemoveDesiredVehicle({ payload }: ActionType<typeof actions.leadRemoveDesiredVehicle>) {
  // console.log('@leadRemoveDesiredVehicle/payload:: ', payload)
  try {
    const { id, desiredVehicles } = payload.props;
    const updatedDesiredVehicles = desiredVehicles.filter(desiredVehicle => desiredVehicle.id !== id);
    yield put(actions.leadSetValuesByFormik({ lead: { ...payload.props.lead, desiredVehicles: updatedDesiredVehicles } }));
  } catch(err) {
    console.log('Falha ao atualizar os veículos desejados::', err);
    toast.error('Falha ao atualizar os veículos desejados');
  }
}

export function* leadSetOfferedVehicle({ payload }: ActionType<typeof actions.leadSetOfferedVehicle>) {
  // console.log('@leadSetOfferedVehicle/payload:: ', payload)
  try {
    const updatedOfferedVehicles: iVehicleModel[] = mergeDeepArrays([...payload.props.offeredVehicles], [payload.props.offeredVehicle]);
    yield put(actions.leadSetValuesByFormik({ lead: { ...payload.props.lead, offeredVehicles: updatedOfferedVehicles } }));
  } catch(err) {
    console.log('Falha ao atualizar os veículos desejados::', err);
    toast.error('Falha ao atualizar os veículos desejados');
  }
}

export function* leadRemoveOfferedVehicle({ payload }: ActionType<typeof actions.leadRemoveOfferedVehicle>) {
  // console.log('@leadRemoveOfferedVehicle/payload:: ', payload)
  try {
    const { id, offeredVehicles } = payload.props;
    const updatedOfferedVehicles = offeredVehicles.filter(offeredVehicle => offeredVehicle.id !== id);
    yield put(actions.leadSetValuesByFormik({ lead: { ...payload.props.lead, offeredVehicles: updatedOfferedVehicles } }));
  } catch(err) {
    console.log('Falha ao atualizar os veículos desejados::', err);
    toast.error('Falha ao atualizar os veículos desejados');
  }
}

export default all([
  takeLatest(types.LEAD_GET, leadGet),
  takeLatest(types.LEAD_REQUEST, leadRequest),
  takeLatest(types.LEAD_SET_DESIRED_VEHICLE, leadSetDesiredVehicle),
  takeLatest(types.LEAD_SET_OFFERED_VEHICLE, leadSetOfferedVehicle),
  takeLatest(types.LEAD_REMOVE_DESIRED_VEHICLE, leadRemoveDesiredVehicle),
  takeLatest(types.LEAD_REMOVE_OFFERED_VEHICLE, leadRemoveOfferedVehicle),
])
