import { action } from 'typesafe-actions';
import { iField } from '../../../interfaces/objects/iField.interface';
import * as types from './types';


export function fieldRequest(props: {
  history: any;
  field: iField;
}) {
  return action(types.FIELD_REQUEST, {
    props
  });
}

export function fieldCreatedSuccess(props: {
  field: iField,
}) {
  return action(types.FIELD_CREATED_SUCCESS, {
    props,
  });
}

export function fieldUpdatedSuccess(props: {
  field: iField,
}) {
  return action(types.FIELD_UPDATED_SUCCESS, {
    props,
  });
}

export function fieldRequestFailure() {
  return action(types.FIELD_REQUEST_FAILURE);
}

export function fieldResetState() {
  return action(types.FIELD_RESET_STATE);
}
