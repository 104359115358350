import { action } from 'typesafe-actions';
import { iObjectPermission } from '../../../interfaces/objects/iObjectPermission.interface';
import * as types from './types';


export function objectPermissionGet(props: {
  history: any;
  sobjectId: string;
  roleId: string;
}) {
  return action(types.OBJECT_PERMISSION_GET, {
    props
  });
}

export function objectPermissionGetSuccess(props: {
  objectPermission: iObjectPermission,
}) {
  return action(types.OBJECT_PERMISSION_GET_SUCCESS, {
    props
  });
}

export function objectPermissionGetFailure() {
  return action(types.OBJECT_PERMISSION_GET_FAILURE);
}

// ====================================================

export function objectPermissionRequest(props: {
  history: any;
  objectPermission: iObjectPermission,
}) {
  return action(types.OBJECT_PERMISSION_REQUEST, {
    props
  });
}

export function objectPermissionCreatedSuccess(props: {
  objectPermission: iObjectPermission,
}) {
  return action(types.OBJECT_PERMISSION_CREATED_SUCCESS, {
    props
  });
}

export function objectPermissionUpdatedSuccess(props: {
  objectPermission: iObjectPermission,
}) {
  return action(types.OBJECT_PERMISSION_UPDATED_SUCCESS, {
    props
  });
}

export function objectPermissionRequestFailure() {
  return action(types.OBJECT_PERMISSION_REQUEST_FAILURE);
}

// ====================================================


export function objectPermissionChangeRequestPerform() {
  return action(types.OBJECT_PERMISSION_CHANGE_REQUEST_PERFORM);
}

// ====================================================


export function objectPermissionResetState() {
  return action(types.OBJECT_PERMISSION_RESET_STATE);
}
