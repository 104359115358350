import React from 'react';

import IconSettings from '../../icon-settings';
import GlobalNavigationBar from '../../global-navigation-bar'; // `~` is replaced with design-system-react at runtime
import GlobalNavigationBarRegion from '../../global-navigation-bar/region';
import GlobalNavigationBarDropdown from '../../global-navigation-bar/dropdown';
import GlobalNavigationBarLink from '../../global-navigation-bar/link';

import Button from '../../button';
import Icon from '../../icon';

import AppLauncher from '../../app-launcher';
import AppLauncherExpandableSection from '../../app-launcher/expandable-section';
import AppLauncherTile from '../../app-launcher/tile';
import { menuItems } from '../../../../organisms/Header/Controls/Navigation/menuItems';

class GlobalNavigationBarExample extends React.Component {
	static displayName = 'GlobalNavigationBarExample';

	render() {
		
		const dropdownCollection = [
			{
				label: 'Menu Item One',
				value: '1',
				iconCategory: 'utility',
				iconName: 'table',
				href: 'http://www.google.com',
			},
			{
				label: 'Menu Item Two',
				value: '2',
				iconCategory: 'utility',
				iconName: 'kanban',
				href: 'http://www.google.com',
			},
			{
				label: 'Menu Item Three',
				value: '3',
				iconCategory: 'utility',
				iconName: 'side_list',
				href: 'http://www.google.com',
			},
		];

		return (
			<IconSettings iconPath="/assets/icons">
				<GlobalNavigationBar>
					<GlobalNavigationBarRegion region="primary">
						<AppLauncher
							id="app-launcher-trigger"
							onClick={(event) => {
								window.location = '/home' // TODO: Decidir oque fazer

							}}
							// triggerName="App Name"
							// onSearch={() => {
							// 	console.log('Search term:', event.target.value);
							// }}
							// modalHeaderButton={<Button label="App Exchange" />}
						>
							{/* <AppLauncherExpandableSection title="Tile Section">
								<AppLauncherTile
									title="Marketing Cloud"
									iconText="MC"
									description="Send emails, track emails, read emails! Emails!"
								/>
								<AppLauncherTile
									title="Call Center"
									description="The key to call center and contact center is not to use too many words!"
									descriptionHeading="Call Center"
									iconText="CC"
								/>
							</AppLauncherExpandableSection>
							<AppLauncherExpandableSection title="Small Tile Section">
								<AppLauncherTile
									title="Journey Builder"
									iconText="JB"
									size="small"
								/>
								<AppLauncherTile
									title="Sales Cloud"
									iconNode={
										<Icon name="campaign" category="standard" size="large" />
									}
									size="small"
								/>
							</AppLauncherExpandableSection> */}
						</AppLauncher>
					</GlobalNavigationBarRegion>

					<GlobalNavigationBarRegion region="secondary" navigation>
						{React.Children.toArray(
							menuItems?.map((menuItem) => (
								// <MenuItem dense onClick={() => history.push(menuItem.link)}>{menuItem.title}</MenuItem>
								<GlobalNavigationBarLink 
									id={menuItem.title} 
									label={menuItem.title}
									active={window.location.pathname === menuItem.link}
									onClick={() => {
										window.location = menuItem.link;
									}}
								/>
							))
						)}
						{/* <GlobalNavigationBarDropdown
							assistiveText={{ icon: 'Open menu item submenu' }}
							id="primaryDropdown"
							label="Menu Item"
							options={dropdownCollection}
						/> */}
					</GlobalNavigationBarRegion>
				</GlobalNavigationBar>
			</IconSettings>
		);
	}
}

export default GlobalNavigationBarExample; // export is replaced with `ReactDOM.render(<GlobalNavigationBarExample />, mountNode);` at runtime
