import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* fieldRequest({ payload }: ActionType<typeof actions.fieldRequest>) {
  // console.log('@fieldeRequest/payload:: ', payload)
  try {
    if(payload.props.field.id) {
      const response = yield call(api.patch, `/api/fields/${payload.props.field.id}`, payload.props.field);
      yield put(actions.fieldUpdatedSuccess({ field: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/fields/', payload.props.field);
      yield put(actions.fieldCreatedSuccess({ field: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Err', err)
    console.log('Erro:: ', err)
    yield put(actions.fieldRequestFailure());
  }
}

export default all([
  takeLatest(types.FIELD_REQUEST, fieldRequest),
])
