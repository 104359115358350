import { action } from 'typesafe-actions';
import { iExpense } from '../../../interfaces/objects/iExpense.interface';
import { iVehicle } from '../../../interfaces/objects/iVehicle.interface';
import * as types from './types';


export function vehicleSetValuesByFormik(props: {
  vehicle: iVehicle;
}) {
  return action(types.VEHICLE_SET_VALUES_BY_FORMIK, {
    props
  });
}


export function vehicleGet(props: {
  history: any;
  id: string;
}) {
  return action(types.VEHICLE_GET, {
    props
  });
}

export function vehicleGetSuccess(props: {
  vehicle: iVehicle,
}) {
  return action(types.VEHICLE_GET_SUCCESS, {
    props
  });
}

export function vehicleGetFailure() {
  return action(types.VEHICLE_GET_FAILURE);
}

// ====================================================

export function vehicleRequest(props: {
  history: any;
  vehicle: iVehicle,
}) {
  return action(types.VEHICLE_REQUEST, {
    props
  });
}

export function vehicleCreatedSuccess(props: {
  vehicle: iVehicle,
}) {
  return action(types.VEHICLE_CREATED_SUCCESS, {
    props,
  });
}

export function vehicleUpdatedSuccess(props: {
  vehicle: iVehicle,
}) {
  return action(types.VEHICLE_UPDATED_SUCCESS, {
    props,
  });
}

export function vehicleRequestFailure() {
  return action(types.VEHICLE_REQUEST_FAILURE);
}

// ====================================================

export function vehicleSetExpense(props: {
  expense: iExpense,
  expenses: iExpense[],
}) {
  return action(types.VEHICLE_SET_EXPENSE, {
    props
  });
}

export function vehicleSetExpenseSuccess(props: {
  expenses: iExpense[],
}) {
  return action(types.VEHICLE_SET_EXPENSE_SUCCESS, {
    props
  });
}

// ====================================================

export function vehicleSetDebitValue(props: {
  expenses: iExpense[],
}) {
  return action(types.VEHICLE_SET_DEBIT_VALUE, {
    props
  });
}

export function vehicleSetDebitValueSuccess(props: {
  debitValue: number;
}) {
  return action(types.VEHICLE_SET_DEBIT_VALUE_SUCCESS, {
    props
  });
}

// ====================================================

export function vehicleSetPurchaseValue(props: {
  vehicle: iVehicle;
}) {
  return action(types.VEHICLE_SET_PURCHASE_VALUE, {
    props
  });
}

// ====================================================

export function vehicleResetState() {
  return action(types.VEHICLE_RESET_STATE);
}

