import { PosMachineAction, iPosMachineState } from './types';

import * as types from './types';


const initialState: iPosMachineState = {
  posMachineCreated: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    rent: {
      name: 'rent',
      label: 'Aluguel',
      type: 'input',
    },
    code: {
      name: 'code',
      label: 'Código',
      type: 'input',
    },
    serialNumber: {
      name: 'serialNumber',
      label: 'Número de série',
      type: 'input',
    },
    observations: {
      name: 'observations',
      label: 'Observações',
      type: 'textarea',
    },
    operator: {
      name: 'operator',
      label: 'Operadora',
      type: 'input',
    },
    compensationPeriod: {
      name: 'compensationPeriod',
      label: 'Período de compensação',
      type: 'input',
    },
    active: {
      name: 'active',
      label: 'Ativo',
      type: 'checkbox',
    },
    anticipationFee: {
      name: 'anticipationFee',
      label: 'Taxa de antecipação',
      type: 'input',
    },
    anticipationInstallmentFee: {
      name: 'anticipationInstallmentFee',
      label: 'Taxa de antecipação',
      type: 'input',
    },
    debitFee: {
      name: 'debitFee',
      label: 'Taxa de débito',
      type: 'input',
    },
    creditFee: {
      name: 'creditFee',
      label: 'Taxa de crédito',
      type: 'input',
    },
    accountId: {
      name: 'accountId',
      label: 'Conta bancária',
      type: 'input'
    },
    storeId: {
      name: 'storeId',
      label: 'Loja',
      type: 'input'
    },
  },
  data: {
    name: '',
    rent: 0,
    code: '',
    serialNumber: '',
    observations: '',
    operator: '',
    compensationPeriod: 0,
    active: false,
    anticipationFee: 0,
    anticipationInstallmentFee: 0,
    debitFee: 0,
    creditFee: 0,
    accountId: '',
    storeId: '',
  }
}

export default function changeState(
  state = initialState,
  action: PosMachineAction
): iPosMachineState {
  switch(action.type) {
    case types.POS_MACHINE_GET:
      return {
        ...state,
      };
    case types.POS_MACHINE_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      };
    case types.POS_MACHINE_REQUEST:
      return {
        ...state,
      };
    case types.POS_MACHINE_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        posMachineCreated: true
      };
    case types.POS_MACHINE_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        posMachineCreated: true
      };
    case types.POS_MACHINE_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.POS_MACHINE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
