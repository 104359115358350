import { RoleAction, iRoleState } from './types';

import * as types from './types';


const initialState: iRoleState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    storeId: {
      name: 'storeId',
      label: 'Lojas',
      object: 'stores',
      type: 'lookup',
      hideModal: true,
    },
  },
  data: {
    name: '',
    objectPermissions: [],
  }
}

export default function changeState(
  state = initialState,
  action: RoleAction
): iRoleState {
  switch(action.type) {
    case types.ROLE_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.ROLE_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.role,
        loadingData: false
      };
    case types.ROLE_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.ROLE_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.ROLE_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.role,
        requestPerform: true
      };
    case types.ROLE_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.role,
        requestPerform: true,
        loadingData: false
      };
    case types.ROLE_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.ROLE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
