import { DealVehicleAction, iDealVehicleState } from './types';
import * as types from './types';


const initialState: iDealVehicleState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    netPrice: {
      name: 'netPrice',
      label: 'Valor negociado',
      type: 'currency',
    },
    dealId: {
      name: 'dealId',
      type: 'input',
      hidden: true
    },
    // vehicleId: {
    //   name: 'vehicleId',
    //   label: 'Veículo',
    //   object: 'vehicles',
    //   type: 'lookup',
    //   editionOnly: true,
    //   params: {
    //     status: 'IN_STOCK',
    //   },
    // },
  },
  data: {
    netPrice: 0,
    dealId: '',
    vehicleId: '',
    vehicle: undefined,
  }
}

export default function changeState(
  state = { ...initialState },
  action: DealVehicleAction
): iDealVehicleState {
  switch(action.type) {
    case types.DEAL_VEHICLE_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_VEHICLE_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.dealVehicle,
        loadingData: false
      };
    case types.DEAL_VEHICLE_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_VEHICLE_REQUEST:
      return {
        ...state,
      };
    case types.DEAL_VEHICLE_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.dealVehicle,
        requestPerform: true
      };
    case types.DEAL_VEHICLE_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.dealVehicle,
        requestPerform: true
      };
    case types.DEAL_VEHICLE_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.DEAL_VEHICLE_SET_VEHICLE:
      return {
        ...state,
        data: {
          ...state.data,
          vehicleId: action.payload.props.vehicle.id,
          vehicle: action.payload.props.vehicle,
          netPrice: action.payload.props.vehicle.saleValue ?? 0
        }
      };
    case types.DEAL_VEHICLE_RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
