import { ActionType } from 'typesafe-actions';

import { IFieldSchema } from '../../../components/formik/types';
import { iField } from '../../../interfaces/objects/iField.interface';
import * as actions from './actions';

export type FieldAction = ActionType<typeof actions>;

export interface iFieldState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iField;
}


export const FIELD_REQUEST = '@field/FIELD_REQUEST';
export const FIELD_CREATED_SUCCESS = '@field/FIELD_CREATED_SUCCESS';
export const FIELD_UPDATED_SUCCESS = '@field/FIELD_UPDATED_SUCCESS';
export const FIELD_REQUEST_FAILURE = '@field/FIELD_REQUEST_FAILURE';
export const FIELD_RESET_STATE = '@field/FIELD_RESET_STATE';
