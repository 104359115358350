import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import * as actions from './actions';

export type ObjectSettingsAction = ActionType<typeof actions>;

export interface iObjectSettingsState {
  fields: { [key: string]: IFieldSchema };
  permissions: { [key: string]: IFieldSchema };
};


export const FIELD_REQUEST = '@object-settings/FIELD_REQUEST';
export const FIELD_SUCCESS = '@object-settings/FIELD_SUCCESS';
export const FIELD_FAILURE = '@object-settings/FIELD_FAILURE';

export const CHANGE_CHECKBOX = '@object-settings/CHANGE_CHECKBOX';
