import { action } from 'typesafe-actions';

import { iDealProduct } from '../../../interfaces/objects/iDealProduct.interface';
import { iProduct } from '../../../interfaces/objects/iProduct.interface';
import * as types from './types';


export function dealProductSetValuesByFormik(props: {
  dealProduct: iDealProduct;
}) {
  return action(types.DEAL_PRODUCT_SET_VALUES_BY_FORMIK, {
    props
  });
}


export function dealProductGet(props: {
  history: any;
  id: string;
}) {
  return action(types.DEAL_PRODUCT_GET, {
    props
  });
}

export function dealProductGetSuccess(props: {
  dealProduct: iDealProduct,
}) {
  return action(types.DEAL_PRODUCT_GET_SUCCESS, {
    props
  });
}


export function dealProductGetFailure() {
  return action(types.DEAL_PRODUCT_GET_FAILURE);
}

// ---------------------------------------------------

export function dealProductRequest(data: {
  dealProduct: iDealProduct,
  product?: iProduct
}) {
  return action(types.DEAL_PRODUCT_REQUEST, {
    data
  });
}

export function dealProductCreatedSuccess(data: {
  dealProduct: iDealProduct,
}) {
  return action(types.DEAL_PRODUCT_CREATED_SUCCESS, {
    data,
  });
}

export function dealProductUpdatedSuccess(data: {
  dealProduct: iDealProduct,
}) {
  return action(types.DEAL_PRODUCT_UPDATED_SUCCESS, {
    data,
  });
}

export function dealProductRequestFailure() {
  return action(types.DEAL_PRODUCT_REQUEST_FAILURE);
}

// ---------------------------------------------------

export function dealProductSetProduct(props: {
  dealProduct: iDealProduct;
}) {
  return action(types.DEAL_PRODUCT_SET_PRODUCT, {
    props
  });
}

// ---------------------------------------------------

export function dealProductResetState() {
  return action(types.DEAL_PRODUCT_RESET_STATE);
}
