import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';

export function* contactGet({ payload }: ActionType<typeof actions.contactGet>) {
  // console.log('@contactGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/contacts/${payload.props.id}`);
    yield put(actions.contactGetSuccess({ contact: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.contactGetFailure());
  }
}

export function* contactRequest({ payload }: ActionType<typeof actions.contactRequest>) {
  console.log('@contactRequest/payload:: ', payload);
  const { address, contact } = payload.data;
  try {
    if(contact.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/contacts/${contact.id}`, contact);
      console.log('@contactUpdatedSuccess/response.data.payload:: ', response.data.payload)
      yield put(actions.contactUpdatedSuccess({ contact: { ...response.data.payload, address } }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/contacts/', contact);
      console.log('@contactCreatedSuccess/response.data.payload:: ', response.data.payload)
      yield put(actions.contactCreatedSuccess({ contact: { ...response.data.payload, address } }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.contactRequestFailure());
  }
}

export default all([
  takeLatest(types.CONTACT_GET, contactGet),
  takeLatest(types.CONTACT_REQUEST, contactRequest),
])
