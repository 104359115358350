import { action } from 'typesafe-actions';
import { iAddress } from '../../../interfaces/objects/iAddress.interface';
import * as types from './types';


export function addressGet(props: {
  history: any;
  id: string;
}) {
  return action(types.ADDRESS_GET, {
    props
  });
}

export function addressGetSuccess(props: {
  address: iAddress,
}) {
  return action(types.ADDRESS_GET_SUCCESS, {
    props
  });
}

export function addressGetFailure() {
  return action(types.ADDRESS_GET_FAILURE);
}

// ====================================================

export function addressRequest(props: {
  history: any;
  address: iAddress;
}) {
  return action(types.ADDRESS_REQUEST, {
    props
  });
}

export function addressCreatedSuccess(props: {
  address: iAddress,
}) {
  return action(types.ADDRESS_CREATED_SUCCESS, {
    props,
  });
}

export function addressUpdatedSuccess(props: {
  address: iAddress,
}) {
  return action(types.ADDRESS_UPDATED_SUCCESS, {
    props,
  });
}

export function addressRequestFailure() {
  return action(types.ADDRESS_REQUEST_FAILURE);
}

// ====================================================

export function addressPostalCodeRequest(props: {
  address: iAddress;
  history: any;
  postalCode: string;
}) {
  return action(types.ADDRESS_POSTAL_CODE_REQUEST, {
    props
  });
}

export function addressPostalCodeRequestSuccess(props: {
  address: iAddress,
}) {
  return action(types.ADDRESS_POSTAL_CODE_REQUEST_SUCCESS, {
    props,
  });
}

export function addressPostalCodeRequestFailure() {
  return action(types.ADDRESS_POSTAL_CODE_REQUEST_FAILURE);
}

// =======================================================


export function addressResetState() {
  return action(types.ADDRESS_RESET_STATE);
}
