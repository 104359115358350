import { action } from 'typesafe-actions';

import { iVehicleLocation } from '../../../interfaces/objects/iVehicleLocation.interface';
import * as types from './types';

export function vehicleLocationRequest(props: {
  history: any;
  vehicleLocation: iVehicleLocation,
}) {
  return action(types.VEHICLE_LOCATION_REQUEST, {
    props,
  });
}

export function vehicleLocationCreatedSuccess(props: {
  vehicleLocation: iVehicleLocation,
}) {
  return action(types.VEHICLE_LOCATION_CREATED_SUCCESS, {
    props,
  });
}

export function vehicleLocationUpdatedSuccess(props: {
  vehicleLocation: iVehicleLocation,
}) {
  return action(types.VEHICLE_LOCATION_UPDATED_SUCCESS, {
    props,
  });
}

export function vehicleLocationRequestFailure() {
  return action(types.VEHICLE_LOCATION_REQUEST_FAILURE);
}

export function vehicleLocationResetState() {
  return action(types.VEHICLE_LOCATION_RESET_STATE);
}

