import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import { reorder, move } from '../../../services/dnd';
import * as actions from './actions';
import * as types from './types';


export function* changePosition({ payload }: ActionType<typeof actions.changePosition>) {
  // console.log('@changePosition/payload:: ', payload)
  const { tasks, source, destination } = payload
  const sInd = +source.droppableId;
  const dInd = +destination.droppableId;

  if(sInd === dInd) {
    const items = reorder(tasks.lists[sInd], source.index, destination.index);
    payload.tasks.lists[sInd].cards = items;
  } else {
    const result = move(tasks.lists[sInd], tasks.lists[dInd], source, destination);
    const newLists = [...tasks.lists];
    newLists[sInd] = result[sInd];
    newLists[dInd] = result[dInd];

    payload.tasks.lists = newLists;
  }
}
export function* taskGet({ payload }: ActionType<typeof actions.taskGet>) {
  // console.log('@taskGet/payload:: ', payload)
  try {
    const response: any = yield call(api.get, `/api/tasks/${payload.props.id}`);
    yield put(actions.taskGetSuccess({ task: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.taskGetFailure());
  }
}


export function* taskRequest({ payload }: ActionType<typeof actions.taskRequest>) {
  // console.log('@taskRequest/payload:: ', payload)
  try {
    if(payload.props.task.id) {
      const response = yield call(api.patch, `/api/tasks/${payload.props.task.id}`, payload.props.task);
      yield put(actions.taskUpdatedSuccess({ task: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/tasks/', payload.props.task);
      yield put(actions.taskCreatedSuccess({ task: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.taskRequestFailure());
  }

}

export default all([
  takeLatest(types.CHANGE_POSITION, changePosition),
  takeLatest(types.TASK_GET, taskGet),
  takeLatest(types.TASK_REQUEST, taskRequest),
])
