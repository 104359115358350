import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iAccount } from '../../../interfaces/objects/iAccount.interface';
import * as actions from './actions';

export type AccountAction = ActionType<typeof actions>;

export interface iAccountState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iAccount;
}

export const ACCOUNT_REQUEST = '@account/ACCOUNT_REQUEST';
export const ACCOUNT_CREATED_SUCCESS = '@account/ACCOUNT_CREATED_SUCCESS';
export const ACCOUNT_UPDATED_SUCCESS = '@account/ACCOUNT_UPDATED_SUCCESS';
export const ACCOUNT_REQUEST_FAILURE = '@account/ACCOUNT_REQUEST_FAILURE';
export const ACCOUNT_RESET_STATE = '@account/ACCOUNT_RESET_STATE';
