import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import users from './users/sagas';
import theme from './theme/sagas'
import tasks from './tasks/sagas';
import accounts from './accounts/sagas';
import addresses from './addresses/sagas';
import commissions from './commissions/sagas';
import contacts from './contacts/sagas';
import deals from './deals/sagas';
import dealProducts from './deal-products/sagas';
import dealVehicles from './deal-vehicles/sagas';
import expenses from './expenses/sagas';
import fieldPermissions from './field-permissions/sagas';
import fields from './fields/sagas';
import financialInstitutions from './financial-institutions/sagas';
import financings from './financings/sagas';
import finances from './finances/sagas';
import leads from './leads/sagas';
import objectPermissions from './object-permissions/sagas';
import objectSettings from './object-settings/sagas';
import origins from './origins/sagas';
import pendencies from './pendencies/sagas';
import posMachines from './pos-machines/sagas';
import products from './products/sagas';
import ratings from './ratings/sagas';
import roles from './roles/sagas';
import sobjects from './sobjects/sagas';
import stores from './stores/sagas';
import transactions from './transactions/sagas';
import vehicleLocations from './vehicle-locations/sagas';
import vehicleModels from './vehicle-models/sagas';
import vehicles from './vehicles/sagas';

export default function* rootSaga(): any {
  return yield all([
    accounts,
    addresses,
    auth,
    commissions,
    contacts,
    deals,
    dealProducts,
    dealVehicles,
    expenses,
    fieldPermissions,
    fields,
    financialInstitutions,
    financings,
    finances,
    leads,
    objectPermissions,
    objectSettings,
    origins,
    pendencies,
    posMachines,
    products,
    ratings,
    roles,
    sobjects,
    stores,
    users,
    vehicleLocations,
    vehicleModels,
    vehicles,
    theme,
    tasks,
    transactions,
  ])
}
