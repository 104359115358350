import styled from 'styled-components';
import { iGlobalStyleProps } from '../../../styles/GlobalStyles';

export const Container = styled.div.attrs<iGlobalStyleProps>({
  className: "loading-data"
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  font-size: 30px;
  color: #fff;
  background: rgba(0,0,0 , 0.8);

  /* .loader {
    border: 16px solid ${(props: iGlobalStyleProps) => props.theme.colors.white};
    border-top: 16px solid ${(props: iGlobalStyleProps) => props.theme.colors.primary};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

`;
