import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* pendencyRequest({ payload }: ActionType<typeof actions.pendencyRequest>) {
  // console.log('@pendencyRequest/payload:: ', payload)
  try {
    if(payload.props.pendency.id) {
      const response = yield call(api.patch, `/api/pendencies/${payload.props.pendency.id}`, payload.props.pendency);
      yield put(actions.pendencyUpdatedSuccess({ pendency: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/pendencies/', payload);
      yield put(actions.pendencyCreatedSuccess({ pendency: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.pendencyRequestFailure());
  }

}

export default all([
  takeLatest(types.PENDENCY_REQUEST, pendencyRequest),
])
