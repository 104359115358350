import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import Routes from './routes';
import { store, persistor } from './store';
import { light } from './styles/themes/theme';
import GlobalStyles from './styles';
import { Loading } from './components/atoms/Loading';
import * as SLDS from './components/slds';
import './styles/index.css';
import SignInForm from './components/forms/SignInForm/SignInForm';


function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={light}>
          <SLDS.IconSettings iconPath="/assets/icons">
            <BrowserRouter>
              <SignInForm />
              <Routes />
            </BrowserRouter>
            <GlobalStyles />
            <Loading />
            <ToastContainer autoClose={1500} className="toast-container" />
          </SLDS.IconSettings>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
