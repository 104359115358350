import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* financialInstitutionRequest({ payload }: ActionType<typeof actions.financialInstitutionRequest>) {
  // console.log('@financialInstitutionRequest/payload:: ', payload.data)
  try {
    if(payload.data.financialInstitution.id) {
      const response = yield call(api.patch, `/api/financialInstitutions/${payload.data.financialInstitution.id}`, payload.data.financialInstitution);
      yield put(actions.financialInstitutionUpdatedSuccess({ financialInstitution: { ...response.data.payload } }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/financialInstitutions/', payload.data.financialInstitution);
      yield put(actions.financialInstitutionCreatedSuccess({ financialInstitution: { ...response.data.payload } }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.financialInstitutionRequestFailure());
  }

}


export default all([
  takeLatest(types.FINANCIAL_INSTITUTION_REQUEST, financialInstitutionRequest),
])
