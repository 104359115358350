import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* changeStatus({ payload }: ActionType<typeof actions.changeStatus>) {
  // console.log('@changeStatus/payload:: ', payload)

}

export function* userRequest({ payload }: ActionType<typeof actions.userRequest>) {
  // console.log('@userRequest/payload:: ', payload)
  try {
    if(payload.props.user.id) {
      const response = yield call(api.patch, `/api/users/${payload.props.user.id}`, payload.props.user);
      yield put(actions.userUpdatedSuccess({ user: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/users/', payload.props.user);
      yield put(actions.userCreatedSuccess({ user: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.userRequestFailure());
  }

}

export default all([
  takeLatest(types.CHANGE_STATUS, changeStatus),
  takeLatest(types.USER_REQUEST, userRequest),
])
