import { ExpenseAction, iExpenseState } from './types';
import * as types from './types';


const initialState: iExpenseState = {
  requestPerform: false,
  fields: {
    value: {
      name: 'value',
      label: 'Valor',
      type: 'currency',
    },
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea',
    },
    paymentDate: {
      name: 'paymentDate',
      label: 'Data de pagamento',
      type: 'date',
    },
    created: {
      name: 'created',
      label: 'Data de criação',
      type: 'date',
    },
    deadline: {
      name: 'deadline',
      label: 'Data de vencimento',
      type: 'date',
    },
    referenceMonth: {
      name: 'referenceMonth',
      label: 'Mês de referência',
      type: 'date[MM-yyyy]'
    },
    categoryId: {
      name: 'categoryId',
      label: 'Categoria',
      type: 'input',
    },
    subCategoryId: {
      name: 'subCategoryId',
      label: 'Subcategoria',
      type: 'input',
    },
    vehicleId: {
      name: 'vehicleId',
      label: 'Veículo',
      object: 'vehicles',
      type: 'lookup',
      hideModal: true,
    },
    dealId: {
      name: 'dealId',
      label: 'Negociação',
      object: 'deals',
      type: 'lookup',
      hideModal: true,
    },
    catererId: {
      name: 'catererId',
      label: 'Fornecedor',
      object: 'contacts',
      type: 'lookup',
    },
    responsibleId: {
      name: 'responsibleId',
      label: 'Responsável',
      object: 'users',
      type: 'lookup',
      hideModal: true,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          key: 'OPEN',
          label: 'Em aberto',
          value: 'OPEN'
        },
        {
          key: 'PAID',
          label: 'Pago',
          value: 'PAID'
        },
        {
          key: 'OVERDUE',
          label: 'Em atraso',
          value: 'OVERDUE'
        }
      ],
    },
  },
  data: {
    value: 0,
    title: '',
    description: '',
    status: 'OPEN',
    categoryId: '',
    subCategoryId: '',
  }
}

export default function changeState(
  state = initialState,
  action: ExpenseAction
): iExpenseState {
  switch(action.type) {
    case types.EXPENSE_REQUEST:
      return {
        ...state,
      };
    case types.EXPENSE_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.expense,
        requestPerform: true
      };
    case types.EXPENSE_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.expense,
        requestPerform: true
      };
    case types.EXPENSE_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.EXPENSE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
