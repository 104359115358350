import { RatingAction, iRatingState } from './types';

import * as types from './types';


const initialState: iRatingState = {
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input'
    },
    ratedValue: {
      name: 'ratedValue',
      label: 'Valor avaliado',
      type: 'currency'
    },
    vehicleBody: {
      name: 'vehicleBody',
      label: 'Carroceria',
      type: 'rating'
    },
    vehicleBodyObs: {
      name: 'vehicleBodyObs',
      label: 'Obs. Carroceria',
      type: 'input'
    },
    tires: {
      name: 'tires',
      label: 'Pneus',
      type: 'rating'
    },
    tiresObs: {
      name: 'tiresObs',
      label: 'Obs. Pneus',
      type: 'input'
    },
    wheels: {
      name: 'wheels',
      label: 'Rodas',
      type: 'rating'
    },
    wheelsObs: {
      name: 'wheelsObs',
      label: 'Obs. Rodas',
      type: 'input'
    },
    films: {
      name: 'films',
      label: 'Películas',
      type: 'rating'
    },
    filmsObs: {
      name: 'filmsObs',
      label: 'Obs. Películas',
      type: 'input'
    },
    headlights: {
      name: 'headlights',
      label: 'Faróis',
      type: 'rating'
    },
    headlightsObs: {
      name: 'headlightsObs',
      label: 'Obs. Faróis',
      type: 'input'
    },
    windows: {
      name: 'windows',
      label: 'Vidros',
      type: 'rating'
    },
    windowsObs: {
      name: 'windowsObs',
      label: 'Obs. Vidros',
      type: 'input'
    },
    spareTire: {
      name: 'spareTire',
      label: 'Estepe',
      type: 'rating'
    },
    spareTireObs: {
      name: 'spareTireObs',
      label: 'Obs. Estepe',
      type: 'input'
    },
    seat: {
      name: 'seat',
      label: 'Bancos',
      type: 'rating'
    },
    seatObs: {
      name: 'seatObs',
      label: 'Obs. Bancos',
      type: 'input'
    },
    trunk: {
      name: 'trunk',
      label: 'Porta-malas',
      type: 'rating'
    },
    trunkObs: {
      name: 'trunkObs',
      label: 'Obs. Porta-malas',
      type: 'input'
    },
    gearHandle: {
      name: 'gearHandle',
      label: 'Manopla do câmbio',
      type: 'rating'
    },
    gearHandleObs: {
      name: 'gearHandleObs',
      label: 'Obs. Manopla do câmbio',
      type: 'input'
    },
    steeringWheel: {
      name: 'steeringWheel',
      label: 'Volante',
      type: 'rating'
    },
    steeringWheelObs: {
      name: 'steeringWheelObs',
      label: 'Obs. Volante',
      type: 'input'
    },
    seatBelt: {
      name: 'seatBelt',
      label: 'Cintos de segurança',
      type: 'rating'
    },
    seatBeltObs: {
      name: 'seatBeltObs',
      label: 'Obs. Cintos de segurança',
      type: 'input'
    },
    doors: {
      name: 'doors',
      label: 'Portas',
      type: 'rating'
    },
    doorsObs: {
      name: 'doorsObs',
      label: 'Obs. Portas',
      type: 'input'
    },
    roof: {
      name: 'roof',
      label: 'Forro do teto',
      type: 'rating'
    },
    roofObs: {
      name: 'roofObs',
      label: 'Obs. Forro do teto',
      type: 'input'
    },
    mat: {
      name: 'mat',
      label: 'Tapetes',
      type: 'rating'
    },
    matObs: {
      name: 'matObs',
      label: 'Obs. Tapetes',
      type: 'input'
    },
    carpet: {
      name: 'carpet',
      label: 'Carpetes',
      type: 'rating'
    },
    carpetObs: {
      name: 'carpetObs',
      label: 'Obs. Carpetes',
      type: 'input'
    },
    console: {
      name: 'console',
      label: 'Console',
      type: 'rating'
    },
    consoleObs: {
      name: 'consoleObs',
      label: 'Obs. Console',
      type: 'input'
    },
    panel: {
      name: 'panel',
      label: 'Painel',
      type: 'rating'
    },
    panelObs: {
      name: 'panelObs',
      label: 'Obs. Painel',
      type: 'input'
    },
    airConditioner: {
      name: 'airConditioner',
      label: 'Ar-condicionado',
      type: 'rating'
    },
    airConditionerObs: {
      name: 'airConditionerObs',
      label: 'Obs. Ar-condicionado',
      type: 'input'
    },
    brake: {
      name: 'brake',
      label: 'Freios',
      type: 'rating'
    },
    brakeObs: {
      name: 'brakeObs',
      label: 'Obs. Freios',
      type: 'input'
    },
    suspension: {
      name: 'suspension',
      label: 'Suspensão',
      type: 'rating'
    },
    suspensionObs: {
      name: 'suspensionObs',
      label: 'Obs. Suspensão',
      type: 'input'
    },
    gearing: {
      name: 'gearing',
      label: 'Embreagem',
      type: 'rating'
    },
    gearingObs: {
      name: 'gearingObs',
      label: 'Obs. Embreagem',
      type: 'input'
    },
    transmission: {
      name: 'transmission',
      label: 'Câmbio',
      type: 'rating'
    },
    transmissionObs: {
      name: 'transmissionObs',
      label: 'Obs. Câmbio',
      type: 'input'
    },
    fluids: {
      name: 'fluids',
      label: 'Fluidos',
      type: 'rating'
    },
    fluidsObs: {
      name: 'fluidsObs',
      label: 'Obs. Fluidos',
      type: 'input'
    },
    engine: {
      name: 'engine',
      label: 'Motor',
      type: 'rating'
    },
    engineObs: {
      name: 'engineObs',
      label: 'Obs. Motor',
      type: 'input'
    },
    cables: {
      name: 'cables',
      label: 'Cabos',
      type: 'rating'
    },
    cablesObs: {
      name: 'cablesObs',
      label: 'Obs. Cabos',
      type: 'input'
    },
    lights: {
      name: 'lights',
      label: 'Luzes',
      type: 'rating'
    },
    lightsObs: {
      name: 'lightsObs',
      label: 'Obs. Luzes',
      type: 'input'
    },
    locks: {
      name: 'locks',
      label: 'Travas',
      type: 'rating'
    },
    locksObs: {
      name: 'locksObs',
      label: 'Obs. Travas',
      type: 'input'
    },
    mirros: {
      name: 'mirros',
      label: 'Retrovisores',
      type: 'rating'
    },
    mirrosObs: {
      name: 'mirrosObs',
      label: 'Obs. Retrovisores',
      type: 'input'
    },
    media: {
      name: 'media',
      label: 'Mídia',
      type: 'rating'
    },
    mediaObs: {
      name: 'mediaObs',
      label: 'Obs. Mídia',
      type: 'input'
    },
    cleaners: {
      name: 'cleaners',
      label: 'Limpadores',
      type: 'rating'
    },
    cleanersObs: {
      name: 'cleanersObs',
      label: 'Obs. Limpadores',
      type: 'input'
    },
    vents: {
      name: 'vents',
      label: 'Saídas de ar',
      type: 'rating'
    },
    ventsObs: {
      name: 'ventsObs',
      label: 'Obs. Saídas de ar',
      type: 'input'
    },
    mandatoryEquipment: {
      name: 'mandatoryEquipment',
      label: 'Equipamento obrigatório',
      type: 'rating'
    },
    mandatoryEquipmentObs: {
      name: 'mandatoryEquipmentObs',
      label: 'Obs. Equipamento obrigatório',
      type: 'input'
    },
    evaluatorId: {
      name: 'evaluatorId',
      label: 'Avaliador',
      object: 'users',
      type: 'lookup',
    },
    clientId: {
      name: 'clientId',
      label: 'Cliente',
      object: 'contacts',
      type: 'lookup',
    },
  },
  data: {
    name: '',
    ratedValue: 0,
    vehicleBody: 1,
    vehicleBodyObs: '',
    tires: 1,
    tiresObs: '',
    wheels: 1,
    wheelsObs: '',
    films: 1,
    filmsObs: '',
    headlights: 1,
    headlightsObs: '',
    windows: 1,
    windowsObs: '',
    spareTire: 1,
    spareTireObs: '',
    seat: 1,
    seatObs: '',
    trunk: 1,
    trunkObs: '',
    gearHandle: 1,
    gearHandleObs: '',
    steeringWheel: 1,
    steeringWheelObs: '',
    seatBelt: 1,
    seatBeltObs: '',
    doors: 1,
    doorsObs: '',
    roof: 1,
    roofObs: '',
    mat: 1,
    matObs: '',
    carpet: 1,
    carpetObs: '',
    console: 1,
    consoleObs: '',
    panel: 1,
    panelObs: '',
    airConditioner: 1,
    airConditionerObs: '',
    brake: 1,
    brakeObs: '',
    suspension: 1,
    suspensionObs: '',
    gearing: 1,
    gearingObs: '',
    transmission: 1,
    transmissionObs: '',
    fluids: 1,
    fluidsObs: '',
    engine: 1,
    engineObs: '',
    cables: 1,
    cablesObs: '',
    lights: 1,
    lightsObs: '',
    locks: 1,
    locksObs: '',
    mirros: 1,
    mirrosObs: '',
    media: 1,
    mediaObs: '',
    cleaners: 1,
    cleanersObs: '',
    vents: 1,
    ventsObs: '',
    mandatoryEquipment: 1,
    mandatoryEquipmentObs: '',
  }
}

export default function changeState(
  state = initialState,
  action: RatingAction
): iRatingState {
  switch(action.type) {
    case types.RATING_REQUEST:
      return {
        ...state,
      };
    case types.RATING_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rating,
        requestPerform: true
      };
    case types.RATING_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rating,
        requestPerform: true
      };
    case types.RATING_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.RATING_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
