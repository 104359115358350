import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type LoadingAction = ActionType<typeof actions>;

export interface iLoadingState {
  asyncLoadingItems: string[];
  loadingItems: string[];
}

export const LOADING_ADD_ASYNC_ITEM = '@lead/LOADING_ADD_ASYNC_ITEM';
export const LOADING_ADD_ITEM = '@lead/LOADING_ADD_ITEM';
export const LOADING_REMOVE_ASYNC_ITEM = '@lead/LOADING_REMOVE_ASYNC_ITEM';
export const LOADING_REMOVE_ITEM = '@lead/LOADING_REMOVE_ITEM';
