import { FinancingAction, iFinancingState } from './types';
import * as types from './types';

const initialState: iFinancingState = {
  requestPerform: false,
  fields: {
    financingValue: {
      name: 'financingValue',
      label: 'Valor financiado',
      type: 'currency',
    },
    installmentValue: {
      name: 'installmentValue',
      label: 'Parcela',
      type: 'currency',
    },
    returnValue: {
      name: 'returnValue',
      label: 'Retorno',
      type: 'currency',
    },
    term: {
      name: 'term',
      label: 'Prazo',
      type: 'input',
    },
    coefficient: {
      name: 'coefficient',
      label: 'Coeficiente',
      type: 'input',
    },
    gracePeriod: {
      name: 'gracePeriod',
      label: 'Carência(dias)',
      type: 'input',
    },
    tac: {
      name: 'tac',
      label: 'TAC',
      type: 'input',
    },
    accountId: {
      name: 'accountId',
      label: 'Conta p/ depósito',
      object: 'accounts',
      type: 'lookup',
    },
    effectiveDate: {
      name: 'effectiveDate',
      label: 'Data da efetivação',
      type: 'date'
    },
    dealId: {
      disabled: true,
      name: 'dealId',
      label: 'Negociação',
      type: 'input',
    },
    vehicleId: {
      name: 'vehicleId',
      label: 'Veículo',
      object: 'vehicles',
      type: 'lookup',
      hideModal: true,
    },
    financialInstitutionId: {
      name: 'financialInstitutionId',
      label: 'Instituição financeira',
      object: 'financialInstitutions',
      type: 'lookup',
      hideModal: true,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          key: 'WAITING',
          label: 'Aguardando',
          value: 'WAITING'
        },
        {
          key: 'APPROVED',
          label: 'Aprovado',
          value: 'APPROVED'
        },
        {
          key: 'REFUSED',
          label: 'Recusado',
          value: 'REFUSED'
        },
        {
          key: 'ANALYZING',
          label: 'Em análise',
          value: 'ANALYZING'
        },
        {
          key: 'PAID',
          label: 'Pago',
          value: 'PAID'
        }
      ],
    },
  },
  data: {
    financingValue: 0,
    installmentValue: 0,
    returnValue: 0,
    term: 0,
    coefficient: 0,
    gracePeriod: 0,
    tac: 0,
    dealId: '',
    status: 'WAITING',
  }
}

export default function changeState(
  state = initialState,
  action: FinancingAction
): iFinancingState {
  switch(action.type) {
    case types.FINANCING_REQUEST:
      return {
        ...state,
      };
    case types.FINANCING_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.financing,
        requestPerform: true,
      };
    case types.FINANCING_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.financing,
        requestPerform: true,
      };
    case types.FINANCING_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.FINANCING_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
