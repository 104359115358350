import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { v4 as createId } from 'uuid';

import { iImage } from "../../interfaces/iImage";
import { storage } from '.';
import { toast } from 'react-toastify';

export const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export const getAll = async (path: string, tenantId: string): Promise<iImage[]> => {
  let list: iImage[] = [];

  const imagesFolder = ref(storage, `images/${tenantId}${path}`);
  const imageList = await listAll(imagesFolder);

  for(let i in imageList.items) {
    let imageUrl = await getDownloadURL(imageList.items[i])

    list.push({
      name: imageList.items[i].name,
      url: imageUrl
    });
  }

  return list;
}

export const insert = async (file: File, path: string, tenantId: string): Promise<iImage> => {
  if(allowedTypes.includes(file.type)) {

    let randomName = createId();
    let newFile = ref(storage, `images/${tenantId}${path}/${randomName}`);

    let upload = await uploadBytes(newFile, file);
    let imageUrl = await getDownloadURL(upload.ref);

    return { name: upload.ref.name, url: imageUrl };

  } else {
    toast.error('Tipo de arquivo não permitido');
    throw new Error('Tipo de arquivo não permitido');
  }
}

export const exclude = async (name: string, path: string, tenantId: string) => {
  let imageRef = ref(storage, `images/${tenantId}${path}/${name}`);
  await deleteObject(imageRef);
}

export const excludeAll = async (path: string, tenantId: string) => {
  const imagesFolder = ref(storage, `images/${tenantId}${path}`);
  const { items } = await listAll(imagesFolder);
  items.forEach(async (image) => {
    await deleteObject(image);
  })
}
