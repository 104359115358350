import { CommissionAction, iCommissionsState } from './types';

import * as types from './types';


const initialState: iCommissionsState = {
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input'
    },
    value: {
      name: 'value',
      label: 'Valor',
      type: 'currency'
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'input',
    },
    receiverId: {
      name: 'receiverId',
      label: 'Cliente',
      object: 'contacts',
      type: 'lookup',
    },
  },
  data: {
    name: '',
    value: 0,
    type: '',
  }
}

export default function changeState(
  state = initialState,
  action: CommissionAction
): iCommissionsState {
  switch(action.type) {
    case types.COMMISSION_REQUEST:
      return {
        ...state,
      };
    case types.COMMISSION_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.commission,
        requestPerform: true
      };
    case types.COMMISSION_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.commission,
        requestPerform: true
      };
    case types.COMMISSION_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.COMMISSION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
}
