import { action } from 'typesafe-actions';
import * as types from './types';
import { iObjectSettingsState } from './types';

export function fieldRequest () {
  return action(types.FIELD_REQUEST,);
}

export function fieldSuccess ({ objSchema }: { objSchema: iObjectSettingsState }) {
  return action(types.FIELD_SUCCESS, {
    objSchema,
  });
}

export function changeCheckBox ({
  name,
  property
}: {
  name: string;
  property: string;
}) {
  return action(types.CHANGE_CHECKBOX, {
    name,
    property
  });
}

export function fieldFailure () {
  return action(types.FIELD_FAILURE);
}
