import { action } from 'typesafe-actions';
import { iFinancing } from '../../../interfaces/objects/iFinancing.interface';
import * as types from './types';

export function financingRequest(props: {
  history: any;
  financing: iFinancing,
}) {
  return action(types.FINANCING_REQUEST, {
    props
  });
}

export function financingCreatedSuccess(props: {
  financing: iFinancing,
}) {
  return action(types.FINANCING_CREATED_SUCCESS, {
    props,
  });
}

export function financingUpdatedSuccess(props: {
  financing: iFinancing,
}) {
  return action(types.FINANCING_UPDATED_SUCCESS, {
    props,
  });
}

export function financingRequestFailure() {
  return action(types.FINANCING_REQUEST_FAILURE);
}

export function financingResetState() {
  return action(types.FINANCING_RESET_STATE);
}
