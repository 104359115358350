import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iPendency } from '../../../interfaces/objects/iPendency.interface';
import * as actions from './actions';

export type PendencyAction = ActionType<typeof actions>;

export interface iPendencyState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iPendency;
}

export const PENDENCY_GET = '@pendency/PENDENCY_GET';
export const PENDENCY_GET_SUCCESS = '@pendency/PENDENCY_GET_SUCCESS';
export const PENDENCY_GET_FAILURE = '@pendency/PENDENCY_GET_FAILURE';

export const PENDENCY_REQUEST = '@pendency/PENDENCY_REQUEST';
export const PENDENCY_CREATED_SUCCESS = '@pendency/PENDENCY_CREATED_SUCCESS';
export const PENDENCY_UPDATED_SUCCESS = '@pendency/PENDENCY_UPDATED_SUCCESS';
export const PENDENCY_REQUEST_FAILURE = '@pendency/PENDENCY_REQUEST_FAILURE';
export const PENDENCY_RESET_STATE = '@pendency/PENDENCY_RESET_STATE';
