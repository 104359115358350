import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iAddress } from '../../../interfaces/objects/iAddress.interface';
import * as actions from './actions';

export type AddresseAction = ActionType<typeof actions>;

export interface iAddresseState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iAddress;
}

export const ADDRESS_GET = '@address/ADDRESS_GET';
export const ADDRESS_GET_SUCCESS = '@address/ADDRESS_GET_SUCCESS';
export const ADDRESS_GET_FAILURE = '@address/ADDRESS_GET_FAILURE';


export const ADDRESS_REQUEST = '@address/ADDRESS_REQUEST';
export const ADDRESS_CREATED_SUCCESS = '@address/ADDRESS_CREATED_SUCCESS';
export const ADDRESS_UPDATED_SUCCESS = '@address/ADDRESS_UPDATED_SUCCESS';
export const ADDRESS_REQUEST_FAILURE = '@address/ADDRESS_REQUEST_FAILURE';
export const ADDRESS_RESET_STATE = '@address/ADDRESS_RESET_STATE';


export const ADDRESS_POSTAL_CODE_REQUEST = '@address/ADDRESS_POSTAL_CODE_REQUEST';
export const ADDRESS_POSTAL_CODE_REQUEST_SUCCESS = '@address/ADDRESS_POSTAL_CODE_REQUEST_SUCCESS';
export const ADDRESS_POSTAL_CODE_REQUEST_FAILURE = '@address/ADDRESS_POSTAL_CODE_REQUEST_FAILURE';
