import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* sobjectGet({ payload }: ActionType<typeof actions.sobjectGet>) {
  // console.log('@sobjectGet/payload:: ', payload)
  try {
    const response: any = yield call(api.get, `/api/sObjects/${payload.props.id}`);
    yield put(actions.sobjectGetSuccess({ sobject: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.sobjectGetFailure());
  }
}

export function* sobjectRequest({ payload }: ActionType<typeof actions.sobjectRequest>) {
  // console.log('@sobjecteRequest/payload:: ', payload)
  try {
    if(payload.props.sobject.id) {
      const response = yield call(api.patch, `/api/sObjects/${payload.props.sobject.id}`, payload.props.sobject);
      yield put(actions.sobjectUpdatedSuccess({ sobject: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/sObjects/', payload.props.sobject);
      yield put(actions.sobjectCreatedSuccess({ sobject: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Err', err)
    console.log('Erro:: ', err)
    yield put(actions.sobjectRequestFailure());
  }
}

export default all([
  takeLatest(types.SOBJECT_GET, sobjectGet),
  takeLatest(types.SOBJECT_REQUEST, sobjectRequest),
])
