import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';

export function* dealProductGet({ payload }: ActionType<typeof actions.dealProductGet>) {
  // console.log('@dealProductGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/dealProducts/${payload.props.id}`);
    yield put(actions.dealProductGetSuccess({ dealProduct: response.data.payload }));
  } catch {
    yield put(actions.dealProductGetFailure());
  }
}


export function* dealProductRequest({ payload }: ActionType<typeof actions.dealProductRequest>) {
  // console.log('@dealProductRequest/payload:: ', payload)
  try {
    if(payload.data.dealProduct.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/dealProducts/${payload.data.dealProduct.id}`, payload.data.dealProduct);
      yield put(actions.dealProductUpdatedSuccess({ dealProduct: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/dealProducts/', payload.data.dealProduct);
      yield put(actions.dealProductCreatedSuccess({ dealProduct: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.dealProductRequestFailure());
  }

}

export function* dealProductSetProduct({ payload }: ActionType<typeof actions.dealProductSetProduct>) {
  // console.log('@dealProductSetProduct/payload:: ', payload);
  yield put(actions.dealProductSetValuesByFormik({ dealProduct: payload.props.dealProduct }));
}

export default all([
  takeLatest(types.DEAL_PRODUCT_GET, dealProductGet),
  takeLatest(types.DEAL_PRODUCT_REQUEST, dealProductRequest),
  takeLatest(types.DEAL_PRODUCT_SET_PRODUCT, dealProductSetProduct),
])
