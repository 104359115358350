import { action } from 'typesafe-actions';
import { iExpense } from '../../../interfaces/objects/iExpense.interface';
import * as types from './types';

export function expenseRequest(data: {
  history: any;
  expense: iExpense,
}) {
  return action(types.EXPENSE_REQUEST, {
    data
  });
}

export function expenseCreatedSuccess(data: {
  expense: iExpense,
}) {
  return action(types.EXPENSE_CREATED_SUCCESS, {
    data,
  });
}

export function expenseUpdatedSuccess(data: {
  expense: iExpense,
}) {
  return action(types.EXPENSE_UPDATED_SUCCESS, {
    data,
  });
}

export function expenseRequestFailure() {
  return action(types.EXPENSE_REQUEST_FAILURE);
}

export function expenseResetState() {
  return action(types.EXPENSE_RESET_STATE);
}
