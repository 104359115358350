import { InputLabel, InputLabelProps } from '@material-ui/core';
import React, { FC } from 'react'
import { BiLayerPlus } from 'react-icons/bi';

import { StyledModalLabel } from './styled';

interface Props extends InputLabelProps {
  label?: string;
  size?: number;
}

export const ModalLabel: FC<Props> = ({ color = 'primary', label = '', size = 0, ...props }) => {

  return (
    <StyledModalLabel size={size}>
      <InputLabel
        {...props}
        color={color}
      >
        <BiLayerPlus size={size} />
        {' ' + label}
      </InputLabel>
    </StyledModalLabel>
  );

}
