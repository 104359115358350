import { action } from 'typesafe-actions';
import { iFieldPermission } from '../../../interfaces/objects/iFieldPermission.interface';
import * as types from './types';

export function fieldPermissionRequest(props: {
  history: any;
  fieldPermission: iFieldPermission,
}) {
  return action(types.FIELD_PERMISSION_REQUEST, {
    props
  });
}

export function fieldPermissionCreatedSuccess(props: {
  fieldPermission: iFieldPermission,
}) {
  return action(types.FIELD_PERMISSION_CREATED_SUCCESS, {
    props
  });
}

export function fieldPermissionUpdatedSuccess(props: {
  fieldPermission: iFieldPermission,
}) {
  return action(types.FIELD_PERMISSION_UPDATED_SUCCESS, {
    props
  });
}

export function fieldPermissionRequestFailure() {
  return action(types.FIELD_PERMISSION_REQUEST_FAILURE);
}

export function fieldPermissionResetState() {
  return action(types.FIELD_PERMISSION_RESET_STATE);
}
