import { action } from 'typesafe-actions';

import { iRating } from '../../../interfaces/objects/iRating.interface';
import * as types from './types';

export function ratingRequest(data: {
  history: any;
  rating: iRating,
}) {
  return action(types.RATING_REQUEST, {

    data
  });
}

export function ratingCreatedSuccess(data: {
  rating: iRating,
}) {
  return action(types.RATING_CREATED_SUCCESS, {

    data,
  });
}

export function ratingUpdatedSuccess(data: {
  rating: iRating,
}) {
  return action(types.RATING_UPDATED_SUCCESS, {
    data,
  });
}

export function ratingRequestFailure() {
  return action(types.RATING_REQUEST_FAILURE);
}

export function ratingResetState() {
  return action(types.RATING_RESET_STATE);
}
