import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';

export function* objectPermissionGet({ payload }: ActionType<typeof actions.objectPermissionGet>) {
  // console.log('@objectPermissionGet/payload:: ', payload)
  const { sobjectId, roleId, history } = payload.props
  try {
    const response: any = yield call(api.get, `/api/object-permissions/getObjectPermissionByRoleAndObjectId/?sobjectId=${sobjectId}&roleId=${roleId}`);
    yield put(actions.objectPermissionGetSuccess({ objectPermission: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.objectPermissionGetFailure());
  }
}

export function* objectPermissionRequest({ payload }: ActionType<typeof actions.objectPermissionRequest>) {
  // console.log('@objectPermissionRequest/payload:: ', payload)
  try {
    if(payload.props.objectPermission.id) {
      const response = yield call(api.patch, `/api/object-permissions/${payload.props.objectPermission.id}`, payload.props.objectPermission);
      yield put(actions.objectPermissionUpdatedSuccess({ objectPermission: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/object-permissions/', payload.props.objectPermission);
      yield put(actions.objectPermissionCreatedSuccess({ objectPermission: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.objectPermissionRequestFailure());
  }

}

export default all([
  takeLatest(types.OBJECT_PERMISSION_GET, objectPermissionGet),
  takeLatest(types.OBJECT_PERMISSION_REQUEST, objectPermissionRequest),
])
