import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iVehicleLocation } from '../../../interfaces/objects/iVehicleLocation.interface';
import * as actions from './actions';

export type VehicleLocationAction = ActionType<typeof actions>;

export interface iVehicleLocationState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iVehicleLocation;
}

export const VEHICLE_LOCATION_REQUEST = '@vehicleLocation/VEHICLE_LOCATION_REQUEST';
export const VEHICLE_LOCATION_CREATED_SUCCESS = '@vehicleLocation/VEHICLE_LOCATION_CREATED_SUCCESS';
export const VEHICLE_LOCATION_UPDATED_SUCCESS = '@vehicleLocation/VEHICLE_LOCATION_UPDATED_SUCCESS';
export const VEHICLE_LOCATION_REQUEST_FAILURE = '@vehicleLocation/VEHICLE_LOCATION_REQUEST_FAILURE';
export const VEHICLE_LOCATION_RESET_STATE = '@vehicleLocation/VEHICLE_LOCATION_RESET_STATE';
