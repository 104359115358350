import { api } from '../../../services/api/axios';
import { iAuthState, AuthAction } from './types';
import * as types from './types';
import Cookies from 'js-cookie';

const initialState: iAuthState = {
  showAuthModal: false,
  showStoresModal: false,
  isSignedIn: false,
  error: false,
};

export default function auth(
  state = initialState,
  action: AuthAction
): iAuthState {
  switch(action.type) {
    case types.AUTH_SET_SHOW_AUTH_MODAL:
      return {
        ...state,
        showAuthModal: action.payload.props.showAuthModal,
      };
    case types.AUTH_SIGN_IN_REQUEST:
      return {
        ...state,
      };
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        tenant: action.payload.props.decodedTokenPayload.tenant,
        user: action.payload.props.decodedTokenPayload.user,
        userStores: action.payload.props.userStores,
        showStoresModal: action.payload.props.showStoresModal,
        showAuthModal: false,
        isSignedIn: true,
      };
    case types.AUTH_SIGN_IN_FAILURE:
      delete api.defaults.headers.Authorization;
      delete api.defaults.headers.Store;
      return {
        ...state,
        error: true,
      };
    case types.AUTH_SELECT_STORE_SUCCESS: {
      Cookies.set('store', String(action.payload.props.store.id));
      api.defaults.headers.Store = Cookies.get('store');
      return {
        ...state,
        store: action.payload.props.store,
        showStoresModal: false,
      }
    }
    case types.AUTH_LOGOUT:
      delete api.defaults.headers.Authorization;
      delete api.defaults.headers.Store;
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
