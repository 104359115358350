import { FinancialInstitutionAction, iFinancialInstitutionState } from './types';

import * as types from './types';


const initialState: iFinancialInstitutionState = {
  requestPerform: false,
  fields: {
    contact: {
      name: 'contactId',
      label: 'Contato',
      object: 'contacts',
      type: 'lookup',
    },
  },
  data: {
    contactId: ''
  }
}

export default function changeState(
  state = initialState,
  action: FinancialInstitutionAction
): iFinancialInstitutionState {
  switch(action.type) {
    case types.FINANCIAL_INSTITUTION_REQUEST:
      return {
        ...state,
      };
    case types.FINANCIAL_INSTITUTION_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.financialInstitution,
        requestPerform: true
      };
    case types.FINANCIAL_INSTITUTION_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.financialInstitution,
        requestPerform: true
      };
    case types.FINANCIAL_INSTITUTION_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.FINANCIAL_INSTITUTION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
