import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* posMachineGet({ payload }: ActionType<typeof actions.posMachineGet>) {
  // console.log('@posMachineGet/payload:: ', payload)
  try {
    const response: any = yield call(api.get, `/api/posmachines/${payload.id}`);
    yield put(actions.posMachineGetSuccess(response.data.payload));
  } catch(err) {
    console.log('Erro:: ', err)
    // yield put(actions.posMachineGetFailure());
  }
}


export function* posMachineRequest({ payload }: ActionType<typeof actions.posMachineRequest>) {
  // console.log('@posMachineRequest/payload:: ', payload)
  try {
    if(payload.id) {
      const response = yield call(api.patch, `/api/posmachines/${payload.id}`, payload);
      yield put(actions.posMachineUpdatedSuccess({ ...response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/posmachines/', payload);
      yield put(actions.posMachineCreatedSuccess({ ...response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.posMachineRequestFailure());
  }

}

export default all([
  takeLatest(types.POS_MACHINE_GET, posMachineGet),
  takeLatest(types.POS_MACHINE_REQUEST, posMachineRequest),
])
