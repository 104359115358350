import { LeadAction, iLeadState } from './types';
import * as types from './types';


const initialState: iLeadState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input'
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea'
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'BUY',
          label: 'Compra',
          value: 'BUY'
        },
        {
          key: 'SELL',
          label: 'Venda',
          value: 'SELL'
        },
        {
          key: 'TRADE',
          label: 'Troca',
          value: 'TRADE'
        }
      ],
    },
    desiredYear: {
      name: 'desiredYear',
      label: 'Ano desejado',
      type: 'input'
    },
    km: {
      name: 'km',
      label: 'KM',
      type: 'input'
    },
    phone: {
      name: 'phone',
      label: 'Telefone',
      type: 'input'
    },
    email: {
      name: 'email',
      label: 'E-mail',
      type: 'input'
    },
    originId: {
      name: 'originId',
      label: 'Origem',
      type: 'input'
    },
    proposedValue: {
      name: 'proposedValue',
      label: 'Valor proposto',
      type: 'currency'
    },
    vehicleId: {
      name: 'vehicleId',
      label: 'Veículo',
      type: 'input'
    },
    desiredValue: {
      name: 'desiredValue',
      label: 'Valor desejado',
      type: 'currency'
    }
  },
  data: {
    id: '',
    name: '',
    description: '',
    type: 'BUY',
    desiredYear: '',
    km: '',
    phone: '',
    email: '',
    originId: '',
    proposedValue: 0,
    vehicleId: '',
    desiredValue: 0,
    desiredVehicles: [],
    offeredVehicles: []
  }
}

export default function changeState(
  state = initialState,
  action: LeadAction
): iLeadState {
  switch(action.type) {
    case types.LEAD_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.lead,
        }
      };
    }
    case types.LEAD_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.LEAD_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.lead,
        loadingData: false
      };
    case types.LEAD_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.LEAD_REQUEST:
      return {
        ...state,
      };
    case types.LEAD_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.lead,
        requestPerform: true,
      };
    case types.LEAD_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.lead,
        requestPerform: true,
      };
    case types.LEAD_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.LEAD_SET_DESIRED_VEHICLE:
      return {
        ...state,
      };
    case types.LEAD_REMOVE_DESIRED_VEHICLE:
      return {
        ...state,
      };
    case types.LEAD_SET_OFFERED_VEHICLE:
      return {
        ...state,
      };
    case types.LEAD_REMOVE_OFFERED_VEHICLE:
      return {
        ...state,
      };
    case types.LEAD_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
