import { FieldPermissionAction, iFieldPermissionState } from './types';

import * as types from './types';


const initialState: iFieldPermissionState = {
  requestPerform: false,
  fields: {
    isVisible: {
      name: 'isVisible',
      type: 'checkbox',
    },
    isUpdateable: {
      name: 'isUpdateable',
      type: 'checkbox',
    },
    sobjectId: {
      name: 'sobjectId',
      label: 'sobjectId',
      type: 'input',
      hidden: true
    },
    fieldId: {
      name: 'fieldId',
      label: 'fieldId',
      type: 'input',
      hidden: true
    },
    roleId: {
      name: 'roleId',
      label: 'roleId',
      type: 'input',
      hidden: true
    },
  },
  data: {
    isVisible: false,
    isUpdateable: false,
    sobjectId: '',
    fieldId: '',
    roleId: '',
  }
}

export default function changeState(
  state = initialState,
  action: FieldPermissionAction
): iFieldPermissionState {
  switch(action.type) {
    case types.FIELD_PERMISSION_REQUEST:
      return {
        ...state,
      };
    case types.FIELD_PERMISSION_CREATED_SUCCESS:
      return {
        ...state,
        requestPerform: true
      };
    case types.FIELD_PERMISSION_UPDATED_SUCCESS:
      return {
        ...state,
        requestPerform: true
      };
    case types.FIELD_PERMISSION_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.FIELD_PERMISSION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
