import { FieldAction, iFieldState } from './types';

import * as types from './types';


const initialState: iFieldState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    label: {
      name: 'label',
      label: 'Label',
      type: 'input',
    },
    sobjectId: {
      name: 'sobjectId',
      label: 'Objeto',
      type: 'input',
      hidden: true,
    },
  },
  data: {
    name: '',
    label: '',
    sobjectId: '',
  }
}

export default function changeState(
  state = initialState,
  action: FieldAction
): iFieldState {
  switch(action.type) {
    case types.FIELD_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.FIELD_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.field,
        requestPerform: true
      };
    case types.FIELD_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.field,
        requestPerform: true,
        loadingData: false
      };
    case types.FIELD_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.FIELD_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
