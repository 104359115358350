import { action } from 'typesafe-actions';

import { iSobject } from '../../../interfaces/objects/iSobject.interface';
import * as types from './types';


export function sobjectGet(props: {
  history: any;
  id: string;
}) {
  return action(types.SOBJECT_GET, {
    props
  });
}

export function sobjectGetSuccess(props: {
  sobject: iSobject,
}) {
  return action(types.SOBJECT_GET_SUCCESS, {
    props
  });
}

export function sobjectGetFailure() {
  return action(types.SOBJECT_GET_FAILURE);
}

// ====================================================

export function sobjectRequest(props: {
  history: any;
  sobject: iSobject;
}) {
  return action(types.SOBJECT_REQUEST, {
    props
  });
}

export function sobjectCreatedSuccess(props: {
  sobject: iSobject,
}) {
  return action(types.SOBJECT_CREATED_SUCCESS, {
    props,
  });
}

export function sobjectUpdatedSuccess(props: {
  sobject: iSobject,
}) {
  return action(types.SOBJECT_UPDATED_SUCCESS, {
    props,
  });
}

export function sobjectRequestFailure() {
  return action(types.SOBJECT_REQUEST_FAILURE);
}

// =======================================================

export function sobjectResetState() {
  return action(types.SOBJECT_RESET_STATE);
}
