import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iTask } from '../../../interfaces/objects/iTask.interface';
import * as actions from './actions';

export type TaskAction = ActionType<typeof actions>;

export interface ITaskCard {
  id: string;
  content: string;
  labels: never[] | string[];
  user?: undefined | string;
}
export interface ITaskList {
  title: string;
  creatable: boolean;
  cards: ITaskCard[];
  done?: undefined | boolean;
}
export interface ITaskState {
  loadingData: boolean;
  requestPerform: boolean;
  error: boolean;
  lists: ITaskList[];
  fields: { [key: string]: IFieldSchema };
  data: iTask;
}

export const TASK_GET = '@task/TASK_GET';
export const TASK_GET_SUCCESS = '@task/TASK_GET_SUCCESS';
export const TASK_GET_FAILURE = '@task/TASK_GET_FAILURE';

export const TASK_REQUEST = '@task/TASK_REQUEST';
export const TASK_CREATED_SUCCESS = '@task/TASK_CREATED_SUCCESS';
export const TASK_UPDATED_SUCCESS = '@task/TASK_UPDATED_SUCCESS';
export const TASK_REQUEST_FAILURE = '@task/TASK_REQUEST_FAILURE';

export const CHANGE_POSITION = '@task/CHANGE_POSITION';

export const TASK_RESET_STATE = '@task/TASK_RESET_STATE';
