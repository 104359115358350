import { action } from 'typesafe-actions';
import { iFinancialInstitution } from '../../../interfaces/objects/iFinancialInstitution.interface';
import * as types from './types';

export function financialInstitutionRequest(data: {
  history: any;
  financialInstitution: iFinancialInstitution,
}) {
  return action(types.FINANCIAL_INSTITUTION_REQUEST, {
    data
  });
}

export function financialInstitutionCreatedSuccess(data: {
  financialInstitution: iFinancialInstitution,
}) {
  return action(types.FINANCIAL_INSTITUTION_CREATED_SUCCESS, {
    data,
  });
}

export function financialInstitutionUpdatedSuccess(data: {
  financialInstitution: iFinancialInstitution,
}) {
  return action(types.FINANCIAL_INSTITUTION_UPDATED_SUCCESS, {
    data,
  });
}

export function financialInstitutionRequestFailure() {
  return action(types.FINANCIAL_INSTITUTION_REQUEST_FAILURE);
}

export function financialInstitutionResetState() {
  return action(types.FINANCIAL_INSTITUTION_RESET_STATE);
}
