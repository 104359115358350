import { action } from 'typesafe-actions';
import { iSoldVehicles } from '../../../interfaces/iSoldVehicles';
import * as types from './types';

export function financeGet(props: {
  searchFilters?: any;
}) {
  return action(types.FINANCE_GET, {
    props
  });
}

export function financeGetSuccess(props: {
  soldVehicles: iSoldVehicles[];
}) {
  return action(types.FINANCE_GET_SUCCESS, {
    props,
  });
}

export function financeGetFailure() {
  return action(types.FINANCE_GET_FAILURE);
}

export function financeResetState() {
  return action(types.FINANCE_RESET_STATE);
}
