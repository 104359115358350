import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iUser } from '../../../interfaces/objects/iUser.interface';
import * as actions from './actions';

export type UserAction = ActionType<typeof actions>;

export interface iUserState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iUser;
}

export const CHANGE_STATUS = '@user/CHANGE_STATUS';

export const USER_REQUEST = '@user/USER_REQUEST';
export const USER_CREATED_SUCCESS = '@user/USER_CREATED_SUCCESS';
export const USER_UPDATED_SUCCESS = '@user/USER_UPDATED_SUCCESS';
export const USER_REQUEST_FAILURE = '@user/USER_REQUEST_FAILURE';
export const USER_RESET_STATE = '@user/USER_RESET_STATE';
