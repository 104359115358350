import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* ratingRequest({ payload }: ActionType<typeof actions.ratingRequest>) {
  // console.log('@ratingRequest/payload:: ', payload.data)
  const { history } = payload.data;
  try {
    if(payload.data.rating.id) {
      const response = yield call(api.patch, `/api/ratings/${payload.data.rating.id}`, payload.data.rating);
      yield put(actions.ratingUpdatedSuccess({ rating: response.data.payload }));
      toast.success('Cadastro atualizado!');
      console.log('ratingUpdatedSuccess:: ', response.data.payload);
    } else {
      const response = yield call(api.post, '/api/ratings/', payload.data.rating);
      yield put(actions.ratingCreatedSuccess({ rating: response.data.payload }));
      toast.success('Cadastro realizado!');
      console.log('ratingCreatedSuccess:: ', response.data.payload);
    }
    history.push('/ratings/')
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.ratingRequestFailure());
  }

}


export default all([
  takeLatest(types.RATING_REQUEST, ratingRequest),
])
