import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iRating } from '../../../interfaces/objects/iRating.interface';
import * as actions from './actions';

export type RatingAction = ActionType<typeof actions>;
export interface iRatingState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iRating;
}

export const RATING_REQUEST = '@rating/RATING_REQUEST';
export const RATING_CREATED_SUCCESS = '@rating/RATING_CREATED_SUCCESS';
export const RATING_UPDATED_SUCCESS = '@rating/RATING_UPDATED_SUCCESS';
export const RATING_REQUEST_FAILURE = '@rating/RATING_REQUEST_FAILURE';
export const RATING_RESET_STATE = '@rating/RATING_RESET_STATE';

export const RATING_UPDATE_CLIENT = '@rating/RATING_UPDATE_CLIENT';
export const RATING_UPDATE_CLIENT_SUCCESS = '@rating/RATING_UPDATE_CLIENT_SUCCESS';
export const RATING_SET_CREATED_CLIENT = '@rating/RATING_SET_CREATED_CLIENT';

export const RATING_UPDATE_EVALUATOR = '@rating/RATING_UPDATE_EVALUATOR';
export const RATING_UPDATE_EVALUATOR_SUCCESS = '@rating/RATING_UPDATE_EVALUATOR_SUCCESS';
export const RATING_SET_CREATED_EVALUATOR = '@rating/RATING_SET_CREATED_EVALUATOR';
