import { DealProductAction, iDealProductState } from './types';
import * as types from './types';


const initialState: iDealProductState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    netPrice: {
      name: 'netPrice',
      label: 'Valor negociado',
      type: 'currency',
    },
    dealId: {
      name: 'dealId',
      type: 'input',
      hidden: true
    },
    vehicleId: {
      name: 'vehicleId',
      label: 'Veículo',
      object: 'vehicles',
      type: 'lookup',
      editionOnly: true,
    },
  },
  data: {
    netPrice: 0,
    dealId: '',
    productId: '',
    vehicleId: '',
  }
}

export default function changeState(
  state = initialState,
  action: DealProductAction
): iDealProductState {
  switch(action.type) {
    case types.DEAL_PRODUCT_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.dealProduct,
        }
      };
    }
    case types.DEAL_PRODUCT_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.DEAL_PRODUCT_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.dealProduct,
        loadingData: false
      };
    case types.DEAL_PRODUCT_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.DEAL_PRODUCT_REQUEST:
      return {
        ...state,
      };
    case types.DEAL_PRODUCT_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.dealProduct,
        requestPerform: true
      };
    case types.DEAL_PRODUCT_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.dealProduct,
        requestPerform: true
      };
    case types.DEAL_PRODUCT_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.DEAL_PRODUCT_SET_PRODUCT: {
      return {
        ...state,
        // requestPerform: true
      };
    }
    case types.DEAL_PRODUCT_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
