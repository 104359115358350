import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type ThemeAction = ActionType<typeof actions>;

export interface ITheme {
  title: string;

  colors: {
    primary: string;
    secondary: string;
    background: string;

    white: string;
    black: string;

    info: string;
    success: string;
    warning: string;
    danger: string;
  }
}

export const TOGGLE_THEME = '@theme/TOGGLE_THEME';


export const PERSIST_REHYDRATE = '@persist/REHYDRATE';
