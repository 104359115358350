import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function MyRoute ({ component: Component, isClosed, prevPath, ...rest }) {
  const isSignedIn = useSelector((state) => state.auth.isSignedIn);

  if (isClosed && !isSignedIn) {
    return <Redirect to={{ pathname: '/login', state: { prevPath } }} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} component={Component} />;
}

MyRoute.defaultProps = {
  isClosed: false,
};

MyRoute.propTypes = {
  component: PropTypes.oneOfType([ PropTypes.element, PropTypes.func ]).isRequired,
  isClosed: PropTypes.bool,
};
