import { combineReducers } from 'redux';

import { StoreState } from '../createStore';

import auth from './auth/reducer';
import theme from './theme/reducer';
import tasks from './tasks/reducer';
import users from './users/reducer';
import accounts from './accounts/reducer';
import addresses from './addresses/reducer';
import commissions from './commissions/reducer';
import contacts from './contacts/reducer';
import deals from './deals/reducer';
import dealProducts from './deal-products/reducer';
import dealVehicles from './deal-vehicles/reducer';
import expenses from './expenses/reducer';
import fieldPermissions from './field-permissions/reducer';
import fields from './fields/reducer';
import financialInstitutions from './financial-institutions/reducer';
import financings from './financings/reducer';
import finances from './finances/reducer';
import leads from './leads/reducer';
import loading from './loading/reducer';
import objectPermissions from './object-permissions/reducer';
import objectSettings from './object-settings/reducer';
import origins from './origins/reducer';
import pendencies from './pendencies/reducer';
import posMachines from './pos-machines/reducer';
import products from './products/reducer';
import ratings from './ratings/reducer';
import roles from './roles/reducer';
import sobjects from './sobjects/reducer';
import stores from './stores/reducer';
import transactions from './transactions/reducer';
import vehicleLocations from './vehicle-locations/reducer';
import vehicleModels from './vehicle-models/reducer';
import vehicles from './vehicles/reducer';

const rootReducer = combineReducers<StoreState>({
  accounts,
  addresses,
  auth,
  commissions,
  contacts,
  deals,
  dealProducts,
  dealVehicles,
  expenses,
  fieldPermissions,
  fields,
  financialInstitutions,
  financings,
  finances,
  leads,
  loading,
  objectPermissions,
  objectSettings,
  origins,
  pendencies,
  posMachines,
  products,
  ratings,
  roles,
  stores,
  users,
  vehicleLocations,
  vehicleModels,
  vehicles,
  sobjects,
  theme,
  tasks,
  transactions,
});

export default (state: any, action: any) => {
  // console.log('state::: ', state);
  return rootReducer(state, action)
}
