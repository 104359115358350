import React, { FC, ReactNode } from 'react';
import { Portal } from '@material-ui/core';

import { MaxWidthProps } from '../../../styles/Breakpoints';
import { StyledModal, StyledOverlay } from './styled';
import { ModalLabel } from '../ModalLabel';
import { Button, Icon } from '../../../components/slds';

export interface iMyModal extends MaxWidthProps {
  id?: string;
  name?: string;
  title?: string;
  children?: ReactNode;
  btn?: ReactNode;
  hideButton?: boolean;
  hideCloseButton?: boolean;
  hideTitle?: boolean;
  showModal?: boolean;
  onClickOpen?: () => void;
  onClickClose?: () => void;
}

const MyModal: FC<iMyModal> = ({
  name,
  title,
  children,
  btn,
  hideButton = false,
  hideCloseButton = false,
  hideTitle = false,
  showModal,
  size,
  onClickOpen,
  onClickClose
}) => {

  return (
    <div className={`openModal modal ${name}`}>
      {(!hideButton) && (
        <div className={`LinkModal`} onClick={onClickOpen}>
          {btn ?? <ModalLabel size={32} />}
        </div>
      )}
      {(showModal) && (
        <Portal
          container={document.getElementById('portal-root')}
        >
          <StyledOverlay>
            <StyledModal size={size} className='slds-card p-[2px]'>
              {hideCloseButton ? null : (
                <Button
                  assistiveText={{ icon: 'Fechar' }}
                  iconCategory="utility"
                  iconName="close"
                  iconSize="large"
                  inverse
                  className="slds-button_icon slds-modal__close mt-[-30px] mr-[-8px] right-0 float-end"
                  onClick={onClickClose}
                  title="Fechar"
                  variant="icon"
                />
              )}
              {hideTitle || !title ? null : (
                <div className='flex text-white h-[48px] w-full'>
                  <span className='text-lg'>
                    {title}
                  </span>
                </div>
              )}
              <div className='flex flex-col align-middle justify-center w-full'>
                {children}
              </div>
            </StyledModal>
          </StyledOverlay>
        </Portal>
      )}
    </div>
  );

}

export default MyModal;
