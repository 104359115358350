import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iLead } from '../../../interfaces/objects/iLead.interface';
import * as actions from './actions';

export type LeadAction = ActionType<typeof actions>;

export interface iLeadState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iLead;
}

export const LEAD_SET_VALUES_BY_FORMIK = '@lead/LEAD_SET_VALUES_BY_FORMIK';

export const LEAD_GET = '@lead/LEAD_GET';
export const LEAD_GET_SUCCESS = '@lead/LEAD_GET_SUCCESS';
export const LEAD_GET_FAILURE = '@lead/LEAD_GET_FAILURE';

export const LEAD_REQUEST = '@lead/LEAD_REQUEST';
export const LEAD_CREATED_SUCCESS = '@lead/LEAD_CREATED_SUCCESS';
export const LEAD_UPDATED_SUCCESS = '@lead/LEAD_UPDATED_SUCCESS';
export const LEAD_REQUEST_FAILURE = '@lead/LEAD_REQUEST_FAILURE';

export const LEAD_SET_DESIRED_VEHICLE = '@lead/LEAD_SET_DESIRED_VEHICLE';
export const LEAD_REMOVE_DESIRED_VEHICLE = '@lead/LEAD_REMOVE_DESIRED_VEHICLE';

export const LEAD_SET_OFFERED_VEHICLE = '@lead/LEAD_SET_OFFERED_VEHICLE';
export const LEAD_REMOVE_OFFERED_VEHICLE = '@lead/LEAD_REMOVE_OFFERED_VEHICLE';

export const LEAD_RESET_STATE = '@lead/LEAD_RESET_STATE';
