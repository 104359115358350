import { ObjectPermissionAction, iObjectPermissionState } from './types';
import * as types from './types';


const initialState: iObjectPermissionState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    isVisible: {
      name: 'isVisible',
      label: 'Visível',
      type: 'checkbox',
    },
    isUpdateable: {
      name: 'isUpdateable',
      label: 'Editável',
      type: 'checkbox',
    },
    isDeletable: {
      name: 'isDeletable',
      label: 'Deletável',
      type: 'checkbox',
    },
    roleId: {
      name: 'roleId',
      label: 'Papel',
      type: 'input',
      hidden: true,
    },
    sobjectId: {
      name: 'sobjectId',
      label: 'Objeto',
      type: 'input',
      hidden: true,
    },
  },
  data: {
    isVisible: false,
    isUpdateable: false,
    isDeletable: false,
    sobjectId: '',
    roleId: '',
  }
}

export default function changeState(
  state = initialState,
  action: ObjectPermissionAction
): iObjectPermissionState {
  switch(action.type) {
    case types.OBJECT_PERMISSION_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.OBJECT_PERMISSION_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.objectPermission,
        loadingData: false
      };
    case types.OBJECT_PERMISSION_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.OBJECT_PERMISSION_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.OBJECT_PERMISSION_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.objectPermission,
        requestPerform: true,
        loadingData: false
      };
    case types.OBJECT_PERMISSION_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.objectPermission,
        requestPerform: true,
        loadingData: false
      };
    case types.OBJECT_PERMISSION_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.OBJECT_PERMISSION_CHANGE_REQUEST_PERFORM:
      return {
        ...state,
        requestPerform: false
      };
    case types.OBJECT_PERMISSION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
