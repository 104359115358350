import { ProductAction, iProductState } from './types';

import * as types from './types';


const initialState: iProductState = {
  requestPerform: false,
  fields: {
    value: {
      name: 'value',
      label: 'Valor',
      type: 'currency',
    },
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea',
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'PRODUCT',
          label: 'Produto',
          value: 'PRODUCT'
        },
        {
          key: 'SERVICE',
          label: 'Serviço',
          value: 'SERVICE'
        }
      ],
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          key: 'OPEN',
          label: 'Em aberto',
          value: 'OPEN'
        },
        {
          key: 'IN_PROGRESS',
          label: 'Em progresso',
          value: 'IN_PROGRESS'
        },
        {
          key: 'DONE',
          label: 'Concluído',
          value: 'DONE'
        }
      ],
    },
    // categoryId: {
    //   name: 'categoryId',
    //   label: 'Categoria',
    //   type: 'input',
    // },
    // subCategoryId: {
    //   name: 'subCategoryId',
    //   label: 'Subcategoria',
    //   type: 'input',
    // },
    catererId: {
      name: 'catererId',
      label: 'Fornecedor',
      object: 'contacts',
      type: 'lookup'
    },
  },
  data: {
    value: 0,
    title: "",
    description: "",
    type: 'SERVICE',
    status: 'OPEN',
    // categoryId: "",
    // subCategoryId: "",
  }
}

export default function changeState(
  state = initialState,
  action: ProductAction
): iProductState {
  switch(action.type) {
    case types.PRODUCT_REQUEST:
      return {
        ...state,
      };
    case types.PRODUCT_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.product,
        requestPerform: true
      };
    case types.PRODUCT_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.product,
        requestPerform: true
      };
    case types.PRODUCT_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.PRODUCT_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
