import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';

export function* financingRequest({ payload }: ActionType<typeof actions.financingRequest>) {
  // console.log('@financingRequest/payload:: ', payload)
  try {
    if(payload.props.financing.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/financings/${payload.props.financing.id}`, payload.props.financing);
      yield put(actions.financingUpdatedSuccess({ financing: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/financings/', payload.props.financing);
      yield put(actions.financingCreatedSuccess({ financing: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.financingRequestFailure());
  }

}

export default all([
  takeLatest(types.FINANCING_REQUEST, financingRequest),
])
