import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* commissionRequest({ payload }: ActionType<typeof actions.commissionRequest>) {
  // console.log('@commissionRequest/payload:: ', payload.data)
  try {
    if(payload.data.commission.id) {
      const response = yield call(api.patch, `/api/commissions/${payload.data.commission.id}`, payload.data.commission);
      yield put(actions.commissionUpdatedSuccess({ commission: { ...response.data.payload } }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/commissions/', payload.data.commission);
      yield put(actions.commissionCreatedSuccess({ commission: { ...response.data.payload } }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.commissionRequestFailure());
  }

}


export default all([
  takeLatest(types.COMMISSION_REQUEST, commissionRequest),
])
