import { action } from 'typesafe-actions';
import * as types from './types';

export function addAsyncItem(props: {
  item: string;
}) {
  return action(types.LOADING_ADD_ASYNC_ITEM, {
    props
  });
}
export function addItem(props: {
  item: string;
}) {
  return action(types.LOADING_ADD_ITEM, {
    props
  });
}

export function removeAsyncItem(props: {
  item: string;
}) {
  return action(types.LOADING_REMOVE_ASYNC_ITEM, {
    props
  });
}

export function removeItem(props: {
  item: string;
}) {
  return action(types.LOADING_REMOVE_ITEM, {
    props
  });
}
