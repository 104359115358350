import { action } from 'typesafe-actions';

import { iRole } from '../../../interfaces/objects/iRole.interface';
import * as types from './types';


export function roleGet(props: {
  history: any;
  id: string;
}) {
  return action(types.ROLE_GET, {
    props
  });
}

export function roleGetSuccess(props: {
  role: iRole,
}) {
  return action(types.ROLE_GET_SUCCESS, {
    props
  });
}

export function roleGetFailure() {
  return action(types.ROLE_GET_FAILURE);
}

// ====================================================



export function roleRequest(props: {
  history: any;
  role: iRole;
}) {
  return action(types.ROLE_REQUEST, {
    props
  });
}

export function roleCreatedSuccess(props: {
  role: iRole,
}) {
  return action(types.ROLE_CREATED_SUCCESS, {
    props,
  });
}

export function roleUpdatedSuccess(props: {
  role: iRole,
}) {
  return action(types.ROLE_UPDATED_SUCCESS, {
    props,
  });
}

export function roleRequestFailure() {
  return action(types.ROLE_REQUEST_FAILURE);
}

// =======================================================


export function roleResetState() {
  return action(types.ROLE_RESET_STATE);
}
