import { action } from 'typesafe-actions';

import { iTask } from '../../../interfaces/objects/iTask.interface';
import * as types from './types';


export function taskGet(props: {
  history: any;
  id: string;
}) {
  return action(types.TASK_GET, {
    props
  });
}

export function taskGetSuccess(props: {
  task: iTask,
}) {
  return action(types.TASK_GET_SUCCESS, {
    props
  });
}

export function taskGetFailure() {
  return action(types.TASK_GET_FAILURE);
}

// ====================================================

export function taskRequest(props: {
  history: any;
  task: iTask,
}) {
  return action(types.TASK_REQUEST, {
    props
  });
}

export function taskCreatedSuccess(props: {
  task: iTask,
}) {
  return action(types.TASK_CREATED_SUCCESS, {
    props
  });
}

export function taskUpdatedSuccess(props: {
  task: iTask,
}) {
  return action(types.TASK_UPDATED_SUCCESS, {
    props
  });
}

export function taskRequestFailure() {
  return action(types.TASK_REQUEST_FAILURE);
}

// ====================================================


export function changePosition(
  tasks: types.ITaskState,
  source: { index: number; droppableId: string },
  destination: { index: number; droppableId: string }
) {
  return action(types.CHANGE_POSITION, {
    tasks,
    source,
    destination
  });
}

export function taskResetState() {
  return action(types.TASK_RESET_STATE);
}

