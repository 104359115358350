import { action } from 'typesafe-actions';
import { iLead } from '../../../interfaces/objects/iLead.interface';
import { iVehicleModel } from '../../../interfaces/objects/iVehicleModel.interface';
import * as types from './types';


export function leadSetValuesByFormik(props: {
  lead: iLead;
}) {
  return action(types.LEAD_SET_VALUES_BY_FORMIK, {
    props
  });
}


export function leadGet(props: {
  history: any;
  id: string;
}) {
  return action(types.LEAD_GET, {
    props
  });
}

export function leadGetSuccess(props: {
  lead: iLead;
}) {
  return action(types.LEAD_GET_SUCCESS, {
    props
  });
}

export function leadGetFailure() {
  return action(types.LEAD_GET_FAILURE);
}

// ---------------------------------------------------

export function leadRequest(props: {
  history: any;
  lead: iLead;
}) {
  return action(types.LEAD_REQUEST, {
    props
  });
}

export function leadCreatedSuccess(props: {
  lead: iLead;
}) {
  return action(types.LEAD_CREATED_SUCCESS, {
    props,
  });
}

export function leadUpdatedSuccess(props: {
  lead: iLead;
}) {
  return action(types.LEAD_UPDATED_SUCCESS, {
    props,
  });
}

export function leadRequestFailure() {
  return action(types.LEAD_REQUEST_FAILURE);
}

// ---------------------------------------------------

export function leadSetDesiredVehicle(props: {
  lead: iLead;
  desiredVehicle: iVehicleModel;
  desiredVehicles: iVehicleModel[];
}) {
  return action(types.LEAD_SET_DESIRED_VEHICLE, {
    props
  });
}

export function leadRemoveDesiredVehicle(props: {
  lead: iLead;
  id: string;
  desiredVehicles: iVehicleModel[];
}) {
  return action(types.LEAD_REMOVE_DESIRED_VEHICLE, {
    props
  });
}

// ---------------------------------------------------

export function leadSetOfferedVehicle(props: {
  lead: iLead;
  offeredVehicle: iVehicleModel;
  offeredVehicles: iVehicleModel[];
}) {
  return action(types.LEAD_SET_OFFERED_VEHICLE, {
    props
  });
}

export function leadRemoveOfferedVehicle(props: {
  lead: iLead;
  id: string;
  offeredVehicles: iVehicleModel[];
}) {
  return action(types.LEAD_REMOVE_OFFERED_VEHICLE, {
    props
  });
}

// ---------------------------------------------------

export function leadResetState() {
  return action(types.LEAD_RESET_STATE);
}
