import { createStore, applyMiddleware, Middleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import persistedReducers from './modules/reduxPersist';
import { AuthAction, iAuthState } from './modules/auth/types';
import { ThemeAction, ITheme } from './modules/theme/types';
import { ITaskState } from './modules/tasks/types';
import { iObjectSettingsState } from './modules/object-settings/types';
import { iUserState } from './modules/users/types';
import { iAccountState } from './modules/accounts/types';
import { iAddresseState } from './modules/addresses/types';
import { iCommissionsState } from './modules/commissions/types';
import { iContactState } from './modules/contacts/types';
import { iDealState } from './modules/deals/types';
import { iDealProductState } from './modules/deal-products/types';
import { iDealVehicleState } from './modules/deal-vehicles/types';
import { iExpenseState } from './modules/expenses/types';
import { iFieldPermissionState } from './modules/field-permissions/types';
import { iFieldState } from './modules/fields/types';
import { iFinancialInstitutionState } from './modules/financial-institutions/types';
import { iFinancingState } from './modules/financings/types';
import { iFinanceState } from './modules/finances/types';
import { iLeadState } from './modules/leads/types';
import { iLoadingState } from './modules/loading/types';
import { iObjectPermissionState } from './modules/object-permissions/types';
import { iOriginState } from './modules/origins/types';
import { iPendencyState } from './modules/pendencies/types';
import { iPosMachineState } from './modules/pos-machines/types';
import { iProductState } from './modules/products/types';
import { iRatingState } from './modules/ratings/types';
import { iRoleState } from './modules/roles/types';
import { iSobjectState } from './modules/sobjects/types';
import { iStoreState } from './modules/stores/types';
import { iTransactionState } from './modules/transactions/types';
import { iVehicleLocationState } from './modules/vehicle-locations/types';
import { iVehicleModelState } from './modules/vehicle-models/types';
import { iVehicleState } from './modules/vehicles/types';

export interface StoreState {
  accounts: iAccountState;
  addresses: iAddresseState;
  auth: iAuthState;
  commissions: iCommissionsState;
  contacts: iContactState;
  deals: iDealState;
  dealProducts: iDealProductState;
  dealVehicles: iDealVehicleState;
  expenses: iExpenseState;
  fieldPermissions: iFieldPermissionState;
  fields: iFieldState;
  financialInstitutions: iFinancialInstitutionState;
  financings: iFinancingState;
  finances: iFinanceState;
  leads: iLeadState;
  loading: iLoadingState;
  objectPermissions: iObjectPermissionState;
  origins: iOriginState;
  pendencies: iPendencyState;
  posMachines: iPosMachineState;
  products: iProductState;
  ratings: iRatingState;
  roles: iRoleState;
  sobjects: iSobjectState;
  stores: iStoreState;
  users: iUserState;
  vehicleLocations: iVehicleLocationState;
  vehicleModels: iVehicleModelState;
  vehicles: iVehicleState;
  theme: ITheme;
  tasks: ITaskState;
  transactions: iTransactionState;
  objectSettings: iObjectSettingsState;
}

export type StoreAction = AuthAction | ThemeAction;

export default (
  reducers: Reducer<StoreState, StoreAction>,
  middlewares: Middleware[]
) => {
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

  return createStore(persistedReducers(reducers), enhancer);
};
