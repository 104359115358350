import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iSoldVehicles } from '../../../interfaces/iSoldVehicles';
import * as actions from './actions';

export type FinanceAction = ActionType<typeof actions>;

export interface iFinanceState {
  soldVehicles: iSoldVehicles[];
}

export const FINANCE_GET = '@finance/FINANCE_GET';
export const FINANCE_GET_SUCCESS = '@finance/FINANCE_GET_SUCCESS';
export const FINANCE_GET_FAILURE = '@finance/FINANCE_GET_FAILURE';

export const FINANCE_RESET_STATE = '@finance/FINANCE_RESET_STATE';
