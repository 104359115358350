import { StoreAction, iStoreState } from './types';

import * as types from './types';


const initialState: iStoreState = {
  requestPerform: false,
  fields: {
    nickname: {
      name: 'nickname',
      label: 'Apelido',
      disabled: false,
      type: 'input',
      maxlength: 255
    },
    contactId: {
      name: 'contactId',
      label: 'Contato',
      type: 'lookup',
      object: 'contacts',
    },
  },
  data: {
    nickname: '',
    // contactId: 1,
  }
}

export default function changeState(
  state = initialState,
  action: StoreAction
): iStoreState {
  switch(action.type) {
    case types.STORE_REQUEST:
      return {
        ...state,
      };
    case types.STORE_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.store,
        requestPerform: true
      };
    case types.STORE_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.store,
        requestPerform: true
      };
    case types.STORE_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.STORE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
