import { SobjectAction, iSobjectState } from './types';

import * as types from './types';


const initialState: iSobjectState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    label: {
      name: 'label',
      label: 'Label',
      type: 'input',
    },
    // isStandard: {
    //   name: 'isStandard',
    //   label: 'isStandard',
    //   type: 'checkbox',
    // },
  },
  data: {
    name: '',
    label: '',
    // isStandard: false,
  }
}

export default function changeState(
  state = initialState,
  action: SobjectAction
): iSobjectState {
  switch(action.type) {
    case types.SOBJECT_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.SOBJECT_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.sobject,
        loadingData: false
      };
    case types.SOBJECT_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.SOBJECT_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.SOBJECT_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.sobject,
        requestPerform: true
      };
    case types.SOBJECT_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.sobject,
        requestPerform: true,
        loadingData: false
      };
    case types.SOBJECT_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.SOBJECT_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
