import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* accountRequest({ payload }: ActionType<typeof actions.accountRequest>) {
  // console.log('@accountRequest/payload:: ', payload)
  try {
    if(payload.data.account.id) {
      const response = yield call(api.patch, `/api/accounts/${payload.data.account.id}`, payload.data.account);
      yield put(actions.accountUpdatedSuccess({ account: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/accounts/', payload.data.account);
      yield put(actions.accountCreatedSuccess({ account: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Erro:: ', err)
    yield put(actions.accountRequestFailure());
  }

}

export default all([
  takeLatest(types.ACCOUNT_REQUEST, accountRequest),
])
