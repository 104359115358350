import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iSobject } from '../../../interfaces/objects/iSobject.interface';

import * as actions from './actions';

export type SobjectAction = ActionType<typeof actions>;

export interface iSobjectState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iSobject;
}

export const SOBJECT_GET = '@sobject/SOBJECT_GET';
export const SOBJECT_GET_SUCCESS = '@sobject/SOBJECT_GET_SUCCESS';
export const SOBJECT_GET_FAILURE = '@sobject/SOBJECT_GET_FAILURE';


export const SOBJECT_REQUEST = '@sobject/SOBJECT_REQUEST';
export const SOBJECT_CREATED_SUCCESS = '@sobject/SOBJECT_CREATED_SUCCESS';
export const SOBJECT_UPDATED_SUCCESS = '@sobject/SOBJECT_UPDATED_SUCCESS';
export const SOBJECT_REQUEST_FAILURE = '@sobject/SOBJECT_REQUEST_FAILURE';
export const SOBJECT_RESET_STATE = '@sobject/SOBJECT_RESET_STATE';
