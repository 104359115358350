import { action } from 'typesafe-actions';
import { iCommission } from '../../../interfaces/objects/iCommission.interface';
import * as types from './types';

export function commissionRequest(data: {
  history: any;
  commission: iCommission;
}) {
  return action(types.COMMISSION_REQUEST, {
    data
  });
}

export function commissionCreatedSuccess(data: {
  commission: iCommission,
}) {
  return action(types.COMMISSION_CREATED_SUCCESS, {
    data,
  });
}

export function commissionUpdatedSuccess(data: {
  commission: iCommission,
}) {
  return action(types.COMMISSION_UPDATED_SUCCESS, {
    data,
  });
}

export function commissionRequestFailure() {
  return action(types.COMMISSION_REQUEST_FAILURE);
}

export function commissionResetState() {
  return action(types.COMMISSION_RESET_STATE);
}
