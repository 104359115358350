import React, { Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import MyRoute from './MyRoute';
import { Loading } from '../components/atoms/Loading';

const Accounts = React.lazy(() => import('../pages/Accounts'));
const Addresses = React.lazy(() => import('../pages/Addresses'));
const Commissions = React.lazy(() => import('../pages/Commissions'));
const Contacts = React.lazy(() => import('../pages/Contacts'));
const Deals = React.lazy(() => import('../pages/Deals'));
const Deal = React.lazy(() => import('../pages/Deal/index'));
const Expenses = React.lazy(() => import('../pages/Expenses'));
const FinancialInstitutions = React.lazy(() => import('../pages/FinancialInstitutions'));
const Financings = React.lazy(() => import('../pages/Financings'));
const Finances = React.lazy(() => import('../pages/Finances'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword/ForgotPassword'));
const Leads = React.lazy(() => import('../pages/Leads'));
const ObjectSettings = React.lazy(() => import('../pages/ObjectSettings'));
const Origins = React.lazy(() => import('../pages/Origins'));
const Page404 = React.lazy(() => import('../pages/Page404'));
const PasswordRecovery = React.lazy(() => import('../pages/PasswordRecovery/PasswordRecovery'));
const Pendencies = React.lazy(() => import('../pages/Pendencies'));
const PosMachines = React.lazy(() => import('../pages/PosMachines'));
const Products = React.lazy(() => import('../pages/Products'));
const Rating = React.lazy(() => import('../pages/Rating'));
const Ratings = React.lazy(() => import('../pages/Ratings'));
const Role = React.lazy(() => import('../pages/Role'));
const Roles = React.lazy(() => import('../pages/Roles'));
const Settings = React.lazy(() => import('../pages/Settings'));
const UserSettings = React.lazy(() => import('../pages/Settings/UserSettings'));
const RoleSettings = React.lazy(() => import('../pages/Settings/RoleSettings'));
const Sobjects = React.lazy(() => import('../pages/Sobjects'));
const Store = React.lazy(() => import('../pages/Store'));
const Stores = React.lazy(() => import('../pages/Stores'));
const SignIn = React.lazy(() => import('../pages/SignIn'));
const TasksBoard = React.lazy(() => import('../pages/TasksBoard'));
const Task = React.lazy(() => import('../pages/Task'));
const Tasks = React.lazy(() => import('../pages/Tasks'));
const Transactions = React.lazy(() => import('../pages/Transactions'));
const VehicleLocations = React.lazy(() => import('../pages/VehicleLocations'));
const VehicleModels = React.lazy(() => import('../pages/VehicleModels'));
const Vehicles = React.lazy(() => import('../pages/Vehicles'));
const Home = React.lazy(() => import('../pages/Home'));
const RoleDetailSettings = React.lazy(() => import('../pages/Settings/RoleDetailSettings'));

export default function Routes() {
  const location = useLocation()
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <MyRoute prevPath={undefined} path="/login/" component={SignIn} isClosed={false} />
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/" component={Home} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/accounts/" component={Accounts} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/addresses/" component={Addresses} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/commissions/" component={Commissions} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/contacts/" component={Contacts} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/deal/:id?" component={Deal} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/deals/" component={Deals} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/expenses/:id?" component={Expenses} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/financialInstitutions/:id?" component={FinancialInstitutions} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/financings/:id?" component={Financings} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/finances/" component={Finances} />
        <MyRoute prevPath={location.pathname} isClosed={false} path="/forgot-password" component={ForgotPassword} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/leads/:id?" component={Leads} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/objectsettings/:id?" component={ObjectSettings} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/origins/:id?" component={Origins} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/pendencies/:id?" component={Pendencies} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/pos-machines/:id?" component={PosMachines} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/products/:id?" component={Products} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/rating/:id?" component={Rating} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/ratings/:id?" component={Ratings} />
        <MyRoute prevPath={location.pathname} isClosed={false} path="/password-recovery/:recoveryToken" component={PasswordRecovery} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/role/:id?" component={Role} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/roles/:id?" component={Roles} />
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/settings/" component={Settings} />
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/settings/roles" component={RoleSettings} />
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/settings/roles/:id" component={RoleDetailSettings} />
        {/* <MyRoute prevPath={location.pathname} isClosed={true} exact path="/settings/roles/:id/object-permissions" component={ObjectsPermissionSettings} /> */}
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/settings/users" component={UserSettings} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/sobjects/:id?" component={Sobjects} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/store/:id?" component={Store} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/stores/:id?" component={Stores} />
        <MyRoute prevPath={location.pathname} isClosed={true} exact path="/tasks/board/:id?" component={TasksBoard} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/task/:id?" component={Task} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/tasks/:id?" component={Tasks} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/transactions/:id?" component={Transactions} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/vehicle-locations/:id?" component={VehicleLocations} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/vehicle-models/:id?" component={VehicleModels} />
        <MyRoute prevPath={location.pathname} isClosed={true} path="/vehicles/:id?" component={Vehicles} />
        <MyRoute prevPath={location.pathname} isClosed={false} path="*" component={Page404} />
      </Switch>
    </Suspense>
  );
}
