interface iMenuItems {
  title: string;
  link: string;
  permission: string;
}
export const menuItems: iMenuItems[] = [
  { title: 'Inicío', link: '/', permission: 'public' },
  { title: 'Avaliações', link: '/ratings', permission: 'public' },
  // { title: 'Contas', link: '/accounts/', permission: 'public' },
  // { title: 'Comissões', link: '/commissions', permission: 'public' },
  { title: 'Contatos', link: '/contacts', permission: 'public' },
  { title: 'Despesas', link: '/expenses', permission: 'public' },
  // { title: 'Endereços', link: '/addresses', permission: 'public' },
  // { title: 'Financeiras', link: '/financialInstitutions', permission: 'public' },
  { title: 'Finanças', link: '/finances', permission: 'public' },
  // { title: 'Financiamentos', link: '/financings', permission: 'public' },
  { title: 'Leads', link: '/leads', permission: 'public' },
  // { title: 'Máquinas POS', link: 'pos-machines/', permission: 'public' },
  { title: 'Negociações', link: '/deals', permission: 'public' },
  // {title:  'ObjectSettings', link: '/objectsettings', permission: 'public'},
  // { title: 'Origens', link: '/origins', permission: 'public' },
  { title: 'Pendências', link: '/pendencies', permission: 'public' },
  // { title: 'Produtos', link: '/products', permission: 'public' },
  // {title:  'Stores', link: '/stores', permission: 'public'},
  { title: 'Tarefas', link: '/tasks', permission: 'public' },
  // { title: 'Transações', link: '/transactions', permission: 'public' },
  // {title:  'Users', link: '/users', permission: 'public'},
  { title: 'Veículos', link: '/vehicles', permission: 'public' },
  // { title: 'Localizações', link: '/vehicle-locations', permission: 'public' }
];
