import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import * as actions from './actions';
import * as types from './types';
import { api } from '../../../services/api/axios';

export function* field() {
  try {
    const response = yield call(api.post, '/api/object-settings/signin');
    toast.success('Você fez login');

    yield put(actions.fieldSuccess(response.data.payload));
  } catch(err) {
    const error = get(err, 'response.data.error', []);
    const statusCode = get(err, 'response.data.statusCode', 0);
    const messages = get(err, 'response.data.message', []);
    // console.log(`Erro[${statusCode}]:: ${error} -`, messages);
    toast.error(messages);

    yield put(actions.fieldFailure());
  }
}

export default all([
  takeLatest(types.FIELD_REQUEST, field),
])
