import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { iExpense } from '../../../interfaces/objects/iExpense.interface';
import { api } from '../../../services/api/axios';
import { mergeDeepArrays } from '../../../utils/objectUtils';
import * as actions from './actions';
import * as types from './types';


export function* vehicleGet({ payload }: ActionType<typeof actions.vehicleGet>) {
  // console.log('@vehicleGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/vehicles/${payload.props.id}`);
    console.log('vehicleGet > response.data.payload:: ', response.data.payload);
    yield put(actions.vehicleGetSuccess({ vehicle: response.data.payload }));
    if(response.data.payload.expenses?.length > 0) {
      yield put(actions.vehicleSetDebitValue({ expenses: response.data.payload.expenses }));
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.vehicleGetFailure());
  }
}


export function* vehicleRequest({ payload }: ActionType<typeof actions.vehicleRequest>) {
  console.log('sagas > vehicleRequest > payload:: ', payload);
  try {
    if(payload.props.vehicle.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/vehicles/${payload.props.vehicle.id}`, payload.props.vehicle);
      yield put(actions.vehicleUpdatedSuccess({ vehicle: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/vehicles/', payload.props.vehicle);
      yield put(actions.vehicleCreatedSuccess({ vehicle: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.vehicleRequestFailure());
  }
}

export function* vehicleSetExpense({ payload }: ActionType<typeof actions.vehicleSetExpense>) {
  // console.log('@vehicleSetExpense/payload:: ', payload);
  try {
    const expenses: iExpense[] = mergeDeepArrays([...payload.props.expenses], [payload.props.expense]);
    yield put(actions.vehicleSetExpenseSuccess({ expenses }));
    yield put(actions.vehicleSetDebitValue({ expenses }));
  } catch(err) {
    console.log('Falha ao atualizar as despesas::', err);
    toast.error('Falha ao atualizar as despesas');
  }
}

export function* vehicleSetDebitValue({ payload }: ActionType<typeof actions.vehicleSetDebitValue>) {
  console.log('@vehicleSetDebitValue/payload:: ', payload);
  try {
    let debitValue: number = 0;
    payload.props.expenses.forEach((expense: iExpense) => {
      try {
        debitValue += (Number(expense.value) / 100);
      } catch {
        throw new Error('Valor inválido! Valor da despesa deve ser número!');
      }
    })
    yield put(actions.vehicleSetDebitValueSuccess({ debitValue }));
  } catch(err) {
    console.log('Falha ao atualizar as despesas::', err);
    toast.error('Falha ao atualizar as despesas');
  }
}

export function* vehicleSetPurchaseValue({ payload }: ActionType<typeof actions.vehicleSetPurchaseValue>) {
  console.log('@vehicleSetPurchaseValue/payload:: ', payload);
  try {
    const { debitValue = 0, purchaseValue = 0 } = payload.props.vehicle;
    yield put(actions.vehicleSetValuesByFormik({ vehicle: { ...payload.props.vehicle, costValue: (purchaseValue - debitValue) } }));
  } catch(err) {
    console.log('Falha ao atualizar as despesas::', err);
    toast.error('Falha ao atualizar as despesas');
  }
}

export default all([
  takeLatest(types.VEHICLE_GET, vehicleGet),
  takeLatest(types.VEHICLE_REQUEST, vehicleRequest),
  takeLatest(types.VEHICLE_SET_EXPENSE, vehicleSetExpense),
  takeLatest(types.VEHICLE_SET_DEBIT_VALUE, vehicleSetDebitValue),
  takeLatest(types.VEHICLE_SET_PURCHASE_VALUE, vehicleSetPurchaseValue),
])
