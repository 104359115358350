import React, { FC } from 'react'
import { useSelector } from 'react-redux';

import { GlobalStyle } from './GlobalStyles';
import { StoreState } from '../store/createStore';

const GlobalStylesProvider: FC = () => {
  const THEME = useSelector((state: StoreState) => state.theme);
  return (
    <GlobalStyle theme={THEME} />
  );
}

export default GlobalStylesProvider;
