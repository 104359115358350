import { FinanceAction, iFinanceState } from './types';
import * as types from './types';

const initialState: iFinanceState = {
  soldVehicles: []
}

export default function changeState(
  state = initialState,
  action: FinanceAction
): iFinanceState {
  switch(action.type) {
    case types.FINANCE_GET:
      return {
        ...state,
      };
    case types.FINANCE_GET_SUCCESS:
      return {
        ...state,
        soldVehicles: action.payload.props.soldVehicles,
      };
    case types.FINANCE_GET_FAILURE:
      return {
        ...state,
      };
    case types.FINANCE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
