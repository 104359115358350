import { action } from 'typesafe-actions';
import { iAddress } from '../../../interfaces/objects/iAddress.interface';
import { iContact } from '../../../interfaces/objects/iContact.interface';
import * as types from './types';


export function contactSetValuesByFormik(props: {
  contact: iContact;
}) {
  return action(types.CONTACT_SET_VALUES_BY_FORMIK, {
    props
  });
}

export function contactGet(props: {
  history: any;
  id: string;
}) {
  return action(types.CONTACT_GET, {
    props
  });
}

export function contactGetSuccess(props: {
  contact: iContact,
}) {
  return action(types.CONTACT_GET_SUCCESS, {
    props
  });
}


export function contactGetFailure() {
  return action(types.CONTACT_GET_FAILURE);
}

// ---------------------------------------------------

export function contactHandleEditingFormValues() {
  return action(types.CONTACT_HANDLE_EDITING_FORM_VALUE);
}

// ---------------------------------------------------

export function contactHandleMounted() {
  return action(types.CONTACT_HANDLE_MOUNTED);
}

// ---------------------------------------------------

export function contactRequest(data: {
  contact: iContact,
  address?: iAddress
}) {
  return action(types.CONTACT_REQUEST, {
    data
  });
}

export function contactCreatedSuccess(data: {
  contact: iContact,
}) {
  return action(types.CONTACT_CREATED_SUCCESS, {
    data,
  });
}

export function contactUpdatedSuccess(data: {
  contact: iContact,
}) {
  return action(types.CONTACT_UPDATED_SUCCESS, {
    data,
  });
}

export function contactRequestFailure() {
  return action(types.CONTACT_REQUEST_FAILURE);
}

// ---------------------------------------------------
export function contactResetState() {
  return action(types.CONTACT_RESET_STATE);
}
