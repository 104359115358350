import { PendencyAction, iPendencyState } from './types';

import * as types from './types';


const initialState: iPendencyState = {
  requestPerform: false,
  fields: {
    deadline: {
      name: 'deadline',
      label: 'Vencimento',
      type: 'date'
    },
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea'
    },
    categoryId: {
      name: 'categoryId',
      label: 'Categoria',
      type: 'input',
    },
    subCategoryId: {
      name: 'subCategoryId',
      label: 'Subcategoria',
      type: 'input',
    },
    vehicleId: {
      name: 'vehicleId',
      placeholder: 'Veículo',
      object: 'vehicles',
      type: 'lookup',
      hideModal: true,
    },
  },
  data: {
    deadline: new Date(),
    title: '',
    description: '',
    categoryId: '',
    subCategoryId: '',
    vehicleId: '',
  }
}

export default function changeState(
  state = initialState,
  action: PendencyAction
): iPendencyState {
  switch(action.type) {
    case types.PENDENCY_REQUEST:
      return {
        ...state,
      };
    case types.PENDENCY_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.pendency,
        requestPerform: true
      };
    case types.PENDENCY_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.pendency,
        requestPerform: true
      };
    case types.PENDENCY_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.PENDENCY_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
