import { UserAction, iUserState } from './types';

import * as types from './types';


const initialState: iUserState = {
  requestPerform: false,
  fields: {
    username: {
      name: 'username',
      label: 'Usuário',
      type: 'input',
    },
    password: {
      name: 'password',
      label: 'Senha',
      type: 'password',
      placeholder: 'Senha',
    },
    email: {
      name: 'email',
      label: 'E-mail',
      type: 'input',
    },
    phone: {
      name: 'phone',
      label: 'Telefone',
      type: 'input',
    },
    status: {
      name: 'status',
      label: 'Ativo',
      type: 'checkbox',
    },
  },
  data: {
    username: '',
    password: '',
    email: '',
    phone: '',
    status: true,
  }
}

export default function changeState(
  state = initialState,
  action: UserAction
): iUserState {
  switch(action.type) {
    case types.CHANGE_STATUS:
      return {
        ...state,
      };
    case types.USER_REQUEST:
      return {
        ...state,
      };
    case types.USER_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.user,
        requestPerform: true
      };
    case types.USER_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.user,
        requestPerform: true
      };
    case types.USER_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.USER_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
