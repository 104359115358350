import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* originGet({ payload }: ActionType<typeof actions.originGet>) {
  // console.log('@originGet/payload:: ', payload)
  try {
    const response: any = yield call(api.get, `/api/origins/${payload.id}`);
    yield put(actions.originGetSuccess(response.data.payload));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.originGetFailure());
  }
}


export function* originRequest({ payload }: ActionType<typeof actions.originRequest>) {
  // console.log('@originRequest/payload:: ', payload)
  try {
    if(payload.id) {
      const response = yield call(api.patch, `/api/origins/${payload.id}`, payload);
      yield put(actions.originUpdatedSuccess({ ...response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/origins/', payload);
      yield put(actions.originCreatedSuccess({ ...response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.originRequestFailure());
  }

}

export default all([
  takeLatest(types.ORIGIN_GET, originGet),
  takeLatest(types.ORIGIN_REQUEST, originRequest),
])
