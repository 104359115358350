import { AddresseAction, iAddresseState } from './types';
import * as types from './types';


const initialState: iAddresseState = {
  loadingData: false,
  requestPerform: false,
  fields: {
    // postalCode: {
    //   name: 'postalCode',
    //   label: 'CEP',
    //   disabled: false,
    //   type: 'input',
    //   maxlength: 255
    // },
    street: {
      name: 'street',
      label: 'Rua',
      type: 'input'
    },
    addressComplement: {
      name: 'addressComplement',
      label: 'Complemento',
      type: 'input'
    },
    city: {
      name: 'city',
      label: 'Cidade',
      type: 'input'
    },
    provincy: {
      name: 'provincy',
      label: 'Bairro',
      type: 'input'
    },
    state: {
      name: 'state',
      label: 'Estado',
      type: 'input'
    },
    country: {
      name: 'country',
      label: 'País',
      type: 'input'
    },
    latitude: {
      name: 'latitude',
      label: 'Latitude',
      type: 'input'
    },
    longitude: {
      name: 'longitude',
      label: 'Longitude',
      type: 'input'
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'RESIDENTIAL',
          label: 'Residencial',
          value: 'RESIDENTIAL'
        },
        {
          key: 'BUSINESS',
          label: 'Comercial',
          value: 'BUSINESS'
        },
        {
          key: 'TAX',
          label: 'Fiscal',
          value: 'TAX'
        }
      ],
    },
  },
  data: {
    postalCode: '',
    street: '',
    addressComplement: '',
    city: '',
    provincy: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    type: 'RESIDENTIAL',
  }
}

export default function changeState(
  state = initialState,
  action: AddresseAction
): iAddresseState {
  switch(action.type) {
    case types.ADDRESS_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.ADDRESS_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.address,
        loadingData: false
      };
    case types.ADDRESS_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.ADDRESS_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.ADDRESS_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.address,
        requestPerform: true,
        loadingData: false
      };
    case types.ADDRESS_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.address,
        requestPerform: true,
        loadingData: false
      };
    case types.ADDRESS_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.ADDRESS_POSTAL_CODE_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.ADDRESS_POSTAL_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.props.address,
        loadingData: false
      };
    case types.ADDRESS_POSTAL_CODE_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.ADDRESS_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
