import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iTransaction } from '../../../interfaces/objects/iTransaction.interface';
import * as actions from './actions';

export type TransactionAction = ActionType<typeof actions>;

export interface iTransactionState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iTransaction;
}

export const TRANSACTION_SET_VALUES_BY_FORMIK = '@transaction/TRANSACTION_SET_VALUES_BY_FORMIK';

export const TRANSACTION_GET = '@transaction/TRANSACTION_GET';
export const TRANSACTION_GET_SUCCESS = '@transaction/TRANSACTION_GET_SUCCESS';
export const TRANSACTION_GET_FAILURE = '@transaction/TRANSACTION_GET_FAILURE';


export const TRANSACTION_REQUEST = '@transaction/TRANSACTION_REQUEST';
export const TRANSACTION_CREATED_SUCCESS = '@transaction/TRANSACTION_CREATED_SUCCESS';
export const TRANSACTION_UPDATED_SUCCESS = '@transaction/TRANSACTION_UPDATED_SUCCESS';
export const TRANSACTION_REQUEST_FAILURE = '@transaction/TRANSACTION_REQUEST_FAILURE';
export const TRANSACTION_RESET_STATE = '@transaction/TRANSACTION_RESET_STATE';

export const TRANSACTION_SET_CONDITION = '@transaction/TRANSACTION_SET_CONDITION';
