import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iExpense } from '../../../interfaces/objects/iExpense.interface';
import * as actions from './actions';

export type ExpenseAction = ActionType<typeof actions>;

export interface iExpenseState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iExpense;
}

export const EXPENSE_REQUEST = '@expense/EXPENSE_REQUEST';
export const EXPENSE_CREATED_SUCCESS = '@expense/EXPENSE_CREATED_SUCCESS';
export const EXPENSE_UPDATED_SUCCESS = '@expense/EXPENSE_UPDATED_SUCCESS';
export const EXPENSE_REQUEST_FAILURE = '@expense/EXPENSE_REQUEST_FAILURE';
export const EXPENSE_RESET_STATE = '@expense/EXPENSE_RESET_STATE';
