import { action } from 'typesafe-actions';

import { iUser } from '../../../interfaces/objects/iUser.interface';
import * as types from './types';

export function changeStatus(props: {
  users: types.iUserState,
  source: { index: number; droppableId: string },
  destination: { index: number; droppableId: string }
}) {
  return action(types.CHANGE_STATUS, {
    props
  });
}

export function userRequest(props: {
  history: any;
  user: iUser,
}) {
  return action(types.USER_REQUEST, {
    props
  });
}

export function userCreatedSuccess(props: {
  user: iUser,
}) {
  return action(types.USER_CREATED_SUCCESS, {
    props,
  });
}

export function userUpdatedSuccess(props: {
  user: iUser,
}) {
  return action(types.USER_UPDATED_SUCCESS, {
    props,
  });
}

export function userRequestFailure() {
  return action(types.USER_REQUEST_FAILURE);
}

export function userResetState() {
  return action(types.USER_RESET_STATE);
}

