import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iCommission } from '../../../interfaces/objects/iCommission.interface';
import * as actions from './actions';

export type CommissionAction = ActionType<typeof actions>;

export interface iCommissionsState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iCommission;
}


export const COMMISSION_REQUEST = '@commission/COMMISSION_REQUEST';
export const COMMISSION_CREATED_SUCCESS = '@commission/COMMISSION_CREATED_SUCCESS';
export const COMMISSION_UPDATED_SUCCESS = '@commission/COMMISSION_UPDATED_SUCCESS';
export const COMMISSION_REQUEST_FAILURE = '@commission/COMMISSION_REQUEST_FAILURE';
export const COMMISSION_RESET_STATE = '@commission/COMMISSION_RESET_STATE';
