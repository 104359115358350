import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iPosMachine } from '../../../interfaces/objects/iPosMachine.interface';
import * as actions from './actions';

export type PosMachineAction = ActionType<typeof actions>;

export interface iPosMachineState {
  posMachineCreated: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iPosMachine;
}

export const POS_MACHINE_GET = '@posMachine/POS_MACHINE_GET';
export const POS_MACHINE_GET_SUCCESS = '@posMachine/POS_MACHINE_GET_SUCCESS';
export const POS_MACHINE_GET_FAILURE = '@posMachine/POS_MACHINE_GET_FAILURE';

export const POS_MACHINE_REQUEST = '@posMachine/POS_MACHINE_REQUEST';
export const POS_MACHINE_CREATED_SUCCESS = '@posMachine/POS_MACHINE_CREATED_SUCCESS';
export const POS_MACHINE_UPDATED_SUCCESS = '@posMachine/POS_MACHINE_UPDATED_SUCCESS';
export const POS_MACHINE_REQUEST_FAILURE = '@posMachine/POS_MACHINE_REQUEST_FAILURE';
export const POS_MACHINE_RESET_STATE = '@posMachine/POS_MACHINE_RESET_STATE';
