import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import * as types from './types';


export function* toggleTheme({ payload }: ActionType<typeof actions.toggleTheme>) {
  // console.log('@Saga/ToggleTheme:: ', payload.title)
}

export default all([
  takeLatest(types.TOGGLE_THEME, toggleTheme),
])
