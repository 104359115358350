import { action } from 'typesafe-actions';

import { iVehicleModel } from '../../../interfaces/objects/iVehicleModel.interface';
import * as types from './types';


export function vehicleModelSetValuesByFormik(props: {
  vehicleModel: iVehicleModel;
}) {
  return action(types.VEHICLE_MODEL_SET_VALUES_BY_FORMIK, {
    props
  });
}


export function vehicleModelGet(props: {
  history: any;
  id: string;
}) {
  return action(types.VEHICLE_MODEL_GET, {
    props
  });
}

export function vehicleModelGetSuccess(props: {
  vehicleModel: iVehicleModel;
}) {
  return action(types.VEHICLE_MODEL_GET_SUCCESS, {
    props
  });
}

export function vehicleModelGetFailure() {
  return action(types.VEHICLE_MODEL_GET_FAILURE);
}

// ====================================================

export function vehicleModelRequest(props: {
  history: any;
  vehicleModel: iVehicleModel;
}) {
  return action(types.VEHICLE_MODEL_REQUEST, {
    props
  });
}

export function vehicleModelCreatedSuccess(props: {
  vehicleModel: iVehicleModel,
}) {
  return action(types.VEHICLE_MODEL_CREATED_SUCCESS, {
    props,
  });
}

export function vehicleModelUpdatedSuccess(props: {
  vehicleModel: iVehicleModel,
}) {
  return action(types.VEHICLE_MODEL_UPDATED_SUCCESS, {
    props,
  });
}

export function vehicleModelRequestFailure() {
  return action(types.VEHICLE_MODEL_REQUEST_FAILURE);
}

// ====================================================

// export function vehicleModelSetFipeBrand(props: {
//   fipeBrand: iFipeBrand,
// }) {
//   return action(types.VEHICLE_MODEL_SET_FIPE_BRAND, {
//     props,
//   });
// }

// export function vehicleModelSetFipeModel(props: {
//   fipeModel: iFipeModel,
// }) {
//   return action(types.VEHICLE_MODEL_SET_FIPE_MODEL, {
//     props,
//   });
// }

// export function vehicleModelSetFipeYear(props: {
//   fipeYear: iFipeYear,
// }) {
//   return action(types.VEHICLE_MODEL_SET_FIPE_YEAR, {
//     props,
//   });
// }

// ====================================================

export function vehicleModelHandleMounted() {
  return action(types.VEHICLE_MODEL_HANDLE_MOUNTED);
}

// ====================================================

export function vehicleModelResetState() {
  return action(types.VEHICLE_MODEL_RESET_STATE);
}
