import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iFinancing } from '../../../interfaces/objects/iFinancing.interface';
import * as actions from './actions';

export type FinancingAction = ActionType<typeof actions>;

export interface iFinancingState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iFinancing;
}

export const FINANCING_REQUEST = '@financing/FINANCING_REQUEST';
export const FINANCING_CREATED_SUCCESS = '@financing/FINANCING_CREATED_SUCCESS';
export const FINANCING_UPDATED_SUCCESS = '@financing/FINANCING_UPDATED_SUCCESS';
export const FINANCING_REQUEST_FAILURE = '@financing/FINANCING_REQUEST_FAILURE';
export const FINANCING_RESET_STATE = '@financing/FINANCING_RESET_STATE';
