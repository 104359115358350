import { VehicleAction, iVehicleState } from './types';

import * as types from './types';

const initialState: iVehicleState = {
  mounted: false,
  loadingData: false,
  requestPerform: false,
  fields: {
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
      disabled: true,
      placeholder: 'Campo automático'
    },
    vehicleLicensePlate: {
      name: 'vehicleLicensePlate',
      label: 'Placa do veículo',
      type: 'masked',
      mask: 'licensePlate'
    },
    manufactureYear: {
      name: 'Ano de manufactureYear',
      label: 'Ano de fabricação',
      type: 'input'
    },
    // modelYear: {
    //   name: 'Ano modelYear',
    //   label: 'Ano modelo',
    //   type: 'input'
    // },
    fipeCode: {
      name: 'fipeCode',
      label: 'Código Fipe',
      type: 'input',
      disabled: true
    },
    fuel: {
      name: 'fuel',
      label: 'Combustível',
      type: 'input'
    },
    renavam: {
      name: 'renavam',
      label: 'RENAVAM',
      type: 'input'
    },
    chassi: {
      name: 'chassi',
      label: 'CHASSI',
      type: 'input'
    },
    motor: {
      name: 'motor',
      label: 'Motor',
      type: 'input'
    },
    color: {
      name: 'color',
      label: 'Cor',
      type: 'input'
    },
    purchaseDate: {
      name: 'purchaseDate',
      label: 'Data de compra',
      type: 'date'
    },
    saleDate: {
      name: 'saleDate',
      label: 'Data de venda',
      type: 'date'
    },
    warrantyDays: {
      name: 'warrantyDays',
      label: 'Garantia(dias)',
      type: 'input'
    },
    adaptedPCD: {
      name: 'adaptedPCD',
      label: 'Adaptado para PCD',
      type: 'checkbox'
    },
    alienated: {
      name: 'alienated',
      label: 'Alienado',
      type: 'checkbox'
    },
    armored: {
      name: 'armored',
      label: 'Blindado',
      type: 'checkbox'
    },
    paidIPVA: {
      name: 'paidIPVA',
      label: 'IPVA pago',
      type: 'checkbox'
    },
    licensed: {
      name: 'licensed',
      label: 'Licenciado',
      type: 'checkbox'
    },
    onlyOwner: {
      name: 'onlyOwner',
      label: 'Único dono',
      type: 'checkbox'
    },
    revisedInDealership: {
      name: 'revisedInDealership',
      label: 'Revisado na concessionária',
      type: 'checkbox'
    },
    km: {
      name: 'km',
      label: 'KM',
      type: 'input'
    },
    doorsNumber: {
      name: 'doorsNumber',
      label: 'Nº de portas',
      type: 'input'
    },
    documentLocation: {
      name: 'documentLocation',
      label: 'Local do doc.',
      type: 'input'
    },
    envelopeNumber: {
      name: 'envelopeNumber',
      label: 'Nº do envelope',
      type: 'input'
    },
    purchaseValue: {
      name: 'purchaseValue',
      label: 'Valor de compra',
      type: 'currency'
    },
    costValue: {
      name: 'costValue',
      label: 'Valor líquido',
      type: 'currency',
      disabled: true,
      placeholder: 'Campo automático'
    },
    debitValue: {
      name: 'debitValue',
      label: 'Valor de débito',
      type: 'currency',
      disabled: true,
      placeholder: 'Campo automático'
    },
    fipePrice: {
      name: 'fipePrice',
      label: 'Valor da FIPE',
      type: 'currency',
      disabled: true,
      placeholder: 'Campo automático'
    },
    minValue: {
      name: 'minValue',
      label: 'Valor mínimo',
      type: 'currency'
    },
    saleValue: {
      name: 'saleValue',
      label: 'Valor de venda',
      type: 'currency'
    },
    partnersValue: {
      name: 'partnersValue',
      label: 'Valor para parceiros',
      type: 'currency'
    },
    appraisalObservation: {
      name: 'appraisalObservation',
      label: 'Obs. da vistoria',
      type: 'input'
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          key: 'PURCHASING',
          label: 'Comprando',
          value: 'PURCHASING'
        },
        {
          key: 'PENDING',
          label: 'Pendente',
          value: 'PENDING'
        },
        {
          key: 'IN_STOCK',
          label: 'Em estoque',
          value: 'IN_STOCK'
        },
        {
          key: 'IN_DEAL',
          label: 'Em negociação',
          value: 'IN_DEAL'
        },
        {
          key: 'SOLD',
          label: 'Vendido',
          value: 'SOLD'
        }
      ],
    },
    condition: {
      name: 'condition',
      label: 'Condição',
      type: 'select',
      options: [
        {
          key: 'USED',
          label: 'Usado',
          value: 'USED'
        },
        {
          key: 'NEW',
          label: 'Novo',
          value: 'NEW'
        }
      ],
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'MOTORCYCLE',
          label: 'Motocicleta',
          value: 'MOTORCYCLE'
        },
        {
          key: 'CAR',
          label: 'Carro',
          value: 'CAR'
        },
        {
          key: 'TRUCK',
          label: 'Caminhão',
          value: 'TRUCK'
        }
      ],
    },
    appraisalStatus: {
      name: 'appraisalStatus',
      label: 'Status da vistoria',
      type: 'select',
      options: [
        {
          key: 'UNREALIZED',
          label: 'Não realizada',
          value: 'UNREALIZED'
        },
        {
          key: 'APPROVED',
          label: 'Aprovada',
          value: 'APPROVED'
        },
        {
          key: 'APPROVED_WITH_OBSERVATION',
          label: 'Aprovada com observação',
          value: 'APPROVED_WITH_OBSERVATION'
        },
        {
          key: 'DISAPPROVED',
          label: 'Reprovada',
          value: 'DISAPPROVED'
        }
      ],
    },
    stockType: {
      name: 'stockType',
      label: 'Tipo de estoque',
      type: 'select',
      options: [
        {
          key: 'PHYSICAL',
          label: 'Físico',
          value: 'PHYSICAL'
        },
        {
          key: 'VIRTUAL',
          label: 'Virtual',
          value: 'VIRTUAL'
        },
        {
          key: 'CONSIGNED',
          label: 'Consignado',
          value: 'CONSIGNED'
        }
      ],
    },
    dispatcherId: {
      name: 'dispatcherId',
      label: 'Despachante',
      object: 'contacts',
      type: 'lookup',
    },
    investorId: {
      name: 'investorId',
      label: 'Investidor',
      object: 'contacts',
      type: 'lookup',
    },
    vehicleOwnerId: {
      name: 'vehicleOwnerId',
      label: 'Proprietário',
      object: 'contacts',
      type: 'lookup',
    },
    vehicleLocationId: {
      name: 'vehicleLocationId',
      label: 'Localização do veículo',
      object: 'vehicleLocations',
      type: 'lookup',
    },
    storeId: {
      name: 'storeId',
      label: 'Loja',
      type: 'input'
    },
    // vehicleModelId: {
    //   name: 'vehicleModelId',
    //   label: 'Modelo',
    //   object: 'vehicleModels',
    //   type: 'lookup',
    //   hideModal: true
    // },
    purchaseDealId: {
      name: 'purchaseDealId',
      label: 'Negociação de compra',
      type: 'input'
    },
    saleDealId: {
      name: 'saleDealId',
      label: 'Negociação de venda',
      type: 'input'
    },
    // createdAt: {
    //   name: 'createdAt',
    //   label: 'Criado',
    //   type: 'date',
    // },
    // updatedAt: {
    //   name: 'updatedAt',
    //   label: 'Modificado',
    //   type: 'date',
    // },
    // isDeleted: {
    //   name: 'isDeleted',
    //   label: 'Deletado',
    //   type: 'checkbox'
    // },
  },
  data: {
    title: '',
    vehicleLicensePlate: '',
    manufactureYear: 0,
    modelYear: 0,
    renavam: '',
    chassi: '',
    motor: '',
    color: '',
    // purchaseDate: '',
    // saleDate: '',
    warrantyDays: 0,
    adaptedPCD: false,
    alienated: false,
    armored: false,
    paidIPVA: false,
    licensed: false,
    onlyOwner: false,
    revisedInDealership: false,
    km: 0,
    doorsNumber: 0,
    documentLocation: '',
    envelopeNumber: '',
    purchaseValue: 0,
    costValue: 0,
    debitValue: 0,
    fipePrice: 0,
    minValue: 0,
    saleValue: 0,
    partnersValue: 0,
    appraisalObservation: '',
    status: 'PURCHASING',
    condition: 'USED',
    type: 'MOTORCYCLE',
    appraisalStatus: 'UNREALIZED',
    stockType: 'PHYSICAL',
    vehicleLocationId: '',
    storeId: '',
    vehicleModelId: '',
    purchaseDealId: '',
    saleDealId: '',
    expenses: [],
    dealProducts: [],
  }
}

export default function changeState(
  state = initialState,
  action: VehicleAction
): iVehicleState {
  switch(action.type) {
    case types.VEHICLE_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.vehicle,
        }
      };
    }
    case types.VEHICLE_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.VEHICLE_GET_SUCCESS: {
      const { costValue, debitValue, minValue, partnersValue, purchaseValue, saleValue, fipeBrandId, fipeModelId, fipeYearId, ...rest } = action.payload.props.vehicle;
      return {
        ...state,
        loadingData: false,
        mounted: true,
        data: {
          ...rest,
          costValue: costValue ? (costValue / 100) : 0,
          debitValue: debitValue ? (debitValue / 100) : 0,
          minValue: minValue ? (minValue / 100) : 0,
          partnersValue: partnersValue ? (partnersValue / 100) : 0,
          purchaseValue: purchaseValue ? (purchaseValue / 100) : 0,
          saleValue: saleValue ? (saleValue / 100) : 0,
          fipeBrandId: fipeBrandId ? 'fixLookup' + fipeBrandId : '',
          fipeModelId: fipeModelId ? 'fixLookup' + fipeModelId : '',
          fipeYearId: fipeYearId ? 'fixLookup' + fipeYearId : '',
        }
      };
    }
    case types.VEHICLE_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.VEHICLE_REQUEST:
      return {
        ...state,
      };
    case types.VEHICLE_CREATED_SUCCESS: {
      const { fipeBrandId, fipeModelId, fipeYearId, ...rest } = action.payload.props.vehicle;
      return {
        ...state,
        data: {
          ...rest,
          fipeBrandId: fipeBrandId ? 'fixLookup' + fipeBrandId : '',
          fipeModelId: fipeModelId ? 'fixLookup' + fipeModelId : '',
          fipeYearId: fipeYearId ? 'fixLookup' + fipeYearId : '',
        },
        requestPerform: true,
      };
    }
    case types.VEHICLE_UPDATED_SUCCESS: {
      const { fipeBrandId, fipeModelId, fipeYearId, ...rest } = action.payload.props.vehicle;
      return {
        ...state,
        data: {
          ...rest,
          fipeBrandId: fipeBrandId ? 'fixLookup' + fipeBrandId : '',
          fipeModelId: fipeModelId ? 'fixLookup' + fipeModelId : '',
          fipeYearId: fipeYearId ? 'fixLookup' + fipeYearId : '',
        },
        requestPerform: true,
      };
    }
    case types.VEHICLE_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.VEHICLE_SET_EXPENSE:
      return { ...state };

    case types.VEHICLE_SET_EXPENSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          expenses: action.payload.props.expenses
        }
      };
    case types.VEHICLE_SET_DEBIT_VALUE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          debitValue: action.payload.props.debitValue,
          costValue: (state.data.purchaseValue ? (state.data.purchaseValue - action.payload.props.debitValue) : action.payload.props.debitValue)
        }
      };
    case types.VEHICLE_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
