import styled from 'styled-components';
import { iGlobalStyleProps } from '../../../styles/GlobalStyles';

type Props = iGlobalStyleProps & {
  size: number;
}
export const StyledModalLabel = styled.div.attrs<Props>({
  className: 'StyledModalLabel',
})`
  display: flex;
  background-color: transparent;

  label {
    display: flex;
    ${(props: Props) => `${props.size ? 'font-size: ' + props.size + 'px' : ''}`};
    cursor: pointer;
    color: ${(props: iGlobalStyleProps) => props.theme.colors.primary};
    border-top: ${(props: Props) => `${props.size ? 3 : 1}px solid transparent`};
    border-bottom: ${(props: Props) => `${props.size ? 3 : 1}px solid transparent`};
    box-sizing: content-box;

  }
    
  & > {

    &:hover{
      border-bottom: ${(props: Props) => `${props.size ? 3 : 1}px solid ${props.theme.colors.primary}`};
      filter: brightness(75%);
    } 
    .setRelatedBtn{
      padding: 8px 0;
      font-weight: bolder;
      font-size: 24px;
    }

    .Mui-disabled{
      cursor: not-allowed;
      border-bottom: ${(props: Props) => `${props.size ? 3 : 1}px solid transparent`};
    }
  }
`;
