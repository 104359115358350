import Cookies from 'js-cookie';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as loadingActions from '../loading/actions';
import * as types from './types';
import { AxiosResponse } from 'axios';
import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { iStore } from '../../../interfaces/objects/iStore.interface';
import { iSettings } from '../../../interfaces/iSettings';

function base64DecodeUnicode(str: string) {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  let percentEncodedStr = atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join('');


  return decodeURIComponent(percentEncodedStr);
}

export function* signIn({ payload }: ActionType<typeof actions.signInRequest>) {
  yield put(loadingActions.addAsyncItem({item: 'signIn'}))
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/auth/signin', payload.props);
    const { accessToken, settings } = response.data.payload;
    const decodedSettings: iSettings = JSON.parse(base64DecodeUnicode(settings));
    console.log('decodedSettings:: ', decodedSettings);
    let token = accessToken.split('.');
    const decodedTokenPayload = JSON.parse(base64DecodeUnicode(token[1]));
    console.log('decodedTokenPayload:: ', decodedTokenPayload);

    Cookies.set('revenda-token', `Bearer ${accessToken}`, { expires: 0.5 });
    api.defaults.headers.Authorization = Cookies.get('revenda-token');

    let showStoresModal = false;

    const userStores: iStore[] = Object.values(decodedSettings.stores);
    if(userStores?.length >= 1) {
      if(userStores.length > 1) {
        showStoresModal = true
        toast.success('Você fez login, selecione uma loja!', { autoClose: 4000 });
      } else if(userStores.length === 1) {
        api.defaults.headers.Authorization = Cookies.get('revenda-token');
        console.log('userStores[0]', userStores[0])
        yield put(actions.authSelectStoreSucess({ store: userStores[0] }));
      }
      toast.success('Você fez login!');
    }
    yield put(loadingActions.removeAsyncItem({item: 'signIn'}))
    yield put(actions.signInSuccess({ accessToken, decodedTokenPayload, showStoresModal, userStores }));
    if(payload.props.prevPath && !showStoresModal) {
      payload.props.history.push(payload.props.prevPath);
    }
  } catch(err) {
    yield put(loadingActions.removeAsyncItem({item: 'signIn'}))
    yield put(actions.signInFailure());
  }
}

export function* authSelectStore({ payload }: ActionType<typeof actions.authSelectStore>) {
  yield put(actions.authSelectStoreSucess({ store: payload.props.store }));
  if(payload.props.prevPath) {
    payload.props.history.push(payload.props.prevPath);
  }
}

export function* authLogout({ payload }: ActionType<typeof actions.authLogout>) {
  payload.props.history.push('/login');
}

export default all([
  takeLatest(types.AUTH_SIGN_IN_REQUEST, signIn),
  takeLatest(types.AUTH_SELECT_STORE, authSelectStore),
  takeLatest(types.AUTH_LOGOUT, authLogout),
])
