import styled, { createGlobalStyle } from 'styled-components';
import { getMaxWidth, MaxWidthProps, breakAt, BreakpointSizes } from '../styles/Breakpoints';

import 'react-toastify/dist/ReactToastify.css';
import { ITheme } from '../store/modules/theme/types';
import background from '../assets/background.jpg';

export interface iGlobalStyleProps {
  theme: ITheme;
}

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  ::-webkit-scrollbar {
    max-width: 9px !important;
    max-height: 9px !important;
    background-color: transparent !important;
  }

  ::-webkit-scrollbar-track {
      background-color: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
      background-color: rgb(195, 195, 195) !important;
      border-radius: 3px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color: rgb(69, 26, 26) !important;
  }

  body {
    background: ${(props: iGlobalStyleProps) => props.theme.colors.background};
    color: ${(props: iGlobalStyleProps) => props.theme.colors.black};
  }

  html, body, #root {
    height: 100%;
  }

  button {
    cursor: pointer;
    background: ${(props: iGlobalStyleProps) => props.theme.colors.primary};
    border: none;
    color: ${(props: iGlobalStyleProps) => props.theme.colors.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 700;
    transition: all 300ms;
  }

  .success {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.success};
  }
  .warning {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.warning};
  }
  .danger {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.danger};
  }
  .primary {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.primary};
  }
  .secondary {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.secondary};
  }

  /* .LinkModal {
    color: ${(props: iGlobalStyleProps) => props.theme.colors.primary};
    display: flex;
    align-items: center;
    transition: font-weight;
    border-radius: 30%;
  }
  .LinkModal:hover {
    cursor: pointer;
    font-weight: 800;  
  } */

  .MuiStepLabel-iconContainer div {
    transition: box-shadow 300ms;
    border-radius: 100%;
  }
  .MuiStepLabel-iconContainer div:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    background: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
  }

  button:hover {
    filter: brightness(75%);
  }

  a {
    text-decoration: none;
    color: ${(props: iGlobalStyleProps) => props.theme.colors.primary};
  }

  .LinkModal:hover {
    /* text-decoration: underline; */
    cursor: pointer;
    filter: brightness(75%);
  }

  ul {
    list-style: none;
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--success {
    background: ${(props: iGlobalStyleProps) => props.theme.colors.success}
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--error {
    background: ${(props: iGlobalStyleProps) => props.theme.colors.danger}
  }
`;

export const Background = styled.div.attrs({
  className: "styled-background"
})`
  height: 100vh;
  width: 100vw;
  background: ${(props: iGlobalStyleProps) => props.theme.colors.background};
  overflow: hidden;
`;

export const StyledPage = styled.div.attrs({
  className: "styled-page flex flex-col h-full w-full"
})`
  background: ${(props: iGlobalStyleProps) => props.theme.colors.white};
  margin: 0 auto;
  padding-top: 90px;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
`;

interface ContainerProps extends iGlobalStyleProps, MaxWidthProps { };
export const Container = styled.div.attrs<ContainerProps>({
  className: "styled-container"
})`
  height: 100%;
  max-width: ${({ size }: ContainerProps) => `${getMaxWidth({ size })}px`};
  margin: 0 auto;
  `;

export const Content = styled.div.attrs({
  className: "styled-content"
})`
  padding: 8px;
  height: 100%;
  overflow: scroll;
  width: 100%;
  background: ${(props: ContainerProps) => props.theme.colors.white};

  ${breakAt(BreakpointSizes.md)} {
    padding: 16px;
  }

  ${breakAt(BreakpointSizes.lg)} {
    padding: 24px;
  }
`;

