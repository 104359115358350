import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { ActionType } from 'typesafe-actions';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { iDealProduct } from '../../../interfaces/objects/iDealProduct.interface';
import { iDealVehicle } from '../../../interfaces/objects/iDealVehicle.interface';
import { iFinancing } from '../../../interfaces/objects/iFinancing.interface';
import { iTransaction } from '../../../interfaces/objects/iTransaction.interface';
import { iVehicle } from '../../../interfaces/objects/iVehicle.interface';
import { api } from '../../../services/api/axios';
import { mergeDeepArrays } from '../../../utils/objectUtils';
import * as actions from './actions';
import * as types from './types';
import * as Images from '../../../services/firebase/images';
import { iDeal } from '../../../interfaces/objects/iDeal.interface';

export function* dealGet({ payload }: ActionType<typeof actions.dealGet>) {
  // console.log('@dealGet/payload:: ', payload);
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/deals/${payload.props.id}`);
    console.log('@dealGet > response :: ', response);
    yield put(actions.dealGetSuccess({ deal: response.data.payload }));
    if(response.data.payload.products?.length > 0) {
      yield put(actions.dealUpdateTotalProductsValue({ dealProducts: response.data.payload.products }));
    }
    if(response.data.payload.financings?.length > 0) {
      yield put(actions.dealUpdateTotalFinancingsValue({ financings: response.data.payload.financings }));
    }
    if(response.data.payload.transactions?.length > 0) {
      yield put(actions.dealUpdateTotalTransactionsValues({ transactions: response.data.payload.transactions }));
    }
    if(response.data.payload.purchaseVehicles?.length > 0) {
      yield put(actions.dealUpdateTotalPurchaseValue({ purchaseVehicles: response.data.payload.purchaseVehicles }));
    }
    if(response.data.payload.sellingVehicles?.length > 0) {
      yield put(actions.dealUpdateTotalSellingValue({ sellingVehicles: response.data.payload.sellingVehicles }));
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.dealGetFailure());
  }
}

export function* dealRequest({ payload }: ActionType<typeof actions.dealRequest>) {
  // console.log('@dealRequest/payload:: ', payload)
  try {
    if(payload.props.deal.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/deals/${payload.props.deal.id}`, payload.props.deal);
      yield put(actions.dealUpdatedSuccess({ deal: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/deals/', payload.props.deal);
      yield put(actions.dealCreatedSuccess({ deal: response.data.payload }));
      toast.success('Cadastro realizado!');
      payload.props.history.push(`/deal/${response.data.payload.id}`);
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.dealRequestFailure());
  }
}

export function* dealConcretize({ payload }: ActionType<typeof actions.dealConcretize>) {
  // console.log('@dealConcretize/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse<iDeal>> = yield call(api.patch, `/api/deals/concretize/${payload.props.deal.id}`, payload.props.deal);
    console.log('response:: ', response)
    if(response.data.payload) {
      const { concretionDate, purchaseVehicles, sellingVehicles, status, tenantId } = response.data.payload;
      if(concretionDate && status && tenantId) {
        yield put(actions.dealConcretizeSuccess({ concretionDate, status }));
        toast.success('Negociação concretizada!');
        purchaseVehicles.forEach(async (purchaseVehicle) => {
          await Images.excludeAll(`/vehicles/${purchaseVehicle.id}`, tenantId);
        });
        sellingVehicles.forEach(async (sellingVehicle) => {
          if(sellingVehicle.vehicleId) {
            await Images.excludeAll(`/vehicles/${sellingVehicle.vehicleId}`, tenantId);
          }
        });
      }
    }
  } catch {
    yield put(actions.dealConcretizeFailure());
  }
}

export function* dealRevert({ payload }: ActionType<typeof actions.dealRevert>) {
  console.log('@dealRevert/payload:: ', payload)

  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/deals/revert/${payload.props.id}`);
    console.log('response:: ', response)
    if(!response.data.payload.concretionDate) {
      yield put(actions.dealRevertSuccess());
      toast.success('Negociação revertida!');
    } else {
      console.log('concretionDate', response.data.payload.concretionDate)
      yield put(actions.dealRevertFailure());
      toast.error('Falha ao reverter negociação!')
    }
  } catch(err) {
    console.log(err)
    yield put(actions.dealRevertFailure());
    toast.error('Falha ao reverter negociação!')
  }
}

export function* dealUpdateTotalSellingValue({ payload }: ActionType<typeof actions.dealUpdateTotalSellingValue>) {
  // console.log('@dealUpdateTotalSellingValue/payload:: ', payload)
  try {
    let cont: number = 0;
    payload.props.sellingVehicles?.forEach((sellingVehicle: iDealVehicle) => {
      try {
        cont += Number(sellingVehicle.netPrice)
      } catch {
        throw new Error('Valor inválido! Valor do veículo de venda deve ser número!')
      }
    })
    yield put(actions.dealUpdateTotalSellingValueSuccess({ totalSellingValue: cont }));
    yield put(actions.dealUpdateTotalPaidValue());
    yield put(actions.dealUpdateTotalValue());
  } catch(err) {
    console.log('Falha ao atualizar o valor total de venda.', err);
    toast.error('Falha ao atualizar o valor total de venda.');
  }
}

export function* dealUpdateTotalPurchaseValue({ payload }: ActionType<typeof actions.dealUpdateTotalPurchaseValue>) {
  // console.log('@dealUpdateTotalPurchaseValue/payload:: ', payload)
  try {
    let cont: number = 0;
    payload.props.purchaseVehicles?.forEach((purchaseVehicle: iVehicle) => {
      try {
        cont += Number(purchaseVehicle.costValue)
      } catch {
        throw new Error('Valor inválido! Valor do veículo de compra deve ser número!')
      }
    })
    yield put(actions.dealUpdateTotalPurchaseValueSuccess({ totalPurchaseValue: cont }));
    yield put(actions.dealUpdateTotalReceivedValue());
    yield put(actions.dealUpdateTotalDifferenceValue());
    yield put(actions.dealUpdateTotalValue());
  } catch(err) {
    console.log('Falha ao atualizar o valor total de compra.', err);
    toast.error('Falha ao atualizar o valor total de compra.');
  }
}

export function* dealSetPurchaseVehicle({ payload }: ActionType<typeof actions.dealSetPurchaseVehicle>) {
  // console.log('@dealSetPurchaseVehicle/payload:: ', payload)
  try {
    const purchaseVehicles: iVehicle[] = mergeDeepArrays([...payload.props.purchaseVehicles], [payload.props.vehicle])
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, purchaseVehicles } }));
    yield put(actions.dealUpdateTotalPurchaseValue({ purchaseVehicles }));
  } catch(err) {
    console.log('Falha ao atualizar os veículos de compra::', err)
    toast.error('Falha ao atualizar os veículos de compra')
  }
}

export function* dealRemovePurchaseVehicle({ payload }: ActionType<typeof actions.dealRemovePurchaseVehicle>) {
  // console.log('@dealRemovePurchaseVehicle/payload:: ', payload)
  try {
    const { purchaseVehicles, id } = payload.props.deal;
    const updatedPurchaseVehicles = purchaseVehicles.filter(dealProduct => dealProduct.id !== id);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, purchaseVehicles: updatedPurchaseVehicles } }));
    yield put(actions.dealUpdateTotalPurchaseValue({ purchaseVehicles }));
    yield put(actions.dealUpdateTotalReceivedValue());
  } catch(err) {
    console.log('Falha ao atualizar os veículos de compra::', err)
    toast.error('Falha ao atualizar os veículos de compra')
  }
}

export function* dealSetSellingVehicle({ payload }: ActionType<typeof actions.dealSetSellingVehicle>) {
  // console.log('@dealSetSellingVehicle/payload:: ', payload)
  try {
    const sellingVehicles: iDealVehicle[] = mergeDeepArrays([...payload.props.sellingVehicles], [payload.props.dealVehicle])
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, sellingVehicles } }));
    yield put(actions.dealUpdateTotalSellingValue({ sellingVehicles }));
    yield put(actions.dealUpdateTotalPaidValue());
  } catch(err) {
    console.log('Falha ao atualizar os veículos de venda::', err)
    toast.error('Falha ao atualizar os veículos de venda')
  }
}

export function* dealRemoveSellingVehicle({ payload }: ActionType<typeof actions.dealRemoveSellingVehicle>) {
  // console.log('@dealRemoveSellingVehicle/payload:: ', payload)
  try {
    const { sellingVehicles, id } = payload.props.deal;
    const updatedSellingVehicles = sellingVehicles.filter(sellingVehicle => sellingVehicle.id !== id);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, sellingVehicles: updatedSellingVehicles } }));
    yield put(actions.dealUpdateTotalSellingValue({ sellingVehicles }));
    yield put(actions.dealUpdateTotalPaidValue());
  } catch(err) {
    console.log('Falha ao atualizar os veículos de venda::', err)
    toast.error('Falha ao atualizar os veículos de venda')
  }
}

export function* dealSetDealProduct({ payload }: ActionType<typeof actions.dealSetDealProduct>) {
  console.log('@dealSetDealProduct/payload:: ', payload)
  try {
    const dealProducts: iDealProduct[] = mergeDeepArrays([...payload.props.dealProducts], [payload.props.dealProduct])
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, dealProducts } }));
    yield put(actions.dealUpdateTotalProductsValue({ dealProducts }));
    yield put(actions.dealUpdateTotalPaidValue());
  } catch(err) {
    console.log('Falha ao atualizar os produtos::', err)
    toast.error('Falha ao atualizar os produtos')
  }
}

export function* dealRemoveDealProduct({ payload }: ActionType<typeof actions.dealRemoveDealProduct>) {
  // console.log('@dealRemoveDealProduct/payload:: ', payload)
  try {
    const { dealProducts, id } = payload.props.deal;
    const updatedDealproducts = dealProducts.filter(dealProduct => dealProduct.id !== id);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, dealProducts: updatedDealproducts } }));
    yield put(actions.dealUpdateTotalProductsValue({ dealProducts: updatedDealproducts }));
    yield put(actions.dealUpdateTotalPaidValue());
  } catch(err) {
    console.log('Falha ao atualizar os veículos de compra::', err)
    toast.error('Falha ao atualizar os veículos de compra')
  }
}

export function* dealSetFinancing({ payload }: ActionType<typeof actions.dealSetFinancing>) {
  // console.log('@dealSetFinancing/payload:: ', payload);
  try {
    const financings: iFinancing[] = mergeDeepArrays([...payload.props.financings], [payload.props.financing]);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, financings } }));
    yield put(actions.dealUpdateTotalFinancingsValue({ financings }));
    yield put(actions.dealUpdateTotalReceivedValue());
    yield put(actions.dealUpdateTotalDifferenceValue());
  } catch(err) {
    console.log('Falha ao atualizar os financiamentos::', err)
    toast.error('Falha ao atualizar os financiamentos')
  }
}

export function* dealRemoveFinancing({ payload }: ActionType<typeof actions.dealRemoveFinancing>) {
  // console.log('@dealRemoveFinancing > payload:: ', payload);
  try {
    const { financings, id } = payload.props.deal;
    const updatedFinancings = financings.filter(financing => financing.id !== id);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, financings: updatedFinancings } }));
    yield put(actions.dealUpdateTotalFinancingsValue({ financings: updatedFinancings }));
    yield put(actions.dealUpdateTotalReceivedValue());
    yield put(actions.dealUpdateTotalDifferenceValue());
  } catch(err) {
    console.log('Falha ao atualizar os financiamentos::', err)
    toast.error('Falha ao atualizar os financiamentos')
  }
}

export function* dealSetTransaction({ payload }: ActionType<typeof actions.dealSetTransaction>) {
  // console.log('@dealSetTransaction/payload:: ', payload)
  try {
    const updatedTransactions: iTransaction[] = mergeDeepArrays([...payload.props.transactions], [payload.props.transaction])
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, transactions: updatedTransactions } }));
    yield put(actions.dealUpdateTotalTransactionsValues({ transactions: updatedTransactions }));
  } catch(err) {
    console.log('Falha ao atualizar os produtos::', err)
    toast.error('Falha ao atualizar os produtos')
  }
}

export function* dealRemoveTransaction({ payload }: ActionType<typeof actions.dealRemoveTransaction>) {
  // console.log('@dealRemoveTransaction > payload:: ', payload);
  try {
    const { transactions, id } = payload.props.deal;
    const updatedTransactions = transactions.filter(transaction => transaction.id !== id);
    yield put(actions.dealSetValuesByFormik({ deal: { ...payload.props.deal, transactions: updatedTransactions } }));
    yield put(actions.dealUpdateTotalTransactionsValues({ transactions: updatedTransactions }));
  } catch(err) {
    console.log('Falha ao atualizar os financiamentos::', err)
    toast.error('Falha ao atualizar os financiamentos')
  }
}

export function* dealUpdateTotalProductsValue({ payload }: ActionType<typeof actions.dealUpdateTotalProductsValue>) {
  // console.log('@dealUpdateTotalProductsValue > dealProducts:: ', payload.props.dealProducts)
  try {
    let cont: number = 0;
    payload.props.dealProducts.forEach((product: iDealProduct) => {
      try {
        cont += Number(product.netPrice)
      } catch {
        throw new Error('Valor inválido! Valor do produto deve ser número!')
      }
    })
    yield put(actions.dealUpdateTotalProductsValueSuccess({ totalProductsValue: cont }));
    yield put(actions.dealUpdateTotalPaidValue());
    yield put(actions.dealUpdateTotalValue());
  } catch(err) {
    console.log('Falha ao atualizar o valor total dos produtos::', err)
    toast.error('Falha ao atualizar o valor total dos produtos')
  }
}

export function* dealUpdateTotalFinancingsValue({ payload }: ActionType<typeof actions.dealUpdateTotalFinancingsValue>) {
  // console.log('@dealUpdateTotalFinancingsValue > products:: ', payload.props.products)
  try {
    let totalFinancingsValue: number = 0;
    payload.props.financings.forEach((financing: iFinancing) => {
      try {
        if(financing.status === 'PAID') {
          totalFinancingsValue += Number(financing.financingValue)
        }
      } catch {
        throw new Error('Valor inválido! Valor do produto deve ser número!')
      }
    })
    yield put(actions.dealUpdateTotalFinancingsValueSuccess({ totalFinancingsValue }));
    yield put(actions.dealUpdateTotalValue());
  } catch(err) {
    console.log('Falha ao atualizar o valor total dos produtos::', err)
    toast.error('Falha ao atualizar o valor total dos produtos')
  }
}

export function* dealUpdateTotalTransactionsValues({ payload }: ActionType<typeof actions.dealUpdateTotalTransactionsValues>) {
  // console.log('@dealUpdateTotalFinancingsValue > products:: ', payload.props.products)
  try {
    let totalTransactionsReceivedValue: number = 0;
    let totalTransactionsPaidValue: number = 0;
    payload.props.transactions.forEach((transaction: iTransaction) => {
      try {
        if(transaction.condition === 'RECEIVEMENT' && (transaction.status === 'DONE' || transaction.status === 'IN_PROGRESS')) {
          totalTransactionsReceivedValue += Number(transaction.value)
        } else if(transaction.condition === 'PAYMENT' && (transaction.status === 'DONE' || transaction.status === 'IN_PROGRESS')) {
          totalTransactionsPaidValue += Number(transaction.value)
        }
      } catch {
        throw new Error('Valor inválido! Valor do produto deve ser número!')
      }
    })
    yield put(actions.dealUpdateTotalTransactionsReceivedValueSuccess({ totalTransactionsReceivedValue }));
    yield put(actions.dealUpdateTotalTransactionsPaidValueSuccess({ totalTransactionsPaidValue }));
    yield put(actions.dealUpdateTotalReceivedValue());
    yield put(actions.dealUpdateTotalPaidValue());
    yield put(actions.dealUpdateTotalDifferenceValue());
    yield put(actions.dealUpdateTotalValue());
  } catch(err) {
    console.log('Falha ao atualizar o valor total dos produtos::', err)
    toast.error('Falha ao atualizar o valor total dos produtos')
  }
}

export function* dealDelete({ payload }: ActionType<typeof actions.dealDelete>) {
  // console.log('@dealDelete/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.delete, `/api/deals/${payload.props.id}`);
    console.log('dealDelete > response:: ', response)
    if(response.data.payload === 1) {
      toast.success('Negociação deletada com sucesso!');
      payload.props.history.push('/');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.dealDeleteFailure());
  }
}

export default all([
  takeLatest(types.DEAL_GET, dealGet),
  takeLatest(types.DEAL_REQUEST, dealRequest),
  takeLatest(types.DEAL_CONCRETIZE, dealConcretize),
  takeLatest(types.DEAL_REVERT, dealRevert),
  takeLatest(types.DEAL_SET_FINANCING, dealSetFinancing),
  takeLatest(types.DEAL_REMOVE_FINANCING, dealRemoveFinancing),
  takeLatest(types.DEAL_SET_TRANSACTION, dealSetTransaction),
  takeLatest(types.DEAL_SET_PURCHASE_VEHICLE, dealSetPurchaseVehicle),
  takeLatest(types.DEAL_REMOVE_PURCHASE_VEHICLE, dealRemovePurchaseVehicle),
  takeLatest(types.DEAL_SET_SELLING_VEHICLE, dealSetSellingVehicle),
  takeLatest(types.DEAL_REMOVE_SELLING_VEHICLE, dealRemoveSellingVehicle),
  takeLatest(types.DEAL_SET_DEAL_PRODUCT, dealSetDealProduct),
  takeLatest(types.DEAL_REMOVE_PRODUCT, dealRemoveDealProduct),
  takeLatest(types.DEAL_UPDATE_TOTAL_PURCHASE_VALUE, dealUpdateTotalPurchaseValue),
  takeLatest(types.DEAL_UPDATE_TOTAL_PRODUCTS_VALUE, dealUpdateTotalProductsValue),
  takeLatest(types.DEAL_UPDATE_TOTAL_FINANCINGS_VALUE, dealUpdateTotalFinancingsValue),
  takeLatest(types.DEAL_UPDATE_TOTAL_TRANSACTIONS_VALUE, dealUpdateTotalTransactionsValues),
  takeLatest(types.DEAL_GET_TOTAL_SELLING_VALUE, dealUpdateTotalSellingValue),
  takeLatest(types.DEAL_DELETE, dealDelete),
])
