import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iObjectPermission } from '../../../interfaces/objects/iObjectPermission.interface';
import * as actions from './actions';

export type ObjectPermissionAction = ActionType<typeof actions>;

export interface iObjectPermissionState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iObjectPermission;
}

export const OBJECT_PERMISSION_GET = '@objectPermission/OBJECT_PERMISSION_GET';
export const OBJECT_PERMISSION_GET_SUCCESS = '@objectPermission/OBJECT_PERMISSION_GET_SUCCESS';
export const OBJECT_PERMISSION_GET_FAILURE = '@objectPermission/OBJECT_PERMISSION_GET_FAILURE';

export const OBJECT_PERMISSION_REQUEST = '@objectPermission/OBJECT_PERMISSION_REQUEST';
export const OBJECT_PERMISSION_CREATED_SUCCESS = '@objectPermission/OBJECT_PERMISSION_CREATED_SUCCESS';
export const OBJECT_PERMISSION_UPDATED_SUCCESS = '@objectPermission/OBJECT_PERMISSION_UPDATED_SUCCESS';
export const OBJECT_PERMISSION_REQUEST_FAILURE = '@objectPermission/OBJECT_PERMISSION_REQUEST_FAILURE';
export const OBJECT_PERMISSION_RESET_STATE = '@objectPermission/OBJECT_PERMISSION_RESET_STATE';

export const OBJECT_PERMISSION_CHANGE_REQUEST_PERFORM = '@objectPermission/OBJECT_PERMISSION_CHANGE_REQUEST_PERFORM';
