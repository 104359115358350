import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iVehicle } from '../../../interfaces/objects/iVehicle.interface';
import * as actions from './actions';

export type VehicleAction = ActionType<typeof actions>;
export interface iVehicleState {
  mounted: boolean;
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iVehicle;
}

export const VEHICLE_SET_VALUES_BY_FORMIK = '@vehicle/VEHICLE_SET_VALUES_BY_FORMIK';

export const VEHICLE_GET = '@vehicle/VEHICLE_GET';
export const VEHICLE_GET_SUCCESS = '@vehicle/VEHICLE_GET_SUCCESS';
export const VEHICLE_GET_FAILURE = '@vehicle/VEHICLE_GET_FAILURE';

export const VEHICLE_REQUEST = '@vehicle/VEHICLE_REQUEST';
export const VEHICLE_CREATED_SUCCESS = '@vehicle/VEHICLE_CREATED_SUCCESS';
export const VEHICLE_UPDATED_SUCCESS = '@vehicle/VEHICLE_UPDATED_SUCCESS';
export const VEHICLE_REQUEST_FAILURE = '@vehicle/VEHICLE_REQUEST_FAILURE';
export const VEHICLE_RESET_STATE = '@vehicle/VEHICLE_RESET_STATE';

export const VEHICLE_SET_EXPENSE = '@vehicle/VEHICLE_SET_EXPENSE';
export const VEHICLE_SET_EXPENSE_SUCCESS = '@vehicle/VEHICLE_SET_EXPENSE_SUCCESS';

export const VEHICLE_SET_DEBIT_VALUE = '@vehicle/VEHICLE_SET_DEBIT_VALUE';
export const VEHICLE_SET_DEBIT_VALUE_SUCCESS = '@vehicle/VEHICLE_SET_DEBIT_VALUE_SUCCESS';

export const VEHICLE_SET_PURCHASE_VALUE = '@vehicle/VEHICLE_SET_PURCHASE_VALUE';
