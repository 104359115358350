import { action } from 'typesafe-actions';
import { History } from 'history';
import { iStore } from '../../../interfaces/objects/iStore.interface';
import { iTenant } from '../../../interfaces/objects/iTenant.interface';
import { iUser } from '../../../interfaces/objects/iUser.interface';
import * as types from './types';

export function setShowAuthModal(props: {
  showAuthModal: boolean;
}) {
  return action(types.AUTH_SET_SHOW_AUTH_MODAL, {
    props
  });
}

export function signInRequest(props: {
  username: string;
  password: string;
  history: History<unknown>;
  prevPath?: string;
}) {
  return action(types.AUTH_SIGN_IN_REQUEST, {
    props
  });
}

export function signInSuccess(props: {
  showStoresModal: boolean;
  accessToken: string;
  decodedTokenPayload: {
    tenant?: iTenant;
    user?: iUser;
  };
  userStores: iStore[]
}) {
  return action(types.AUTH_SIGN_IN_SUCCESS, {
    props
  });
}

export function signInFailure() {
  return action(types.AUTH_SIGN_IN_FAILURE);
}

export function authSelectStore(props: {
  history: any;
  prevPath?: string;
  store: iStore;
}) {
  return action(types.AUTH_SELECT_STORE, {
    props
  });
}

export function authSelectStoreSucess(props: {
  store: iStore;
}) {
  return action(types.AUTH_SELECT_STORE_SUCCESS, {
    props
  });
}

export function authLogout(props: {
  history: any;
}) {
  return action(types.AUTH_LOGOUT, {
    props
  });
}
