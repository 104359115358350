import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* vehicleLocationRequest({ payload }: ActionType<typeof actions.vehicleLocationRequest>) {
  // console.log('@vehicleLocationRequest/payload:: ', payload)
  try {
    if(payload.props.vehicleLocation.id) {
      const response = yield call(api.patch, `/api/vehiclelocations/${payload.props.vehicleLocation.id}`, payload.props.vehicleLocation);
      yield put(actions.vehicleLocationUpdatedSuccess({ vehicleLocation: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/vehiclelocations/', payload.props.vehicleLocation);
      yield put(actions.vehicleLocationCreatedSuccess({ vehicleLocation: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
    const { history } = payload.props;
    history.push('/vehicleLocations/')
  } catch(err) {
    console.log('Erro:: ', err)

    yield put(actions.vehicleLocationRequestFailure());
  }

}

export default all([
  takeLatest(types.VEHICLE_LOCATION_REQUEST, vehicleLocationRequest),
])
