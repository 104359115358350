import { LoadingAction, iLoadingState } from './types';
import * as types from './types';


const initialState: iLoadingState = {
  asyncLoadingItems: [],
  loadingItems: [],
}

export default function changeState(
  state = initialState,
  action: LoadingAction
): iLoadingState {
  switch(action.type) {
    case types.LOADING_ADD_ASYNC_ITEM: {
      return {
        ...state,
        asyncLoadingItems: [...state.asyncLoadingItems, action.payload.props.item]
      };
    }
    case types.LOADING_ADD_ITEM: {
      return {
        ...state,
        loadingItems: [...state.loadingItems, action.payload.props.item]
      };
    }
    case types.LOADING_REMOVE_ASYNC_ITEM: {
      return {
        ...state,
        asyncLoadingItems: state.asyncLoadingItems.filter(i => i !== action.payload.props.item)
      };
    }
    case types.LOADING_REMOVE_ITEM: {
      return {
        ...state,
        loadingItems: state.loadingItems.filter(i => i !== action.payload.props.item)
      };
    }
    default:
      return state;
  }
}
