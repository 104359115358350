import { action } from 'typesafe-actions';

import { iPosMachine } from '../../../interfaces/objects/iPosMachine.interface';
import * as types from './types';

export function posMachineGet({
  history,
  id,
}: {
  history: any;
  id: string;
}) {
  return action(types.POS_MACHINE_GET, {
    history,
    id,
  });
}

export function posMachineGetSuccess(
  data: iPosMachine,
) {
  return action(types.POS_MACHINE_GET_SUCCESS, {
    data,
  });
}

export function posMachineRequest({
  history,
  id,
  name,
  rent,
  code,
  serialNumber,
  observations,
  operator,
  compensationPeriod,
  active,
  anticipationFee,
  anticipationInstallmentFee,
  debitFee,
  creditFee,
  accountId,
  storeId,
}: {
  history: any;
  id?: string;
  name?: string;
  rent?: number;
  code?: string;
  serialNumber?: string;
  observations?: string;
  operator?: string;
  compensationPeriod?: number;
  active?: boolean;
  anticipationFee?: number;
  anticipationInstallmentFee?: number;
  debitFee?: number;
  creditFee?: number;
  accountId?: string;
  storeId?: string;
}) {
  return action(types.POS_MACHINE_REQUEST, {
    history,

    id,
    name,
    rent,
    code,
    serialNumber,
    observations,
    operator,
    compensationPeriod,

    active,
    anticipationFee,
    anticipationInstallmentFee,
    debitFee,
    creditFee,
    accountId,
    storeId,
  });
}

export function posMachineCreatedSuccess(
  data: iPosMachine,
) {
  return action(types.POS_MACHINE_CREATED_SUCCESS, {
    data,
  });
}

export function posMachineUpdatedSuccess(
  data: iPosMachine,
) {
  return action(types.POS_MACHINE_UPDATED_SUCCESS, {
    data,
  });
}

export function posMachineRequestFailure() {
  return action(types.POS_MACHINE_REQUEST_FAILURE);
}

export function posMachineResetState() {
  return action(types.POS_MACHINE_RESET_STATE);
}


