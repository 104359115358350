import moment from "moment";
import { iSoldVehicles } from "../interfaces/iSoldVehicles";
import { iVehicle } from "../interfaces/objects/iVehicle.interface";

interface Props {
  vehicles: iVehicle[]
}
export default ({ vehicles }: Props): iSoldVehicles[] => {
  const finances: iSoldVehicles[] = [];

  vehicles.map((vehicle) => {
    let today: any;
    let stockDays: any;
    // console.log('vehicle.purchaseDate::: ', vehicle.purchaseDate);
    if(vehicle.purchaseDate) {
      if(vehicle.saleDate) {
        today = moment(new Date(vehicle.saleDate));
        stockDays = today.diff(vehicle.purchaseDate, 'days');
      } else {
        today = moment(new Date);
        stockDays = today.diff(vehicle.purchaseDate, 'days');
      }
      // console.log(`purchaseDate::: ${vehicle.purchaseDate}`);
      // console.log(`today::: ${today}`);
      // console.log(`stockDays::: ${stockDays}`);
    }


    finances.push({
      id: vehicle.id,
      concretionDate: vehicle.saleDate,
      vehicleLicensePlate: vehicle.vehicleLicensePlate,
      title: vehicle.title,
      purchaseValue: vehicle.purchaseValue,
      expenses: 0,
      products: 0,
      stockDays: stockDays ?? undefined
    })

  })

  return finances;
};

