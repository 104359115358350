import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* roleGet({ payload }: ActionType<typeof actions.roleGet>) {
  // console.log('@roleGet/payload:: ', payload)
  try {
    const response: any = yield call(api.get, `/api/roles/${payload.props.id}`);
    // console.log('response.data.payload:: ', response.data.payload)
    yield put(actions.roleGetSuccess({ role: response.data.payload }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.roleGetFailure());
  }
}


export function* roleRequest({ payload }: ActionType<typeof actions.roleRequest>) {
  // console.log('@roleeRequest/payload:: ', payload)
  try {
    if(payload.props.role.id) {
      const response = yield call(api.patch, `/api/roles/${payload.props.role.id}`, payload.props.role);
      yield put(actions.roleUpdatedSuccess({ role: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/roles/', payload.props.role);
      yield put(actions.roleCreatedSuccess({ role: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Err', err)
    console.log('Erro:: ', err)
    yield put(actions.roleRequestFailure());
  }
}

export default all([
  takeLatest(types.ROLE_GET, roleGet),
  takeLatest(types.ROLE_REQUEST, roleRequest),
])
