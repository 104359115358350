import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* transactionGet({ payload }: ActionType<typeof actions.transactionGet>) {
  // console.log('@transactionGet/payload:: ', payload)
  const { id, client, guarantor, store } = payload.props;
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/transactions/${id}`);
    yield put(actions.transactionGetSuccess({ transaction: response.data.payload }));
    yield put(actions.transactionSetCondition({
      transaction: response.data.payload,
      condition: response.data.payload.condition,
      client,
      guarantor,
      store
    }));
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.transactionGetFailure());
  }
}


export function* transactionRequest({ payload }: ActionType<typeof actions.transactionRequest>) {
  // console.log('@transactionRequest/payload:: ', payload)
  try {
    const { transaction } = payload.props;
    if(transaction.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/transactions/${transaction.type}/${transaction.id}`, transaction);
      yield put(actions.transactionUpdatedSuccess({ transaction: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, `/api/transactions/${transaction.type}/`, transaction);
      yield put(actions.transactionCreatedSuccess({ transaction: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    const error = get(err, 'response.data.error', []);
    const statusCode = get(err, 'response.data.statusCode', 0);
    const messages = get(err, 'response.data.message', []);
    if(statusCode == 401) {
      toast.error('Você precisa fazer login!');
    }
    // console.log(`Erro[${statusCode}]:: ${error} -`, messages);

    yield put(actions.transactionRequestFailure());
  }
}


export function* transactionSetCondition({ payload }: ActionType<typeof actions.transactionSetCondition>) {
  // console.log('@transactionSetCondition/payload:: ', payload);
  yield put(actions.transactionSetValuesByFormik({ transaction: payload.props.transaction }));
}

export default all([
  takeLatest(types.TRANSACTION_GET, transactionGet),
  takeLatest(types.TRANSACTION_REQUEST, transactionRequest),
  takeLatest(types.TRANSACTION_SET_CONDITION, transactionSetCondition),
])
