import { ITaskList } from "../store/modules/tasks/types";


export const reorder = (
  list: any,
  startIndex: number,
  endIndex: number
): any[] => {
  const result = [...list.cards];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */

export const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = source;
  const destClone = destination;
  const [removed] = sourceClone.cards.splice(droppableSource.index, 1);

  destClone.cards.splice(droppableDestination.index, 0, removed);

  let result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


export const onDragEnd = (result: any) => {
  const { tasks, source, destination } = result
  const sInd = +source.droppableId;
  const dInd = +destination.droppableId;

  if (sInd === dInd) {
    const items = reorder(tasks.lists[sInd], source.index, destination.index);
    tasks.lists[sInd].cards = items;
  } else {
    const result = move(tasks.lists[sInd], tasks.lists[dInd], source, destination);
    const newLists = [...tasks.lists];
    newLists[sInd] = result[sInd];
    newLists[dInd] = result[dInd];

    tasks.lists = newLists;
  }
}
