import { ITheme, ThemeAction } from './types';
import * as types from './types';
import { light, dark } from '../../../styles/themes/theme'

const initialState: ITheme = light;

export default function toggleTheme (
  state = initialState,
  action: ThemeAction
): ITheme {

  if (action.type === types.TOGGLE_THEME) {
    if (state.title === 'light') {
      return dark;
    } else if (state.title === 'dark') {
      return light;
    }
  }
  return initialState;
}
