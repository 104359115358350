import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iOrigin } from '../../../interfaces/objects/iOrigin.interface';
import * as actions from './actions';

export type OriginAction = ActionType<typeof actions>;

export interface iOriginState {
  originCreated: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iOrigin;
}

export const ORIGIN_GET = '@origin/ORIGIN_GET';
export const ORIGIN_GET_SUCCESS = '@origin/ORIGIN_GET_SUCCESS';
export const ORIGIN_GET_FAILURE = '@origin/ORIGIN_GET_FAILURE';

export const ORIGIN_REQUEST = '@origin/ORIGIN_REQUEST';
export const ORIGIN_CREATED_SUCCESS = '@origin/ORIGIN_CREATED_SUCCESS';
export const ORIGIN_UPDATED_SUCCESS = '@origin/ORIGIN_UPDATED_SUCCESS';
export const ORIGIN_REQUEST_FAILURE = '@origin/ORIGIN_REQUEST_FAILURE';
export const ORIGIN_RESET_STATE = '@origin/ORIGIN_RESET_STATE';
