import { action } from 'typesafe-actions';

import { iProduct } from '../../../interfaces/objects/iProduct.interface';
import * as types from './types';


export function productRequest(props: {
  history: any;
  product: iProduct,
}) {

  return action(types.PRODUCT_REQUEST, {
    props
  });
}

export function productCreatedSuccess(props: {
  product: iProduct,
}) {

  return action(types.PRODUCT_CREATED_SUCCESS, {
    props,
  });
}

export function productUpdatedSuccess(props: {
  product: iProduct,
}) {
  return action(types.PRODUCT_UPDATED_SUCCESS, {
    props,
  });
}

export function productRequestFailure() {
  return action(types.PRODUCT_REQUEST_FAILURE);
}

export function productResetState() {
  return action(types.PRODUCT_RESET_STATE);
}
