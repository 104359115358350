import { action } from 'typesafe-actions';

import { iStore } from '../../../interfaces/objects/iStore.interface';
import * as types from './types';

export function storeRequest(props: {
  history: any;
  store: iStore;
}) {
  return action(types.STORE_REQUEST, {
    props
  });
}

export function storeCreatedSuccess(props: {
  store: iStore,
}) {
  return action(types.STORE_CREATED_SUCCESS, {
    props
  });
}

export function storeUpdatedSuccess(props: {
  store: iStore,
}) {
  return action(types.STORE_UPDATED_SUCCESS, {
    props
  });
}

export function storeRequestFailure() {
  return action(types.STORE_REQUEST_FAILURE);
}

export function storeResetState() {
  return action(types.STORE_RESET_STATE);
}
