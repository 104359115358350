import { TransactionAction, iTransactionState } from './types';

import * as types from './types';
import { iOption } from '../../../components/formik/types';


const initialState: iTransactionState = {
  loadingData: true,
  requestPerform: false,
  fields: {
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      // hidden: true,
      options: [
        { key: 'MONEY', label: 'Dinheiro', value: 'MONEY' },
        { key: 'CREDIT_CARD', label: 'Cartão de crédito', value: 'CREDIT_CARD' },
        { key: 'DEBIT_CARD', label: 'Cartão de débito', value: 'DEBIT_CARD' },
        { key: 'BANK_TRANSFER', label: 'Transferência bancária', value: 'BANK_TRANSFER' },
        { key: 'BANK_CHECK', label: 'Cheque', value: 'BANK_CHECK' },
        { key: 'OVERDRAFT', label: 'Cheque especial', value: 'OVERDRAFT' },
        { key: 'SLIP', label: 'Boleto', value: 'SLIP' },
        { key: 'PROMISSORY', label: 'Nota promissória', value: 'PROMISSORY' },
        { key: 'TED', label: 'TED', value: 'TED' },
        { key: 'DOC', label: 'DOC', value: 'DOC' },
      ],
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { key: 'OPEN', label: 'Em aberto', value: 'OPEN' },
        { key: 'IN_PROGRESS', label: 'Em progresso', value: 'IN_PROGRESS' },
        { key: 'DONE', label: 'Concluído', value: 'DONE' },
      ],
    },
    value: {
      name: 'value',
      label: 'Valor',
      type: 'currency',
    },
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea',
    },
    installmentValue: {
      name: 'installmentValue',
      label: 'Valor das parcelas',
      type: 'currency',
    },
    installments: {
      name: 'installments',
      label: 'Nº parcelas',
      type: 'input',
    },
    returnValue: {
      name: 'returnValue',
      label: 'Valor do retorno',
      type: 'currency',
    },
    gracePeriod: {
      name: 'gracePeriod',
      label: 'Período de carência',
      type: 'input',
    },
    issuingCity: {
      name: 'issuingCity',
      label: 'Cidade emissora',
      type: 'input',
    },
    paymentCity: {
      name: 'paymentCity',
      label: 'Cidade de pagamento',
      type: 'input',
    },
    coefficient: {
      name: 'coefficient',
      label: 'Coeficiente',
      type: 'input',
    },
    effectiveDate: {
      name: 'effectiveDate',
      label: 'Data da efetivação',
      type: 'date',
    },
    deadline: {
      name: 'deadline',
      label: 'Vencimento',
      type: 'date',
    },
    tac: {
      name: 'tac',
      label: 'Taxa de Cadastro',
      type: 'input',
    },
    sourceAccountId: {
      name: 'sourceAccountId',
      label: 'Conta de origem',
      object: 'accounts',
      type: 'lookup',
      hideModal: true,
    },
    targetAccountId: {
      name: 'targetAccountId',
      label: 'Conta de destino',
      object: 'accounts',
      type: 'lookup',
      hideModal: true,
    },
    guarantorId: {
      name: 'guarantorId',
      label: 'Fiador',
      object: 'contacts',
      type: 'lookup',
      hideModal: true,
    },
    payerId: {
      name: 'payerId',
      label: 'Pagador',
      type: 'select',
      options: []
    },
    receiverId: {
      name: 'receiverId',
      label: 'Recebedor',
      type: 'select',
      options: []
    },
    posMachineId: {
      name: 'posMachineId',
      label: 'Máquina POS',
      object: 'posMachines',
      type: 'lookup',
      hideModal: true,
    },
    dealId: {
      name: 'dealId',
      label: 'Negociação',
      type: 'input',
      hidden: true,
    },
  },
  data: {
    status: 'OPEN',
    condition: 'PAYMENT',
    type: 'MONEY',
    value: 0,
    title: '',
    description: '',
    installmentValue: 0,
    installments: 1,
    returnValue: 0,
    gracePeriod: 0,
    issuingCity: '',
    paymentCity: '',
    coefficient: 0,
    tac: 0,
    sourceAccountId: '',
    targetAccountId: '',
    guarantorId: '',
    payerId: '',
    receiverId: '',
    posMachineId: '',
    dealId: '',
  }
}

export default function changeState(
  state = initialState,
  action: TransactionAction
): iTransactionState {
  switch(action.type) {
    case types.TRANSACTION_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.TRANSACTION_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.transaction,
        loadingData: false
      };
    case types.TRANSACTION_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.TRANSACTION_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.TRANSACTION_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.transaction,
        requestPerform: true,
        loadingData: false
      };
    case types.TRANSACTION_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.transaction,
        requestPerform: true,
        loadingData: false
      };
    case types.TRANSACTION_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.TRANSACTION_SET_CONDITION: {
      const payerOptions: iOption[] = [];
      const receiverOptions: iOption[] = [];
      if(action.payload.props.client) {
        if(action.payload.props.condition === 'PAYMENT') {
          receiverOptions.push(action.payload.props.client);
        } else {
          payerOptions.push(action.payload.props.client);
        }
      }
      if(action.payload.props.guarantor) {
        if(action.payload.props.condition === 'PAYMENT') {
          receiverOptions.push(action.payload.props.guarantor);
        } else {
          payerOptions.push(action.payload.props.guarantor);
        }
      }
      if(action.payload.props.store) {
        if(action.payload.props.condition === 'PAYMENT') {
          payerOptions.push(action.payload.props.store);
        } else {
          receiverOptions.push(action.payload.props.store);
        }
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          payerId: {
            ...state.fields.payerId,
            options: payerOptions
          },
          receiverId: {
            ...state.fields.receiverId,
            options: receiverOptions
          }
        },
        data: {
          ...action.payload.props.transaction,
          condition: action.payload.props.condition,
          payerId: payerOptions[0]?.value ?? '',
          receiverId: receiverOptions[0]?.value ?? ''
        }
      };
    }
    case types.TRANSACTION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
