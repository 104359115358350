import { ActionType } from 'typesafe-actions';

import { IFieldSchema } from '../../../components/formik/types';
import { iContract } from '../../../interfaces/contract.interface';
import { iDeal } from '../../../interfaces/objects/iDeal.interface';
import * as actions from './actions';

export type DealAction = ActionType<typeof actions>;

export interface iDealState {
  mounted: boolean;
  editingFormValues: boolean;
  requestPerform: boolean;
  loadingData: boolean;
  contract: iContract;
  totalSellingValue: number;
  totalPurchaseValue: number;
  totalFinancingsValue: number;
  totalTransactionsReceivedValue: number;
  totalTransactionsPaidValue: number;
  totalProductsValue: number;
  totalValue: number;
  totalReceivedValue: number;
  totalPaidValue: number;
  totalDifferenceValue: number;
  fields: { [key: string]: IFieldSchema };
  data: iDeal;
}

export const DEAL_SET_VALUES_BY_FORMIK = '@deal/DEAL_SET_VALUES_BY_FORMIK';

export const DEAL_GET = '@deal/DEAL_GET';
export const DEAL_GET_SUCCESS = '@deal/DEAL_GET_SUCCESS';
export const DEAL_GET_FAILURE = '@deal/DEAL_GET_FAILURE';

export const DEAL_HANDLE_EDITING_FORM_VALUE = '@deal/DEAL_HANDLE_EDITING_FORM_VALUE';
export const DEAL_HANDLE_MOUNTED = '@deal/DEAL_HANDLE_MOUNTED';

export const DEAL_REQUEST = '@deal/DEAL_REQUEST';
export const DEAL_CREATED_SUCCESS = '@deal/DEAL_CREATED_SUCCESS';
export const DEAL_UPDATED_SUCCESS = '@deal/DEAL_UPDATED_SUCCESS';
export const DEAL_REQUEST_FAILURE = '@deal/DEAL_REQUEST_FAILURE';
export const DEAL_RESET_STATE = '@deal/DEAL_RESET_STATE';

export const DEAL_CONCRETIZE = '@deal/DEAL_CONCRETIZE';
export const DEAL_CONCRETIZE_SUCCESS = '@deal/DEAL_CONCRETIZE_SUCCESS';
export const DEAL_CONCRETIZE_FAILURE = '@deal/DEAL_CONCRETIZE_FAILURE';

export const DEAL_REVERT = '@deal/DEAL_REVERT';
export const DEAL_REVERT_SUCCESS = '@deal/DEAL_REVERT_SUCCESS';
export const DEAL_REVERT_FAILURE = '@deal/DEAL_REVERT_FAILURE';

export const DEAL_SET_CONTRACT_CLAUSE = '@deal/DEAL_SET_CONTRACT_CLAUSE';
export const DEAL_SET_CONTRACT_TYPE = '@deal/DEAL_SET_CONTRACT_TYPE';

export const DEAL_SET_STORE = '@deal/DEAL_SET_STORE';

export const DEAL_SET_PURCHASE_VEHICLE = '@deal/DEAL_SET_PURCHASE_VEHICLE';
export const DEAL_REMOVE_PURCHASE_VEHICLE = '@deal/DEAL_REMOVE_PURCHASE_VEHICLE';

export const DEAL_SET_SELLING_VEHICLE = '@deal/DEAL_SET_SELLING_VEHICLE';
export const DEAL_REMOVE_SELLING_VEHICLE = '@deal/DEAL_REMOVE_SELLING_VEHICLE';

export const DEAL_SET_DEAL_PRODUCT = '@deal/DEAL_SET_DEAL_PRODUCT';
export const DEAL_REMOVE_PRODUCT = '@deal/DEAL_REMOVE_PRODUCT';

export const DEAL_SET_FINANCING = '@deal/DEAL_SET_FINANCING';
export const DEAL_REMOVE_FINANCING = '@deal/DEAL_REMOVE_FINANCING';

export const DEAL_SET_TRANSACTION = '@deal/DEAL_SET_TRANSACTION';
export const DEAL_REMOVE_TRANSACTION = '@deal/DEAL_REMOVE_TRANSACTION';

export const DEAL_UPDATE_TOTAL_PRODUCTS_VALUE = '@deal/DEAL_UPDATE_TOTAL_PRODUCTS_VALUE';
export const DEAL_UPDATE_TOTAL_PRODUCTS_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_PRODUCTS_VALUE_SUCCESS';

export const DEAL_UPDATE_TOTAL_FINANCINGS_VALUE = '@deal/DEAL_GET_TOTAL_FINANCINGS_VALUE';
export const DEAL_UPDATE_TOTAL_FINANCINGS_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_FINANCINGS_VALUE_SUCCESS';

export const DEAL_UPDATE_TOTAL_TRANSACTIONS_VALUE = '@deal/DEAL_GET_TOTAL_TRANSACTIONS_VALUE';
export const DEAL_UPDATE_TOTAL_TRANSACTIONS_RECEIVED_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_TRANSACTIONS_RECEIVED_VALUE_SUCCESS';
export const DEAL_UPDATE_TOTAL_TRANSACTIONS_PAID_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_TRANSACTIONS_PAID_VALUE_SUCCESS';


export const DEAL_GET_TOTAL_SELLING_VALUE = '@deal/DEAL_GET_TOTAL_SELLING_VALUE';
export const DEAL_UPDATE_TOTAL_SELLING_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_SELLING_VALUE_SUCCESS';
export const DEAL_GET_TOTAL_SELLING_VALUE_FAILURE = '@deal/DEAL_GET_TOTAL_SELLING_VALUE_FAILURE';

export const DEAL_UPDATE_TOTAL_PURCHASE_VALUE = '@deal/DEAL_UPDATE_TOTAL_PURCHASE_VALUE';
export const DEAL_UPDATE_TOTAL_PURCHASE_VALUE_SUCCESS = '@deal/DEAL_UPDATE_TOTAL_PURCHASE_VALUE_SUCCESS';
export const DEAL_UPDATE_TOTAL_PURCHASE_VALUE_FAILURE = '@deal/DEAL_UPDATE_TOTAL_PURCHASE_VALUE_FAILURE';

export const DEAL_UPDATE_TOTAL_VALUE = '@deal/DEAL_GET_TOTAL_VALUE';

export const DEAL_UPDATE_TOTAL_RECEIVED_VALUE = '@deal/DEAL_UPDATE_TOTAL_RECEIVED_VALUE';
export const DEAL_UPDATE_TOTAL_PAID_VALUE = '@deal/DEAL_UPDATE_TOTAL_PAID_VALUE';
export const DEAL_UPDATE_TOTAL_DIFFERENCE_VALUE = '@deal/DEAL_UPDATE_TOTAL_DIFFERENCE_VALUE';

export const DEAL_DELETE = '@deal/DEAL_DELETE';
export const DEAL_DELETE_SUCCESS = '@deal/DEAL_DELETE_SUCCESS';
export const DEAL_DELETE_FAILURE = '@deal/DEAL_DELETE_FAILURE';
