import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iRole } from '../../../interfaces/objects/iRole.interface';

import * as actions from './actions';

export type RoleAction = ActionType<typeof actions>;

export interface iRoleState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iRole;
}

export const ROLE_GET = '@role/ROLE_GET';
export const ROLE_GET_SUCCESS = '@role/ROLE_GET_SUCCESS';
export const ROLE_GET_FAILURE = '@role/ROLE_GET_FAILURE';


export const ROLE_REQUEST = '@role/ROLE_REQUEST';
export const ROLE_CREATED_SUCCESS = '@role/ROLE_CREATED_SUCCESS';
export const ROLE_UPDATED_SUCCESS = '@role/ROLE_UPDATED_SUCCESS';
export const ROLE_REQUEST_FAILURE = '@role/ROLE_REQUEST_FAILURE';
export const ROLE_RESET_STATE = '@role/ROLE_RESET_STATE';
