import { action } from 'typesafe-actions';

import { iOrigin } from '../../../interfaces/objects/iOrigin.interface';
import * as types from './types';

export function originGet({
  history,
  id,
}: {
  history: any;
  id: string;
}) {
  return action(types.ORIGIN_GET, {
    history,
    id,
  });
}

export function originGetSuccess(
  data: iOrigin,
) {
  return action(types.ORIGIN_GET_SUCCESS, {
    data,
  });
}

export function originGetFailure() {
  return action(types.ORIGIN_GET_FAILURE);
}

export function originRequest({
  history,
  id,
  title,
}: {
  history: any;
  id?: string;
  title?: string;
}) {
  return action(types.ORIGIN_REQUEST, {
    history,
    id,
    title,
  });
}

export function originCreatedSuccess(
  data: iOrigin,
) {
  return action(types.ORIGIN_CREATED_SUCCESS, {
    data,
  });
}

export function originUpdatedSuccess(
  data: iOrigin,
) {
  return action(types.ORIGIN_UPDATED_SUCCESS, {
    data,
  });
}

export function originRequestFailure() {
  return action(types.ORIGIN_REQUEST_FAILURE);
}

export function originResetState() {
  return action(types.ORIGIN_RESET_STATE);
}

