import { ActionType } from 'typesafe-actions';
import { iStore } from '../../../interfaces/objects/iStore.interface';
import { iTenant } from '../../../interfaces/objects/iTenant.interface';
import { iUser } from '../../../interfaces/objects/iUser.interface';
import * as actions from './actions';

export type AuthAction = ActionType<typeof actions>;

export interface iAuthState {
  showAuthModal: boolean;
  showStoresModal: boolean;
  isSignedIn: boolean;
  error: boolean;
  notifications?: any[];
  tenant?: iTenant;
  user?: iUser;
  store?: iStore;
  userStores?: iStore[];
}

export const AUTH_SET_SHOW_AUTH_MODAL = '@auth/AUTH_SET_SHOW_AUTH_MODAL';

export const AUTH_SIGN_IN_REQUEST = '@auth/AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS = '@auth/AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = '@auth/AUTH_SIGN_IN_FAILURE';

export const AUTH_LOGOUT = '@auth/AUTH_LOGOUT';
export const AUTH_SELECT_STORE = '@auth/AUTH_SELECT_STORE';
export const AUTH_SELECT_STORE_SUCCESS = '@auth/AUTH_SELECT_STORE_SUCCESS';

