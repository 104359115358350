import { OriginAction, iOriginState } from './types';
import * as types from './types';


const initialState: iOriginState = {
  originCreated: false,
  fields: {
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
      maxlength: 255
    },
  },
  data: {
    title: '',
  }
}

export default function changeState(
  state = initialState,
  action: OriginAction
): iOriginState {
  switch(action.type) {
    case types.ORIGIN_GET:
      return {
        ...state,
      };
    case types.ORIGIN_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      };
    case types.ORIGIN_REQUEST:
      return {
        ...state,
      };
    case types.ORIGIN_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        originCreated: true
      };
    case types.ORIGIN_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        originCreated: true
      };
    case types.ORIGIN_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.ORIGIN_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
