import { VehicleLocationAction, iVehicleLocationState } from './types';
import * as types from './types';


const initialState: iVehicleLocationState = {
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input',
    },
    nickname: {
      name: 'nickname',
      label: 'Apelido',
      type: 'input',
    },
    responsibleId: {
      name: 'responsibleId',
      label: 'Responsável',
      type: 'lookup',
      object: 'users',
    },
  },
  data: {
    name: 'test',
    nickname: 'test',
  }
}

export default function changeState(
  state = initialState,
  action: VehicleLocationAction
): iVehicleLocationState {
  switch(action.type) {
    case types.VEHICLE_LOCATION_REQUEST:
      return {
        ...state,
      };
    case types.VEHICLE_LOCATION_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.vehicleLocation,
        requestPerform: true
      };
    case types.VEHICLE_LOCATION_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.vehicleLocation,
        requestPerform: true
      };
    case types.VEHICLE_LOCATION_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.VEHICLE_LOCATION_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
