import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store/createStore';
import { LoaderSpin } from '../LoaderSpin';
import { Container } from './styled';

type Props = {
  text?: string;
}


export const Loading: FC<Props> = ({ text = 'Carregando...' }) => {
  const { loadingItems } = useSelector((state: StoreState) => state.loading);

  if(!loadingItems.length) {
    return null;
  }

  console.log('loadingItems', loadingItems)

  return (
    <Container>
      <LoaderSpin size={120} borderSize={16} />
      {text}
    </Container>
  );

}


