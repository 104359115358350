import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';

import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';


export function* expenseRequest({ payload }: ActionType<typeof actions.expenseRequest>) {
  // console.log('@expenseRequest/payload:: ', payload)
  try {
    if(payload.data.expense.id) {
      const response = yield call(api.patch, `/api/expenses/${payload.data.expense.id}`, payload.data.expense);
      yield put(actions.expenseUpdatedSuccess({ expense: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response = yield call(api.post, '/api/expenses/', payload.data.expense);
      yield put(actions.expenseCreatedSuccess({ expense: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.expenseRequestFailure());
  }

}

export default all([
  takeLatest(types.EXPENSE_REQUEST, expenseRequest),
])
