import { TaskAction, ITaskState } from './types';
import * as types from './types';


const initialState: ITaskState = {
  loadingData: true,
  requestPerform: false,
  error: false,
  lists: [
    {
      title: 'Tarefas',
      creatable: true,
      cards: [
        {
          id: '1',
          content: 'Estudar módulo 01 de NodeJS',
          labels: ['#7159c1'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '2',
          content: 'Criar vídeo para o Youtube ensinando a recriar a interface do Pipefy',
          labels: ['#7159c1'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '3',
          content: 'Estudar módulo 03 de React Native',
          labels: ['#7159c1'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '4',
          content: 'Gravar Aula "NextJS: Utilizando server-side rendering com ReactJS"',
          labels: ['#54e1f7'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '5',
          content: 'Gravar testes e deploy ReactJS',
          labels: ['#54e1f7'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
      ]
    },
    {
      title: 'Fazendo',
      creatable: false,
      cards: [
        {
          id: '6',
          content: 'Recriando clone do Pipefy',
          labels: [],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        }
      ]
    },
    {
      title: 'Pausado',
      creatable: false,
      cards: [
        {
          id: '7',
          content: 'Gravar sobre Geolocalização e mapas com React Native',
          labels: ['#7159c1'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '8',
          content: 'Gravar testes e deploy ReactJS',
          labels: ['#54e1f7'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png'
        },
        {
          id: '9',
          content: 'Ajustes na biblioteca unform',
          labels: [],
        }
      ]
    },
    {
      title: 'Concluído',
      creatable: false,
      done: true,
      cards: [
        {
          id: '10',
          content: 'Gravar aula sobre deploy e CI com React Native',
          labels: [],
        },
        {
          id: '12',
          content: 'Gravar testes e deploy ReactJS',
          labels: ['#54e1f7'],
        },
        {
          id: '13',
          content: 'Gravar Aula "Internacionalização de aplicações Node.js, ReactJS e React Native"',
          labels: ['#7159c1'],
        }
      ]
    },
  ],
  fields: {
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
    },
    description: {
      name: 'description',
      label: 'Descrição',
      type: 'textarea',
      placeholder: 'Descrição da tarefa',
    },
    categoryId: {
      name: 'categoryId',
      label: 'Categoria',
      type: 'select',
      options: [
        {
          key: 'selecione',
          label: 'Selecione',
          value: ''
        },
        {
          key: 'category1',
          label: 'Categoria 1',
          value: 'category1'
        },
        {
          key: 'category2',
          label: 'Categoria 2',
          value: 'category2'
        },
        {
          key: 'category3',
          label: 'Categoria 3',
          value: 'category3'
        }
      ]
    },
    subCategoryId: {
      name: 'subCategoryId',
      label: 'Subcategoria',
      type: 'select',
      options: [
        {
          key: 'selecione',
          label: 'Selecione',
          value: ''
        },
        {
          key: 'subcategory1',
          label: 'Subcategoria 1',
          value: 'subcategory1'
        },
        {
          key: 'subcategory2',
          label: 'Subcategoria 2',
          value: 'subcategory2'
        },
        {
          key: 'subcategory3',
          label: 'Subcategoria 3',
          value: 'subcategory3'
        }
      ]
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          key: 'selecione',
          label: 'Selecione',
          value: ''
        },
        {
          key: 'OPEN',
          label: 'Em aberto',
          value: 'OPEN'
        },
        {
          key: 'IN_PROGRESS',
          label: 'Em andamento',
          value: 'IN_PROGRESS'
        },
        {
          key: 'DONE',
          label: 'Concluída',
          value: 'DONE'
        }
      ],
    },
    ownerId: {
      name: 'ownerId',
      label: 'Responsável',
      object: 'users',
      type: 'lookup',
      hideModal: true,
    },
    vehicleId: {
      name: 'vehicleId',
      label: 'Veículo',
      object: 'vehicles',
      type: 'lookup',
      hideModal: true,
    },
  },
  data: {
    title: '',
    description: '',
    categoryId: '',
    subCategoryId: '',
    status: 'OPEN',
    ownerId: '',
    vehicleId: '',
    vehicle: undefined
  }
}

export default function changeState(
  state = initialState,
  action: TaskAction
): ITaskState {
  switch(action.type) {
    case types.TASK_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.TASK_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.task,
        loadingData: false
      };
    case types.TASK_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.TASK_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.TASK_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.task,
        requestPerform: true,
        loadingData: false
      };
    case types.TASK_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.task,
        requestPerform: true,
        loadingData: false
      };
    case types.TASK_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.CHANGE_POSITION:
      return {
        ...state,
      };
    case types.TASK_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
