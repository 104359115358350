import { ITheme } from '../../store/modules/theme/types'

export const light: ITheme = {
  title: 'light',

  colors: {
    primary: '#3f51b5',
    secondary: '#6C757D',
    background: 'rgb(243, 243, 243)',

    white: '#fff',
    black: '#333',
    info: '#17A2B8',
    success: '#28A745',
    warning: '#F2AF29',
    danger: '#DC3545'
  },
}

export const dark: ITheme = {
  title: 'dark',

  colors: {
    primary: '#F2AF29',
    secondary: '#6C757D',
    background: '#5B5B5B',

    white: '#333',
    black: '#fff',
    info: '#17A2B8',
    success: '#28A745',
    warning: '#F2AF29',
    danger: '#DC3545'
  },
};
