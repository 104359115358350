import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iContact } from '../../../interfaces/objects/iContact.interface';
import * as actions from './actions';

export type ContactAction = ActionType<typeof actions>;

export interface iContactState {
  mounted: boolean;
  editingFormValues: boolean;
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iContact;
}

export const CONTACT_SET_VALUES_BY_FORMIK = '@contact/CONTACT_SET_VALUES_BY_FORMIK';

export const CONTACT_GET = '@contact/CONTACT_GET';
export const CONTACT_GET_SUCCESS = '@contact/CONTACT_GET_SUCCESS';
export const CONTACT_GET_FAILURE = '@contact/CONTACT_GET_FAILURE';

export const CONTACT_REQUEST = '@contact/CONTACT_REQUEST';
export const CONTACT_CREATED_SUCCESS = '@contact/CONTACT_CREATED_SUCCESS';
export const CONTACT_UPDATED_SUCCESS = '@contact/CONTACT_UPDATED_SUCCESS';
export const CONTACT_REQUEST_FAILURE = '@contact/CONTACT_REQUEST_FAILURE';
export const CONTACT_RESET_STATE = '@contact/CONTACT_RESET_STATE';

export const CONTACT_HANDLE_EDITING_FORM_VALUE = '@contact/CONTACT_HANDLE_EDITING_FORM_VALUE';
export const CONTACT_HANDLE_MOUNTED = '@contact/CONTACT_HANDLE_MOUNTED';
