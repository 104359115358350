import { ActionType } from 'typesafe-actions';

import { IFieldSchema } from '../../../components/formik/types';
import { iVehicleModel } from '../../../interfaces/objects/iVehicleModel.interface';

import * as actions from './actions';

export type VehicleModelAction = ActionType<typeof actions>;

export interface iVehicleModelState {
  mounted: boolean;
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iVehicleModel;
}

export const VEHICLE_MODEL_SET_VALUES_BY_FORMIK = '@vehicleModel/VEHICLE_MODEL_SET_VALUES_BY_FORMIK';

export const VEHICLE_MODEL_GET = '@vehicleModel/VEHICLE_MODEL_GET';
export const VEHICLE_MODEL_GET_SUCCESS = '@vehicleModel/VEHICLE_MODEL_GET_SUCCESS';
export const VEHICLE_MODEL_GET_FAILURE = '@vehicleModel/VEHICLE_MODEL_GET_FAILURE';


export const VEHICLE_MODEL_REQUEST = '@vehicleModel/VEHICLE_MODEL_REQUEST';
export const VEHICLE_MODEL_CREATED_SUCCESS = '@vehicleModel/VEHICLE_MODEL_CREATED_SUCCESS';
export const VEHICLE_MODEL_UPDATED_SUCCESS = '@vehicleModel/VEHICLE_MODEL_UPDATED_SUCCESS';
export const VEHICLE_MODEL_REQUEST_FAILURE = '@vehicleModel/VEHICLE_MODEL_REQUEST_FAILURE';
export const VEHICLE_MODEL_RESET_STATE = '@vehicleModel/VEHICLE_MODEL_RESET_STATE';


// export const VEHICLE_MODEL_SET_FIPE_BRAND = '@vehicleModel/VEHICLE_MODEL_SET_FIPE_BRAND';
// export const VEHICLE_MODEL_SET_FIPE_MODEL = '@vehicleModel/VEHICLE_MODEL_SET_FIPE_MODEL';
// export const VEHICLE_MODEL_SET_FIPE_YEAR = '@vehicleModel/VEHICLE_MODEL_SET_FIPE_YEAR';

export const VEHICLE_MODEL_HANDLE_MOUNTED = '@vehicleModel/VEHICLE_MODEL_HANDLE_MOUNTED';
