import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import * as actions from './actions';
import * as types from './types';

export function* vehicleModelGet({ payload }: ActionType<typeof actions.vehicleModelGet>) {
  // console.log('@vehicleModelGet/payload:: ', payload)
  try {
    const response: AxiosResponse<iApiResponse> = yield call(api.get, `/api/vehicleModels/${payload.props.id}`);
    yield put(actions.vehicleModelGetSuccess({ vehicleModel: response.data.payload }));
    console.log('response.data.payload::: ', response.data.payload)
  } catch(err) {
    console.log('Erro:: ', err)
    yield put(actions.vehicleModelGetFailure());
  }
}

export function* vehicleModelRequest({ payload }: ActionType<typeof actions.vehicleModelRequest>) {
  console.log('@vehicleModeleRequest/payload:: ', payload)
  try {
    if(payload.props.vehicleModel.id) {
      const response: AxiosResponse<iApiResponse> = yield call(api.patch, `/api/vehicleModels/${payload.props.vehicleModel.id}`, payload.props.vehicleModel);
      yield put(actions.vehicleModelUpdatedSuccess({ vehicleModel: response.data.payload }));
      toast.success('Cadastro atualizado!');
    } else {
      const response: AxiosResponse<iApiResponse> = yield call(api.post, '/api/vehicleModels/', payload.props.vehicleModel);
      yield put(actions.vehicleModelCreatedSuccess({ vehicleModel: response.data.payload }));
      toast.success('Cadastro realizado!');
    }
  } catch(err) {
    // console.log('Err', err)
    console.log('Erro:: ', err)
    yield put(actions.vehicleModelRequestFailure());
  }
}

export default all([
  takeLatest(types.VEHICLE_MODEL_GET, vehicleModelGet),
  takeLatest(types.VEHICLE_MODEL_REQUEST, vehicleModelRequest),
])
