import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iFieldPermission } from '../../../interfaces/objects/iFieldPermission.interface';
import * as actions from './actions';

export type FieldPermissionAction = ActionType<typeof actions>;

export interface iFieldPermissionState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iFieldPermission;
}

export const FIELD_PERMISSION_REQUEST = '@fieldPermission/FIELD_PERMISSION_REQUEST';
export const FIELD_PERMISSION_CREATED_SUCCESS = '@fieldPermission/FIELD_PERMISSION_CREATED_SUCCESS';
export const FIELD_PERMISSION_UPDATED_SUCCESS = '@fieldPermission/FIELD_PERMISSION_UPDATED_SUCCESS';
export const FIELD_PERMISSION_REQUEST_FAILURE = '@fieldPermission/FIELD_PERMISSION_REQUEST_FAILURE';
export const FIELD_PERMISSION_RESET_STATE = '@fieldPermission/FIELD_PERMISSION_RESET_STATE';
