import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iStore } from '../../../interfaces/objects/iStore.interface';
import * as actions from './actions';

export type StoreAction = ActionType<typeof actions>;

export interface iStoreState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iStore;
}

export const STORE_REQUEST = '@store/STORE_REQUEST';
export const STORE_CREATED_SUCCESS = '@store/STORE_CREATED_SUCCESS';
export const STORE_UPDATED_SUCCESS = '@store/STORE_UPDATED_SUCCESS';
export const STORE_REQUEST_FAILURE = '@store/STORE_REQUEST_FAILURE';
export const STORE_RESET_STATE = '@store/STORE_RESET_STATE';
