import { ContactAction, iContactState } from './types';

import * as types from './types';


const initialState: iContactState = {
  mounted: false,
  editingFormValues: false,
  loadingData: false,
  requestPerform: false,
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      type: 'input'
    },
    nickname: {
      name: 'nickname',
      label: 'Apelido',
      type: 'input'
    },
    email: {
      name: 'email',
      label: 'E-mail',
      type: 'input'
    },
    phone: {
      name: 'phone',
      label: 'Telefone',
      type: 'masked',
      mask: 'phone'
    },
    secondPhone: {
      name: 'secondPhone',
      label: 'Tel. Secundário',
      type: 'masked',
      mask: 'phone'
    },
    celPhone: {
      name: 'celPhone',
      label: 'Celular',
      type: 'masked',
      mask: 'phone'
    },
    cpf: {
      name: 'cpf',
      label: 'CPF',
      type: 'masked',
      mask: 'doc'
    },
    rg: {
      name: 'rg',
      label: 'RG',
      type: 'input'
    },
    cnh: {
      name: 'cnh',
      label: 'CNH',
      type: 'input'
    },
    cnpj: {
      name: 'cnpj',
      label: 'CNPJ',
      type: 'masked',
      mask: 'doc'
    },
    ie: {
      name: 'ie',
      label: 'IE',
      type: 'input'
    },
    birthDate: {
      name: 'birthDate',
      label: 'Data de nascimento',
      type: 'date'
    },
    website: {
      name: 'website',
      label: 'Site',
      type: 'input'
    },
    type: {
      name: 'type',
      label: 'Tipo',
      type: 'select',
      options: [
        {
          key: 'PERSONAL',
          label: 'Pessoa Física',
          value: 'PERSONAL'
        },
        {
          key: 'BUSINESS',
          label: 'Pessoa Jurídica',
          value: 'BUSINESS'
        },
        {
          key: 'CATERER',
          label: 'Fornecedor',
          value: 'CATERER'
        }
      ],
    },
    restriction: {
      name: 'restriction',
      label: 'Restrição interna',
      type: 'checkbox'
    },
    // addressId: {
    //   name: 'addressId',
    //   label: 'Endereço',
    //   object: 'addresses',
    //   type: 'lookup',
    // },
  },
  data: {
    name: '',
    nickname: '',
    email: '',
    phone: '',
    secondPhone: '',
    celPhone: '',
    cpf: '',
    rg: '',
    cnh: '',
    cnpj: '',
    ie: '',
    birthDate: undefined,
    website: '',
    type: 'PERSONAL',
    restriction: false
  }
}

export default function changeState(
  state = initialState,
  action: ContactAction
): iContactState {
  switch(action.type) {
    case types.CONTACT_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.contact,
        }
      };
    }
    case types.CONTACT_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.CONTACT_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.props.contact,
        mounted: true,
        loadingData: false
      };
    case types.CONTACT_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.CONTACT_HANDLE_EDITING_FORM_VALUE:
      return {
        ...state,
        editingFormValues: !state.editingFormValues
      };
    case types.CONTACT_HANDLE_MOUNTED:
      return {
        ...state,
        mounted: !state.mounted
      };
    case types.CONTACT_REQUEST:
      return {
        ...state,
      };
    case types.CONTACT_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.contact,
        requestPerform: true
      };
    case types.CONTACT_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.data.contact,
        requestPerform: true
      };
    case types.CONTACT_REQUEST_FAILURE:
      return {
        ...state,
      };
    case types.CONTACT_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
