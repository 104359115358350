import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iProduct } from '../../../interfaces/objects/iProduct.interface';
import * as actions from './actions';

export type ProductAction = ActionType<typeof actions>;

export interface iProductState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iProduct;
}

export const PRODUCT_REQUEST = '@product/PRODUCT_REQUEST';
export const PRODUCT_CREATED_SUCCESS = '@product/PRODUCT_CREATED_SUCCESS';
export const PRODUCT_UPDATED_SUCCESS = '@product/PRODUCT_UPDATED_SUCCESS';
export const PRODUCT_REQUEST_FAILURE = '@product/PRODUCT_REQUEST_FAILURE';
export const PRODUCT_RESET_STATE = '@product/PRODUCT_RESET_STATE';
