import { ActionType } from 'typesafe-actions';
import { IFieldSchema } from '../../../components/formik/types';
import { iFinancialInstitution } from '../../../interfaces/objects/iFinancialInstitution.interface';
import * as actions from './actions';

export type FinancialInstitutionAction = ActionType<typeof actions>;

export interface iFinancialInstitutionState {
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iFinancialInstitution;
}


export const FINANCIAL_INSTITUTION_REQUEST = '@financialInstitution/FINANCIAL_INSTITUTION_REQUEST';
export const FINANCIAL_INSTITUTION_CREATED_SUCCESS = '@financialInstitution/FINANCIAL_INSTITUTION_CREATED_SUCCESS';
export const FINANCIAL_INSTITUTION_UPDATED_SUCCESS = '@financialInstitution/FINANCIAL_INSTITUTION_UPDATED_SUCCESS';
export const FINANCIAL_INSTITUTION_REQUEST_FAILURE = '@financialInstitution/FINANCIAL_INSTITUTION_REQUEST_FAILURE';
export const FINANCIAL_INSTITUTION_RESET_STATE = '@financialInstitution/FINANCIAL_INSTITUTION_RESET_STATE';
