import { action } from 'typesafe-actions';

import { iPendency } from '../../../interfaces/objects/iPendency.interface';
import * as types from './types';


export function pendencyRequest(props: {
  history: any;
  pendency: iPendency,
}) {
  return action(types.PENDENCY_REQUEST, {
    props
  });
}

export function pendencyCreatedSuccess(props: {
  pendency: iPendency,
}) {
  return action(types.PENDENCY_CREATED_SUCCESS, {
    props
  });
}

export function pendencyUpdatedSuccess(props: {
  pendency: iPendency,
}) {
  return action(types.PENDENCY_UPDATED_SUCCESS, {
    props
  });
}

export function pendencyRequestFailure() {
  return action(types.PENDENCY_REQUEST_FAILURE);
}

export function pendencyResetState() {
  return action(types.PENDENCY_RESET_STATE);
}
