import { ActionType } from 'typesafe-actions';

import { IFieldSchema } from '../../../components/formik/types';
import { iDealVehicle } from '../../../interfaces/objects/iDealVehicle.interface';
import * as actions from './actions';

export type DealVehicleAction = ActionType<typeof actions>;

export interface iDealVehicleState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iDealVehicle;
}

export const DEAL_VEHICLE_GET = '@dealVehicle/DEAL_VEHICLE_GET';
export const DEAL_VEHICLE_GET_SUCCESS = '@dealVehicle/DEAL_VEHICLE_GET_SUCCESS';
export const DEAL_VEHICLE_GET_FAILURE = '@dealVehicle/DEAL_VEHICLE_GET_FAILURE';

export const DEAL_VEHICLE_REQUEST = '@dealVehicle/DEAL_VEHICLE_REQUEST';
export const DEAL_VEHICLE_CREATED_SUCCESS = '@dealVehicle/DEAL_VEHICLE_CREATED_SUCCESS';
export const DEAL_VEHICLE_UPDATED_SUCCESS = '@dealVehicle/DEAL_VEHICLE_UPDATED_SUCCESS';
export const DEAL_VEHICLE_REQUEST_FAILURE = '@dealVehicle/DEAL_VEHICLE_REQUEST_FAILURE';

export const DEAL_VEHICLE_SET_VEHICLE = '@dealVehicle/DEAL_VEHICLE_SET_VEHICLE';

export const DEAL_VEHICLE_RESET_STATE = '@dealVehicle/DEAL_VEHICLE_RESET_STATE';
