import axios from 'axios';
import Cookies from 'js-cookie';

import { setupInterceptorsTo } from './axios.interceptors';


export const api = setupInterceptorsTo(axios.create({
  baseURL: process.env.REACT_APP_URL_CONFIG,
  headers: {
    Authorization: Cookies.get('revenda-token'),
    Store: Cookies.get('store')
  },
}));
