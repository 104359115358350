import { VehicleModelAction, iVehicleModelState } from './types';
import * as types from './types';


const initialState: iVehicleModelState = {
  mounted: false,
  loadingData: false,
  requestPerform: false,
  fields: {
    title: {
      name: 'title',
      label: 'Título',
      type: 'input',
      disabled: true,
      placeholder: 'Campo automático'
    },
    fipeCode: {
      name: 'fipeCode',
      label: 'Código Fipe',
      type: 'input',
      disabled: true
    },
    fuel: {
      name: 'fuel',
      label: 'Combustível',
      type: 'input'
    },
    // keyFipe: {
    //   name: 'keyFipe',
    //   label: 'Chave Fipe',
    //   type: 'input'
    // },
    fipePrice: {
      name: 'fipePrice',
      label: 'Preço Fipe',
      type: 'currency',
      disabled: true
    },
    // referenceDate: {
    //   name: 'referenceDate',
    //   label: 'Referência',
    //   type: 'date'
    // },
    galeryId: {
      name: 'galeryId',
      label: 'Galeria',
      type: 'input'
    },
  },
  data: {
    title: '',
    fipeCode: '',
    fuel: '',
    // keyFipe: '',
    fipeBrandId: '',
    fipeModelId: '',
    fipeYearId: '',
    fipePrice: 0,
    galeryId: '',
  }
}

export default function changeState(
  state = initialState,
  action: VehicleModelAction
): iVehicleModelState {
  switch(action.type) {
    case types.VEHICLE_MODEL_SET_VALUES_BY_FORMIK: {
      return {
        ...state,
        data: {
          ...action.payload.props.vehicleModel,
        }
      };
    }
    case types.VEHICLE_MODEL_GET:
      return {
        ...state,
        loadingData: true
      };
    case types.VEHICLE_MODEL_GET_SUCCESS:
      const { fipeBrandId, fipeModelId, fipeYearId, ...rest } = action.payload.props.vehicleModel;
      return {
        ...state,
        mounted: true,
        loadingData: false,
        data: {
          ...rest,
          fipeBrandId: fipeBrandId ? 'fixLookup' + fipeBrandId : '',
          fipeModelId: fipeModelId ? 'fixLookup' + fipeModelId : '',
          fipeYearId: fipeYearId ? 'fixLookup' + fipeYearId : '',
        }
      };
    case types.VEHICLE_MODEL_GET_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    case types.VEHICLE_MODEL_HANDLE_MOUNTED:
      return {
        ...state,
        mounted: !state.mounted
      };
    case types.VEHICLE_MODEL_REQUEST:
      return {
        ...state,
        loadingData: true
      };
    case types.VEHICLE_MODEL_CREATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.vehicleModel,
        requestPerform: true,
        loadingData: false
      };
    case types.VEHICLE_MODEL_UPDATED_SUCCESS:
      return {
        ...state,
        data: action.payload.props.vehicleModel,
        requestPerform: true,
        loadingData: false
      };
    case types.VEHICLE_MODEL_REQUEST_FAILURE:
      return {
        ...state,
        loadingData: false
      };
    // case types.VEHICLE_MODEL_SET_FIPE_BRAND:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       fipeBrand: action.payload.props.fipeBrand,
    //       fipeBrandId: action.payload.props.fipeBrand.id,
    //       fipeModel: undefined,
    //       fipeModelId: '',
    //       fipeYear: undefined,
    //       fipeYearId: '',
    //       fipePrice: 0,
    //       fipeCode: '',
    //       fuel: ''
    //     },
    //     loadingData: false
    //   };
    // case types.VEHICLE_MODEL_SET_FIPE_MODEL:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       fipeModel: action.payload.props.fipeModel,
    //       fipeModelId: action.payload.props.fipeModel.id,
    //       fipeYear: undefined,
    //       fipeYearId: '',
    //       fipePrice: 0,
    //       fipeCode: '',
    //       fuel: ''
    //     },
    //     loadingData: false
    //   };
    // case types.VEHICLE_MODEL_SET_FIPE_YEAR:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       fipeYear: action.payload.props.fipeYear,
    //       fipeYearId: action.payload.props.fipeYear.id,
    //       fipePrice: action.payload.props.fipeYear.value,
    //       fipeCode: action.payload.props.fipeYear.fipeCode,
    //       fuel: action.payload.props.fipeYear.fuel,
    //       title: `${state.data.fipeBrand?.brand?.toUpperCase()} ${state.data.fipeModel?.model?.split('/')[0]} ${action.payload.props.fipeYear?.year}...`
    //     },
    //     loadingData: false
    //   };
    case types.VEHICLE_MODEL_RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
