import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { iApiResponse } from '../../../interfaces/apiResponse.interface';
import { api } from '../../../services/api/axios';
import formatSoldVehicles from '../../../utils/formatSoldVehicles';
import * as actions from './actions';
import * as types from './types';


export function* financeGet({ payload }: ActionType<typeof actions.financeGet>) {
  // console.log('@financeGet/payload:: ', payload);
  try {
    const vehiclesResponse: AxiosResponse<iApiResponse> = yield call(api.get, '/api/vehicles/', { params: { ...payload.props.searchFilters } });
    const soldVehicles = formatSoldVehicles({ vehicles: vehiclesResponse.data.payload });
    // console.log('@soldVehicles:: ', soldVehicles);

    yield put(actions.financeGetSuccess({ soldVehicles }));

  } catch(err) {
    console.log('Erro:: ', err);
    yield put(actions.financeGetFailure());
  }

}


export default all([
  takeLatest(types.FINANCE_GET, financeGet),
])
