import { ActionType } from 'typesafe-actions';

import { IFieldSchema } from '../../../components/formik/types';
import { iDealProduct } from '../../../interfaces/objects/iDealProduct.interface';
import * as actions from './actions';

export type DealProductAction = ActionType<typeof actions>;

export interface iDealProductState {
  loadingData: boolean;
  requestPerform: boolean;
  fields: { [key: string]: IFieldSchema };
  data: iDealProduct;
}

export const DEAL_PRODUCT_SET_VALUES_BY_FORMIK = '@dealProduct/DEAL_PRODUCT_SET_VALUES_BY_FORMIK';

export const DEAL_PRODUCT_GET = '@dealProduct/DEAL_PRODUCT_GET';
export const DEAL_PRODUCT_GET_SUCCESS = '@dealProduct/DEAL_PRODUCT_GET_SUCCESS';
export const DEAL_PRODUCT_GET_FAILURE = '@dealProduct/DEAL_PRODUCT_GET_FAILURE';

export const DEAL_PRODUCT_REQUEST = '@dealProduct/DEAL_PRODUCT_REQUEST';
export const DEAL_PRODUCT_CREATED_SUCCESS = '@dealProduct/DEAL_PRODUCT_CREATED_SUCCESS';
export const DEAL_PRODUCT_UPDATED_SUCCESS = '@dealProduct/DEAL_PRODUCT_UPDATED_SUCCESS';
export const DEAL_PRODUCT_REQUEST_FAILURE = '@dealProduct/DEAL_PRODUCT_REQUEST_FAILURE';
export const DEAL_PRODUCT_RESET_STATE = '@dealProduct/DEAL_PRODUCT_RESET_STATE';

export const DEAL_PRODUCT_SET_PRODUCT = '@dealProduct/DEAL_PRODUCT_SET_PRODUCT';
