import { iObjectSettingsState, ObjectSettingsAction } from './types';
import * as types from './types';


const initialState: iObjectSettingsState = {
  fields: {
    name: {
      name: 'name',
      label: 'Nome',
      isvisible: false,
      iseditable: false,
    },
    email: {
      name: 'email',
      label: 'E-mail',
      isvisible: false,
      iseditable: false,
    },
  },
  permissions: {
    name: {
      name: 'name',
      label: 'Nome',
      isvisible: false,
      iseditable: false,
    },
    email: {
      name: 'email',
      label: 'E-mail',
      isvisible: false,
      iseditable: false,
    },
  }
};

export default function objectSettings(
  state = initialState,
  action: ObjectSettingsAction
): iObjectSettingsState {
  switch(action.type) {
    case types.FIELD_REQUEST:
      return {
        ...state,
        // isLoading: true,
      };
    case types.FIELD_SUCCESS:
      return {
        ...state,
        // isLoading: false,
        // isSignedIn: true,
        // accessToken: action.payload.accessToken,
      };
    case types.CHANGE_CHECKBOX: {
      const { name, property } = action.payload;
      let obj = state.fields[name];
      if(property === 'isvisible') {
        obj.isvisible = !obj.isvisible;
      } else if(property === 'iseditable') {
        obj.iseditable = !obj.iseditable;
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: obj
        }
      };
    }
    case types.FIELD_FAILURE:
      // delete axios.defaults.headers.Authorization;
      return {
        ...initialState,
        // error: true,
      };
    default:
      return state;
  }
}
